import { FormattedMessage } from 'react-intl';

import DEVICES from './Devices';
import { ERROR } from './Error';
import FILTER from './Filter';
import FORM from './Forms';
import PRODUCTS from './Products';
import { SLOTS } from './Slots';
import SPOTS from './Spots';
import { TRANSACTIONS } from './Transactions';
import CONTACTS from './objects/Contact';
import { PRODUCT } from './objects/Products';

export const CommonMessage = {
    EXCEPTIONS: {
        NO_DATA: (
            <FormattedMessage
                id='CommonMessage.EXCEPTION.NO_DATA'
                description='Common message exception no data'
                defaultMessage='No data available'
            />
        )
    },
    DROPOFF: (
        <FormattedMessage
            id='CommonMessage.Dropoff'
            description='CommonMessage word Dropoff'
            defaultMessage='Dropoff'
        />
    ),
    PICKUP: (
        <FormattedMessage
            id='CommonMessage.Pickup'
            description='CommonMessage word Pickup'
            defaultMessage='Pickup'
        />
    ),
    REMOVE_PARCEL: (
        <FormattedMessage
            id='CommonMessage.RemoveParcel'
            description='CommonMessage word Remove parcel'
            defaultMessage='Remove parcel'
        />
    ),
    BUTTON: {
        CANCEL: (
            <FormattedMessage
                id='CommonMessage.Button.Cancel'
                description='CommonMessage button for cancel'
                defaultMessage='Cancel'
            />
        ),
        SUBMIT: (
            <FormattedMessage
                id='CommonMessage.Button.Submit'
                description='CommonMessage button for submit'
                defaultMessage='Submit'
            />
        ),
        EDIT: (
            <FormattedMessage
                id='CommonMessage.Button.Edit'
                description='CommonMessage button for Edit'
                defaultMessage='Edit'
            />
        ),
        SAVE: (
            <FormattedMessage
                id='CommonMessage.Button.Save'
                description='CommonMessage button for Save'
                defaultMessage='Save'
            />
        ),
        ADD: (
            <FormattedMessage
                id='CommonMessage.Button.Add'
                description='CommonMessage button for Add'
                defaultMessage='Add'
            />
        ),
        DELETE: (
            <FormattedMessage
                id='CommonMessage.Button.Delete'
                description='CommonMessage button for Delete'
                defaultMessage='Delete'
            />
        )
    },
    OBJECTS: {
        PRODUCT: PRODUCT,
        CONTACTS: CONTACTS
    },
    STATUS: {
        TITLE: (
            <FormattedMessage
                id='CommonMessage.Status.Title'
                description='CommonMessage status title'
                defaultMessage='Status'
            />
        ),
        SUCCESS: (
            <FormattedMessage
                id='CommonMessage.Status.Success'
                description='CommonMessage status for success'
                defaultMessage='Success'
            />
        ),
        ERROR: (
            <FormattedMessage
                id='CommonMessage.Status.Error'
                description='CommonMessage status for error'
                defaultMessage='Error'
            />
        ),
        UNKNOWN: (
            <FormattedMessage
                id='CommonMessage.Status.Unknown'
                description='CommonMessage status unknown'
                defaultMessage='Unknown'
            />
        )
    },
    DATE: {
        CREATED_DATE: (
            <FormattedMessage
                id='CommonMessage.Date.Created_date'
                description='CommonMessage created date'
                defaultMessage='Created date'
            />
        ),
        UPDATED_DATE: (
            <FormattedMessage
                id='CommonMessage.Date.Updated_date'
                description='CommonMessage updated date'
                defaultMessage='Updated date'
            />
        )
    },
    FORM: FORM,
    TRANSACTIONS: TRANSACTIONS,
    SLOTS: SLOTS,
    DEVICES: DEVICES,
    FILTER: FILTER,
    SPOTS: SPOTS,
    PRODUCTS: PRODUCTS,
    ERRORS: ERROR
};
