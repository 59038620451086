import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ApiQueryParams } from '../../api/BaseQueryParams';
import { SlotLogsQueryParams, SlotLogsResponse, fetchSlotLogsApi } from '../../api/slots/SlotLogs';
import { SliceStatus } from '../utils/Redux';

interface SlotLogsState {
    slotLogs: { [slotId: string]: SlotLogsResponse };
    status: SliceStatus;
    lastUpdate: number;
    message: string;
}

const initialState: SlotLogsState = {
    slotLogs: {},
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: ''
};

export const SlotLogsSlice = createSlice({
    name: 'slotLogs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlotLogs.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSlotLogs.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (action.meta.arg.slot && typeof action.meta.arg.slot == 'string') {
                    if (state.slotLogs[action.meta.arg.slot] === undefined) {
                        state.slotLogs[action.meta.arg.slot] = {
                            ...action.payload,
                            results: new Array(action.payload.count)
                        };
                    }
                    state.slotLogs[action.meta.arg.slot].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            });
    }
});

export const fetchSlotLogs = createAsyncThunk<SlotLogsResponse, ApiQueryParams<SlotLogsQueryParams>>(
    'fetchSlotLogs',
    async (queryParams?: ApiQueryParams<SlotLogsQueryParams>) => {
        return await fetchSlotLogsApi(queryParams ? queryParams : null);
    }
);

export default SlotLogsSlice.reducer;
