import { ApiViewSet, apiPagination } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';
import { ApiError, PaginationResponse, fetchApi, postApi } from '../utils';

export enum PrinterCommandStatus {
    ERROR = 'error',
    SUCCESS = 'success',
    PENDING = 'pending'
}

export type PrinterLogs = PaginationResponse<PrinterLogsResults>;

export interface PrinterLogsResults {
    message: PrinterCommand | PrinterEvent | PrinterCode | null;
    created_at: Date;
}

export interface PrinterCommand {
    command: string;
    status: PrinterCommandStatus;
    message: PrinterStatus | PrinterLabelMessage | { message: string } | string;
    date?: Date;
}

export interface PrinterEvent {
    event: string;
    action_type: string;
    status: PrinterCommandStatus;
    transaction_id: string;
    data: {
        type: string;
        s_code: string;
        reader_no: string;
        act_index: string;
        open_event: string;
    };
    parent_name: string;
}

export interface PrinterCode {
    code: number;
    message: string;
}

export interface Printer {
    uuid: string;
    serial_number: string;
    additional_data: {
        printing_platform: PrintingPlatform;
        spot_id: string;
    };

    last_action: PrinterCommand | PrinterEvent | PrinterCode;
    last_action_time: Date | null;

    last_active_state?: {
        status: PrinterStatus;
        additional_data: { device_uuid: string; serial_number: string; transaction_id: string };
        amountOfPrintedLabels: number;
    };
    last_active_time: string | null;
}

export interface PrinterStatus {
    offline: boolean;
    paperOut: boolean;
    paused: boolean;
    headOpen: boolean;
    ribbonOut: boolean;
}
export interface PrinterLabelMessage {
    additional_data: { device_uuid: string; serial_number: string; transaction_id: string };
    amountOfPrintedLabels: number;
    error: string;
    status: PrinterStatus;
}

enum PrintingPlatform {
    SAVANNAH = 'Savannah',
    TELLOPORT = 'Telloport'
}

export type PrinterResponse = PaginationResponse<Printer>;

export enum PrinterQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGES_SIZE = 'page_size',
    SEARCH = 'search'
}

const printerViewSet: ApiViewSet = {
    baseName: 'services/iot/printers'
};

export async function fetchPrintersApi(queryParams?: ApiQueryParams<PrinterQueryParams>): Promise<PrinterResponse> {
    return await apiPagination<PrinterResponse, PrinterQueryParams>(printerViewSet, queryParams);
}

export async function fetchPrinterApi(uuid: string, account: string): Promise<Printer> {
    const url = `/${printerViewSet.baseName}/${uuid}/?account=${account}`;
    const response = await fetchApi(url, {});
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error fetching printer');
        }
        throw new ApiError('Error fetching printer');
    }
    return await response.json();
}

export async function fetchPrinterLogsApi(uuid: string, queryParams?: ApiQueryParams<PrinterQueryParams>): Promise<PrinterLogs> {
    const url = `/${printerViewSet.baseName}/${uuid}/logs/`;
    return apiPagination<PrinterLogs, PrinterQueryParams>(printerViewSet, queryParams, url);
}

//Commands
export async function pausePrinterAPI(uuid: string, value: boolean, account: string) {
    const url = `/${printerViewSet.baseName}/${uuid}/printer_pause/?account=${account}`;
    const response = await postApi(url, { pause: value });
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Failed to pause printer');
        }
        throw new ApiError('Failed to pause printer', json);
    }
    return await response.json();
}
export async function resetPrinterAPI(uuid: string, account: string) {
    const url = `/${printerViewSet.baseName}/${uuid}/reset_printer/?account=${account}`;
    const response = await postApi(url, {});
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Failed to reset printer');
        }
        throw new ApiError('Failed to reset printer', json);
    }
    return await response.json();
}
export async function clearQueuePrinterAPI(uuid: string, account: string) {
    const url = `/${printerViewSet.baseName}/${uuid}/clear_printer_queue/?account=${account}`;
    const response = await postApi(url, {});
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Failed to clear printer queue');
        }
        throw new ApiError('Failed to clear printer queue', json);
    }
    return await response.json();
}
export async function refreshPrinterStatusAPI(uuid: string, account: string) {
    const url = `/${printerViewSet.baseName}/${uuid}/printer_status/?account=${account}`;
    const response = await postApi(url, {});
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Failed to refresh status');
        }
        throw new ApiError('Failed to refresh status', json);
    }
    return await response.json();
}

export function printerHealthOk(printer: Printer) {
    if (isPrinterCode(printer.last_active_state) || isPrinterEvent(printer.last_active_state)) {
        return false;
    } else if (isPrinterCommand(printer.last_active_state)) {
        const status = printer.last_active_state.status;
        return status?.offline === false && status?.headOpen === false && status?.paperOut === false && status?.ribbonOut === false && status?.paused === false;
    }
    return false; // Return false for other cases
}

// Type guards
export function isPrinterCode(obj: any): obj is PrinterCode {
    return obj && obj.message !== undefined && obj.code !== undefined;
}

export function isPrinterEvent(obj: any): obj is PrinterEvent {
    return (
        obj &&
        obj.event !== undefined &&
        obj.action_type !== undefined &&
        obj.transaction_id !== undefined &&
        obj.data !== undefined &&
        obj.parent_name !== undefined
    );
}

export function isPrinterCommand(obj: any): obj is PrinterCommand {
    return obj && obj.command !== undefined && obj.status !== undefined && obj.message !== undefined;
}

export function isPrinterStatus(message: PrinterStatus | PrinterLabelMessage | { message: string } | string): message is PrinterStatus {
    return (message as PrinterStatus).offline !== undefined;
}

export function isPrinterLabel(message: PrinterStatus | PrinterLabelMessage | { message: string } | string): message is PrinterLabelMessage {
    return (message as PrinterLabelMessage).additional_data !== undefined;
}

export function isPrinterMessage(message: PrinterStatus | PrinterLabelMessage | { message: string } | string): message is { message: string } {
    return (message as { message: string }).message !== undefined;
}
