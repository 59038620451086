import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

import { Attribute } from '../../../api/catalogue/Attributes';

const AttributeListColumns: Column<Attribute>[] = [
    {
        Header: 'ID',
        accessor: 'id'
    },
    {
        Header: (
            <FormattedMessage
                id='AttributeList.tableHeader.name'
                description='The name table header for the attribute list.'
                defaultMessage='Name'
            />
        ),
        accessor: 'name'
    },
    {
        Header: (
            <FormattedMessage
                id='AttributeList.tableHeader.values'
                description='The values table header for the attribute list.'
                defaultMessage='Values'
            />
        ),
        accessor: 'values'
    }
];

export default AttributeListColumns;
