import { Row } from 'react-table';

import { AccountMembership } from '../../../api/User';

export interface AccountsListElementProps {
    selectEnabled?: boolean;
    row: Row<AccountMembership>;
    onClick: (element: AccountMembership) => void;
}

const AccountsListElement = (props: AccountsListElementProps) => {
    return (
        <tr onClick={() => props.onClick(props.row.original)}>
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-content-center'>
                <span>{props.row.original.account.id}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-content-center'>
                <span>{props.row.original.account.name}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder opacity-7 align-content-center'>
                <span>{props.row.original.role ? props.row.original.role : 'member'}</span>
            </td>
        </tr>
    );
};

export default AccountsListElement;
