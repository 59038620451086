import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ApiQueryParams } from '../../api/BaseQueryParams';
import {
    Printer,
    PrinterLogs,
    PrinterQueryParams,
    PrinterResponse,
    clearQueuePrinterAPI,
    fetchPrinterApi,
    fetchPrinterLogsApi,
    fetchPrintersApi,
    pausePrinterAPI,
    refreshPrinterStatusAPI,
    resetPrinterAPI
} from '../../api/iot/Printer';
import { ApiError } from '../../api/utils';
import { ReduxError } from '../store';
import { SliceStatus } from '../utils/Redux';

interface PrinterState {
    printerList: PrinterResponse | null;
    pendingPrinterList: Printer[];
    printerLogsList: { [uuid: string]: PrinterLogs };
    status: SliceStatus;
    logStatus: SliceStatus;
    lastLogUpdate: { [uuid: string]: number };
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: PrinterState = {
    printerList: null,
    pendingPrinterList: [],
    printerLogsList: {},
    status: SliceStatus.INIT,
    logStatus: SliceStatus.INIT,
    lastLogUpdate: {},
    lastUpdate: Date.now(),
    error: null
};

export const printerSlice = createSlice({
    name: 'printers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPrinters.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPrinters.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.printerList === null) state.printerList = { ...action.payload, results: new Array(action.payload.count) };
                state.printerList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchPrinters.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchPrinter.fulfilled, (state, action) => {
                const printer = action.payload;
                const pendingPrinter = state.pendingPrinterList.find((p) => p.uuid === printer.uuid);
                if (pendingPrinter !== undefined) {
                    state.pendingPrinterList = state.pendingPrinterList.filter((p) => p.uuid !== pendingPrinter.uuid);
                }
                const existingPrinter = state.printerList?.results.find((p) => p.uuid === printer.uuid);
                if (existingPrinter !== undefined) {
                    const index = state.printerList?.results.indexOf(existingPrinter);
                    if (index !== undefined && state.printerList?.results[index] !== undefined) {
                        state.printerList.results[index] = printer;
                    }
                }
            })
            .addCase(fetchPrinter.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchPrinterLogs.pending, (state) => {
                state.logStatus = SliceStatus.LOADING;
            })
            .addCase(fetchPrinterLogs.fulfilled, (state, action) => {
                const printerUUID = action.meta.arg.uuid;
                const startPos = toNumber(action.meta.arg.queryParams.page_size) * (toNumber(action.meta.arg.queryParams.page) - 1);
                if (printerUUID) {
                    if (state.printerLogsList[printerUUID] === undefined) {
                        state.printerLogsList[printerUUID] = {
                            ...action.payload,
                            results: new Array(action.payload.count)
                        };
                    }
                    state.printerLogsList[printerUUID] = { ...action.payload, results: state.printerLogsList[printerUUID].results };
                    state.printerLogsList[printerUUID].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    state.lastLogUpdate[printerUUID] = Date.now();
                }
                state.logStatus = SliceStatus.IDLE;
            })
            .addCase(fetchPrinterLogs.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.logStatus = SliceStatus.ERROR;
            })

            //Commands
            .addCase(pausePrinter.pending, (state, action) => {
                const printer = state.printerList?.results.find((p) => p.uuid === action.meta.arg.uuid);
                if (printer && state.pendingPrinterList.find((p) => p.uuid === printer.uuid) === undefined) {
                    state.pendingPrinterList = [...state.pendingPrinterList, printer];
                }
            })
            .addCase(pausePrinter.fulfilled, (state, action) => {
                state.error = null;
                state.status = SliceStatus.IDLE;
            })
            .addCase(pausePrinter.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(resetPrinter.pending, (state, action) => {
                const printer = state.printerList?.results.find((p) => p.uuid === action.meta.arg.uuid);
                if (printer && state.pendingPrinterList.find((p) => p.uuid === printer.uuid) === undefined) {
                    state.pendingPrinterList = [...state.pendingPrinterList, printer];
                }
            })
            .addCase(resetPrinter.fulfilled, (state, action) => {
                state.error = null;
                state.status = SliceStatus.IDLE;
            })
            .addCase(resetPrinter.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(clearPrinterQueue.pending, (state, action) => {
                const printer = state.printerList?.results.find((p) => p.uuid === action.meta.arg.uuid);
                if (printer && state.pendingPrinterList.find((p) => p.uuid === printer.uuid) === undefined) {
                    state.pendingPrinterList = [...state.pendingPrinterList, printer];
                }
            })
            .addCase(clearPrinterQueue.fulfilled, (state, action) => {
                state.error = null;
                state.status = SliceStatus.IDLE;
            })
            .addCase(clearPrinterQueue.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(refreshPrinterStatus.pending, (state, action) => {
                const printer = state.printerList?.results.find((p) => p.uuid === action.meta.arg.uuid);
                if (printer && state.pendingPrinterList.find((p) => p.uuid === printer.uuid) === undefined) {
                    state.pendingPrinterList = [...state.pendingPrinterList, printer];
                }
            })
            .addCase(refreshPrinterStatus.fulfilled, (state, action) => {
                state.error = null;
                state.status = SliceStatus.IDLE;
            })
            .addCase(refreshPrinterStatus.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchPrinters = createAsyncThunk<PrinterResponse, ApiQueryParams<PrinterQueryParams>>(
    'fetchPrinters',
    async (queryParams: ApiQueryParams<PrinterQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchPrintersApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
export const fetchPrinter = createAsyncThunk<Printer, { uuid: string; account: string }>(
    'fetchPrinter',
    async (options: { uuid: string; account: string }, { rejectWithValue }) => {
        try {
            return await fetchPrinterApi(options.uuid, options.account);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchPrinterLogs = createAsyncThunk<PrinterLogs, { queryParams: ApiQueryParams<PrinterQueryParams>; uuid: string }>(
    'fetchPrinterLogs',
    async (options: { queryParams: ApiQueryParams<PrinterQueryParams>; uuid: string }, { rejectWithValue }) => {
        try {
            return await fetchPrinterLogsApi(options.uuid, options.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

//Commands
export const pausePrinter = createAsyncThunk<void, { uuid: string; value: boolean; account: string }>(
    'pausePrinter',
    async (options: { uuid: string; value: boolean; account: string }, { rejectWithValue }) => {
        try {
            await pausePrinterAPI(options.uuid, options.value, options.account);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
export const resetPrinter = createAsyncThunk<void, { uuid: string; account: string }>(
    'resetPrinter',
    async (options: { uuid: string; account: string }, { rejectWithValue }) => {
        try {
            await resetPrinterAPI(options.uuid, options.account);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
export const clearPrinterQueue = createAsyncThunk<void, { uuid: string; account: string }>(
    'clearPrinterQueue',
    async (options: { uuid: string; account: string }, { rejectWithValue }) => {
        try {
            await clearQueuePrinterAPI(options.uuid, options.account);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);
export const refreshPrinterStatus = createAsyncThunk<Promise<Response>, { uuid: string; account: string }>(
    'refreshPrinterStatus',
    async (options: { uuid: string; account: string }, { rejectWithValue }) => {
        try {
            return await refreshPrinterStatusAPI(options.uuid, options.account);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default printerSlice.reducer;
