import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ApiQueryParams } from '../api/BaseQueryParams';
import { InviteQueryParams, UserInviteData, deleteUserInviteApi, fetchUserInvitesApi } from '../api/UserInvites';
import { ApiError } from '../api/utils';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface UserInviteState {
    invites: UserInviteData | null;
    status: SliceStatus;
    lastUpdate: number;
    message: string;
    error: ReduxError | null;
}

const initialState: UserInviteState = {
    invites: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: '',
    error: null
};

export const UserInviteSlice = createSlice({
    name: 'userInvites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInvites.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUserInvites.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.invites == null) {
                    state.invites = {
                        count: action.payload.count,
                        next: action.payload.next,
                        previous: action.payload.previous,
                        results: new Array(action.payload.count)
                    };
                    state.invites?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.invites?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchUserInvites.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })

            .addCase(deleteUserInvites.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteUserInvites.fulfilled, (state, action) => {
                if (state.invites) {
                    const inviteToBeRemoved = state.invites.results.find((i) => action.meta.arg.includes(i.uuid));
                    if (inviteToBeRemoved) {
                        const index = state.invites.results.indexOf(inviteToBeRemoved);
                        state.invites = {
                            ...state.invites,
                            count: state.invites.count,
                            next: state.invites.next,
                            previous: state.invites.previous
                        };
                        state.invites.results.splice(index, 1);
                    }
                }

                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(deleteUserInvites.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchUserInvites = createAsyncThunk<UserInviteData, ApiQueryParams<InviteQueryParams>>(
    'fetchUserinvites',
    async (queryParams: ApiQueryParams<InviteQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchUserInvitesApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteUserInvites = createAsyncThunk<void, string[]>('deleteUserInvites', async (inviteIds: string[], { rejectWithValue }) => {
    await inviteIds.forEach(async (id) => {
        try {
            await deleteUserInviteApi(id);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    });
});

export default UserInviteSlice.reducer;
