import { BiCategory } from 'react-icons/bi';
import { FaBoxes } from 'react-icons/fa';
import { IoIosSwitch } from 'react-icons/io';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AttributeList from './attributes/attribute-list/AttributeList';
import CategoryList from './categories/category-list/CategoryList';
import ProductList from './products/product-list/ProductList';

export default function ShopOverview() {
    const navigate = useNavigate();
    const location = useLocation();

    const getClass = (name: string): string => {
        let css = 'nav-link mb-0 px-0 py-1';
        if (location.pathname.includes(name)) css += ' active';
        return css;
    };

    return (
        <div>
            <div className='nav-wrapper position-relative pb-2'>
                <ul
                    className='nav nav-pills nav-fill p-1'
                    role='tablist'>
                    <button
                        className={getClass('products')}
                        data-bs-toggle='tab'
                        onClick={() => navigate('products')}
                        role='tab'
                        id='ShopOverview.ProductsButton'
                        aria-controls='products'
                        aria-selected='true'>
                        <span className='d-flex justify-content-center align-items-center'>
                            <FaBoxes className='ni ni-badge text-sm me-2' />
                            <span>Products</span>
                        </span>
                    </button>
                    <button
                        className={getClass('attributes')}
                        data-bs-toggle='tab'
                        onClick={() => navigate('attributes')}
                        role='tab'
                        id='ShopOverview.AttributesButton'
                        aria-controls='attributes'
                        aria-selected='true'>
                        <span className='d-flex justify-content-center align-items-center'>
                            <IoIosSwitch className='ni ni-badge text-sm me-2' />
                            <span>Attributes</span>
                        </span>
                    </button>
                    <button
                        className={getClass('categories')}
                        data-bs-toggle='tab'
                        onClick={() => navigate('categories')}
                        role='tab'
                        id='ShopOverview.CategoriesButton'
                        aria-controls='categories'
                        aria-selected='true'>
                        <span className='d-flex justify-content-center align-items-center'>
                            <BiCategory className='ni ni-badge text-sm me-2' />
                            <span>Categories</span>
                        </span>
                    </button>
                </ul>
            </div>
            <Routes>
                <Route
                    index
                    element={<Navigate to='products' />}
                />
                <Route
                    path='categories'
                    element={<CategoryList />}
                />
                <Route
                    path='attributes'
                    element={<AttributeList />}
                />
                <Route
                    path='products'
                    element={<ProductList />}
                />
            </Routes>
        </div>
    );
}
