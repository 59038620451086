import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Category } from '../../../api/catalogue/Categories';
import CheckBox from '../../../components/forms/check/CheckBox';
import { useAppSelector } from '../../../hooks/redux';

interface FilterBarProps {
    onChange?: (value: Category) => void;
    selectedCategory: Category | undefined;
    selectedAttributes: (value: SelectedAttributes[] | undefined) => void;
}

const FilterBar = (props: FilterBarProps) => {
    const [categoryList, changeCategoryList] = useState<Category[]>();
    const [attributeList, changeAttributeList] = useState<Attribute[]>();
    const [selectedAttributes, changeSelectedAttributes] = useState<SelectedAttributes[]>([]);
    const [selectedCategory, changeSelectedCategory] = useState<Category>();
    const categoryState = useAppSelector((state) => state.categories);
    const attributeState = useAppSelector((state) => state.attributes);

    useEffect(() => {
        props.selectedAttributes && props.selectedAttributes(selectedAttributes);
    }, [selectedAttributes]);

    useEffect(() => {
        if (attributeState.attributeList && selectedCategory) {
            changeAttributeList(attributeState.attributeList.filter((a) => a.category === selectedCategory.id));
        } else {
            changeAttributeList([]);
        }
    }, [selectedCategory]);

    useEffect(() => {
        changeSelectedCategory(props.selectedCategory);
        changeSelectedAttributes([]);
    }, [props.selectedCategory]);

    useEffect(() => {
        if (categoryState.categoryList) {
            if (selectedCategory) {
                props.onChange && props.onChange(selectedCategory);
                const categoryChildren = selectedCategory.children;
                if (categoryChildren) {
                    changeCategoryList(categoryChildren);
                } else changeCategoryList([]);
            } else changeCategoryList(categoryState.categoryList);
        }
    }, [selectedCategory, categoryState]);

    const [showAllCategories, setShowAllCategories] = useState(false);

    const displayedCategories = showAllCategories ? categoryList : categoryList?.slice(0, 3);

    const categoryClassName = showAllCategories ? 'ms-3 dropdown-toggle' : 'ms-3 dropdown-toggle show';

    return (
        <div className='card w-100 h-100'>
            <div className='d-flex justify-content-start ms-2 mt-3 dropdown'>
                <p className='text-lg font-weight-bolder'>{'Categories'}</p>
                {categoryList && categoryList.length > 3 && (
                    <a
                        href='#'
                        onClick={() => setShowAllCategories((prev) => !prev)}
                        className={categoryClassName}
                    />
                )}
            </div>
            {displayedCategories && displayedCategories.length > 0 ? (
                <div className='d-flex flex-column justify-content-between ms-2'>
                    {displayedCategories.map((c) => (
                        <a
                            onClick={() => {
                                changeSelectedCategory(c);
                            }}
                            href='#'
                            className='text-sm me-3'
                            key={c.id}>
                            {c.name}
                        </a>
                    ))}
                </div>
            ) : (
                <></>
            )}
            <div className='d-flex justify-content-start ms-2 mt-3 dropdown'>
                <p className='text-lg font-weight-bolder'>{'Attributes'}</p>
            </div>
            {attributeList && attributeList.length > 0 ? (
                <div className='d-flex flex-column justify-content-between'>
                    {attributeList.map((a) => (
                        <Attribute
                            key={a.id}
                            attribute={a}
                            selectedAttributes={selectedAttributes}
                            changeSelectedAttributes={changeSelectedAttributes}
                        />
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default FilterBar;

export interface SelectedAttributes {
    attribute: number;
    values: string[];
}

interface Attribute {
    id: number;
    name: string;
    values: string[];
}

interface AttributeProps {
    attribute: Attribute;
    selectedAttributes: SelectedAttributes[];
    changeSelectedAttributes: (selected: SelectedAttributes[]) => void;
}

const Attribute = ({ attribute, selectedAttributes, changeSelectedAttributes }: AttributeProps) => {
    const [showAll, setShowAll] = useState<boolean>(false);
    const [hideAttributes, setHideAttributes] = useState<boolean>(false);

    const handleCheckboxChange = (value: string) => {
        const attributeId = attribute.id;
        const existingAttribute = selectedAttributes.find((item) => item.attribute === attributeId);

        if (existingAttribute) {
            const updatedValues = existingAttribute.values.includes(value)
                ? existingAttribute.values.filter((v) => v !== value)
                : [...existingAttribute.values, value];

            const updatedAttribute = {
                ...existingAttribute,
                values: updatedValues
            };

            const updatedSelected =
                updatedValues.length > 0
                    ? selectedAttributes.map((item) => (item.attribute === attributeId ? updatedAttribute : item))
                    : selectedAttributes.filter((item) => item.attribute !== attributeId);

            changeSelectedAttributes(updatedSelected);
        } else {
            const newSelectedAttribute: SelectedAttributes = {
                attribute: attributeId,
                values: [value]
            };
            changeSelectedAttributes([...selectedAttributes, newSelectedAttribute]);
        }
    };

    const displayedValues = showAll ? attribute.values : attribute.values.slice(0, 3);

    const dropdownClassName = hideAttributes ? 'ms-3 dropdown-toggle show' : 'ms-3 dropdown-toggle';

    return (
        <div>
            <div className='flex-row justify-content-start dropup'>
                <p className='text-sm font-weight-bold ms-2 mb-0'>
                    {attribute.name}
                    <a
                        href='#'
                        onClick={() => setHideAttributes((prev) => !prev)}
                        className={dropdownClassName}
                    />
                </p>
            </div>
            {hideAttributes ? (
                <></>
            ) : (
                <div className='ms-2 mt-0'>
                    {displayedValues.map((v) => (
                        <CheckBox
                            label={v}
                            checked={selectedAttributes.some((item) => item.attribute === attribute.id && item.values.includes(v))}
                            onChange={() => handleCheckboxChange(v)}
                            submitted={false}
                            key={v}
                        />
                    ))}
                    <p className='text-sm font-weight-bold ms-2 mb-0'>
                        <a
                            href='#'
                            onClick={() => setShowAll((prev) => !prev)}>
                            {showAll ? (
                                <FormattedMessage
                                    id='FilterBar.Less'
                                    description='Text to display less items'
                                    defaultMessage='Less'
                                />
                            ) : (
                                <FormattedMessage
                                    id='FilterBar.more'
                                    description='Text to display more items'
                                    defaultMessage='More'
                                />
                            )}
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};
