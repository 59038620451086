import { useEffect, useState } from 'react';

import { SpotLayoutItem } from '../../api/SpotLayoutItems';

interface Props {
    spotLayoutItem: SpotLayoutItem;
    spotWidth: number;
    spotHeight: number;
    label?: string;
    occupied?: boolean;
    selected?: boolean;
    highlightedSlots?: boolean;
    highlighted?: boolean;
    onClick?: (item: SpotLayoutItem) => void;
    id?: string;
}

const Item = (props: Props) => {
    const [itemClass, changeItemClass] = useState<string>('normal');
    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${(100 * props.spotLayoutItem.origin_x) / props.spotWidth}%`,
        right: `${100 - (100 * (props.spotLayoutItem.origin_x + props.spotLayoutItem.width)) / props.spotWidth}%`,
        bottom: `${(100 * props.spotLayoutItem.origin_y) / props.spotHeight}%`,
        top: `${100 - (100 * (props.spotLayoutItem.origin_y + props.spotLayoutItem.height)) / props.spotHeight}%`
    };

    const centerStyle: React.CSSProperties = {
        textAlign: 'center',
        position: 'absolute',
        top: '40%',
        left: '0',
        right: '0',
        color: 'white'
    };

    useEffect(() => {
        if (props.spotLayoutItem.terminal) {
            changeItemClass('terminal');
        } else if (props.highlighted === true) {
            changeItemClass('highlighted');
        } else if (props.occupied === true) {
            changeItemClass('occupied');
        } else if (props.selected) {
            changeItemClass('selected');
        } else {
            changeItemClass('normal');
        }
    }, [props.spotLayoutItem, props.occupied, props.selected]);

    const label = props.label ? props.label : props.spotLayoutItem.name;

    const handleClick = () => {
        props.onClick && props.onClick(props.spotLayoutItem);
    };

    return (
        <div
            style={style}
            id={props.id}
            className={'spotlayout-item spotlayout-item-' + itemClass}
            onClick={handleClick}>
            <div style={centerStyle}>{label}</div>
        </div>
    );
};

export default Item;
