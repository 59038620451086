import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';

import TextInput from '../forms/textInput/TextInput';

interface AsyncFilterProps<T> {
    id?: string;
    filterKeys: string[];
    objects: T[];
    filterValue?: (value: string) => void;
    filterCallback: (list: T[]) => void;
    useSearch?: boolean;
    placeholder?: string;
    label?: React.ReactNode;
}

const Filter = <T,>(props: AsyncFilterProps<T>) => {
    const [filterValue, changeFilterValue] = useState<string>('');

    useEffect(() => {
        const timeOutId = setTimeout(() => filter(filterValue), 150);
        return () => clearTimeout(timeOutId);
    }, [filterValue]);

    const fuseOptions = {
        includeScore: true,
        keys: props.filterKeys,
        fieldNormWeight: 1,
        threshold: 0,
        ignoreLocation: true
    };
    const fuse = new Fuse(props.objects, fuseOptions);

    const filter = async (value: string) => {
        if (props.useSearch) {
            props.filterValue && props.filterValue(filterValue);
        } else {
            const result = await new Promise((resolve, reject) => {
                if (value === '') {
                    resolve(props.objects);
                }
                const fuseResults = fuse.search(value);
                const result = fuseResults.map((res) => res.item);
                resolve(result);
            });
            props.filterValue && props.filterValue(filterValue);
            props.filterCallback(result as any[]);
        }
    };

    return (
        <TextInput
            id={props.id ? props.id : 'AsyncFilter'}
            label={props.label ? props.label : 'Filter'}
            placeholder={props.placeholder ? props.placeholder : 'Filter here...'}
            value={filterValue}
            onChange={changeFilterValue}
            submitted={false}
        />
    );
};

export default Filter;
