import { PlatformHardwareDriver } from '../common/BackendEnums';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';

export interface SpotLayoutItem {
    id: number;
    url: string;
    name: string;
    spot_layout_url: string;
    spot_layout: number | string;
    slot_url?: string | null;
    slot?: string;
    terminal_url?: string | null;
    terminal?: number | string | null;
    origin_x: number;
    origin_y: number;
    width: number;
    height: number;
    hardware_id: string | null;
    hardware_driver: PlatformHardwareDriver | null;
    hardware_driver_options: {
        data?: SpotLayoutItemData[];
        ip?: string;
        slot?: string;
        board?: string;
        row?: string;
        column?: string;
    };
}
interface SpotLayoutItemData {
    config: SpotLayoutItemConfig;
    driver: string;
}
interface SpotLayoutItemConfig {
    ip: string;
    slot: string;
    board: string;
}

export enum SpotLayoutItemQueryParams {
    SPOT_LAYOUT = 'spot-layout'
}

const spotLayoutItemsViewSet: ApiViewSet = {
    baseName: 'spot-layout-items'
};

export async function fetchSpotLayoutItemsApi(queryParams?: ApiQueryParams<SpotLayoutItemQueryParams> | null): Promise<SpotLayoutItem[]> {
    return apiList<SpotLayoutItem, SpotLayoutItemQueryParams>(spotLayoutItemsViewSet, queryParams);
}

export async function fetchSpotLayoutItemApi(options: DetailOptions): Promise<SpotLayoutItem> {
    return apiDetail<SpotLayoutItem>(spotLayoutItemsViewSet, options);
}
