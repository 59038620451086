import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Auth from '../../authorization/Auth';
import LanguageDropdownButton from '../../components/language/LanguageDropdownButton';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppSelector } from '../../hooks/redux';
import useDetectDeviceViewport, { DeviceViewport } from '../../hooks/useDetectDeviceViewport';
import { Locale } from '../../hooks/useLocale';
import { AuthenticationManager } from '../../utils/AuthenticationManager';
import UrlBreadcrumbs from './UrlBreadcrumbs';

interface HeaderProps {
    changeLocale: (locale: Locale) => void;
    navbarPinned: () => void;
}

const Header = (props: HeaderProps) => {
    const viewport = useDetectDeviceViewport();
    const account = useAppSelector((state) => state.user.selectedMembership);

    return (
        <nav
            className='navbar navbar-main navbar-expand-lg px-2 shadow-none bg-primary'
            id='navbarBlur'
            data-scroll='false'>
            <div className='container-fluid py-1 px-3 mt-1'>
                <Auth role={DefaultRole.ADMIN}>
                    <div className='sidenav-toggler sidenav-toggler-inner d-xl-block d-none pe-5'>
                        <a
                            href='#'
                            onClick={props.navbarPinned}
                            className='nav-link p-0'>
                            <div className='sidenav-toggler-inner'>
                                <i className='sidenav-toggler-line bg-light'></i>
                                <i className='sidenav-toggler-line bg-light'></i>
                                <i className='sidenav-toggler-line bg-light'></i>
                            </div>
                        </a>
                    </div>
                </Auth>
                {viewport === DeviceViewport.PC && <UrlBreadcrumbs />}
                <div
                    className='collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4'
                    id='navbar'>
                    <div className='ms-md-auto pe-md-0 d-flex align-items-center'>
                        {AuthenticationManager.getInstance().loggedIn && account !== null ? (
                            <div className='d-flex flex-row align-items-start pe-2'>
                                {viewport === DeviceViewport.PC && (
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div className='d-flex flex-column justify-content-end text-end p-0 me-2'>
                                            <div className='m-0 p-0 d-flex justify-content-between'>
                                                <span className='small pe-3'>{account.account.name}</span>
                                                <span className='small text-white'>
                                                    {account.account.contacts.length > 0
                                                        ? account.account.contacts[0]?.first_name + ' ' + account.account.contacts[0]?.last_name
                                                        : ''}
                                                </span>
                                            </div>
                                            <span className='small text-white'>{account.account.contacts[0]?.email}</span>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <Link
                                        id='UserInfoPageNavigationButton'
                                        to={'/me'}
                                        className='btn btn-light'>
                                        <FaUser />
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <LanguageDropdownButton changeLocale={props.changeLocale} />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
