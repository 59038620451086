import { combineReducers, configureStore } from '@reduxjs/toolkit';

import attributeReducer from './attributeSlice';
import categoryReducer from './categorySlice';
import contactGroupReducer from './contactGroupSlice';
import contactReducer from './contactSlice';
import dashboardReducer from './dashboardSlice';
import accessControllerReducer from './iot/accessControllerSlice';
import printerReducer from './iot/printerSlice';
import logSliceReducer from './logSlice';
import productAttributeReducer from './productAttributeSlice';
import productReducer from './productSlice';
import reportCategoryReducer from './reportCategoriesSlice';
import reportReducer from './reportSlice';
import shopReducer from './shopSlice';
import slotReducer from './slotSlice';
import slotLogsReducer from './slots/slotLogsSlice';
import spotLayoutReducer from './spotLayoutSlice';
import spotReducer from './spotSlice';
import terminalReducer from './terminalSlice';
import transactionReducer from './transactionSlice';
import userInvitesReducer from './userInvitesSlice';
import userReducer from './userSlice';

export const iotReducer = combineReducers({
    printer: printerReducer,
    accessController: accessControllerReducer
});

export const store = configureStore({
    reducer: {
        user: userReducer,
        userInvites: userInvitesReducer,
        spots: spotReducer,
        transactions: transactionReducer,
        contacts: contactReducer,
        slots: slotReducer,
        contactGroups: contactGroupReducer,
        terminals: terminalReducer,
        spotLayouts: spotLayoutReducer,
        shops: shopReducer,
        products: productReducer,
        attributes: attributeReducer,
        categories: categoryReducer,
        reportCategories: reportCategoryReducer,
        slotLogs: slotLogsReducer,
        printers: printerReducer,
        logs: logSliceReducer,
        iot: iotReducer,
        dashboard: dashboardReducer,
        reports: reportReducer,
        productAttributes: productAttributeReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export interface ReduxError {
    json?: { [key: string]: unknown };
    message: string;
    stack: string;
}
