import { postApi } from '../api/utils';
import { Logger } from '../logging/Logger';
import { WebLogViewModel } from '../logging/WebLog';
import { LogMessageTypeName } from '../logs/classes/LogMessageType';
import { LogSourceName } from '../logs/classes/LogSource';

export async function BackendStatusUpdate(accountId: number) {
    const backendUrl = process.env.REACT_APP_API_URL + '/activity-logs/';

    Logger.getLogs().then(async (logs) => {
        if (logs) {
            const res = await postApi(
                backendUrl,
                logs.map((l) => mapWebLogViewModel(l, accountId))
            );
            if (res.ok) {
                logs && Logger.removeByids(logs.map((l) => l.raw_data.id));
            }
        }
    });
}

interface WebLogRequestModel {
    account: number;
    event_timestamp: number;
    message: string;
    message_type: LogMessageTypeName;
    raw_data: any;
    source: LogSourceName;
}

function mapWebLogViewModel(log: WebLogViewModel, accountId: number): WebLogRequestModel {
    return {
        account: accountId,
        event_timestamp: log.event_timestamp,
        message: log.message,
        message_type: log.message_type,
        raw_data: log.raw_data,
        source: log.logSource
    };
}
