import { UpdatedSlotsList } from '../../catalogue/products/TransferLists/TransferListAssignedSlots/TransferListAssignedSlots';
import { ApiViewSet, DetailOptions, apiDetail, apiPagination } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';
import { ApiError, deleteApi, patchApi, postApi, putApi } from '../utils';
import { Attribute } from './Attributes';
import { Shop } from './Shops';

export interface Product {
    id: number;
    url: string;
    account: number;
    category: number | null;
    shops: string;
    shops_list: Shop[];
    instances: string;
    name: string;
    description: string;
    image: string | null;

    product_data: object | null;

    ean: string[];
    product_codes: string[];
    sku: string[];
    alternative_product_codes: string[];

    attributes: Attribute[];
    product_attributes: ProductAttribute[];

    ordering_allowed: boolean;
    slots: number[];
    weight: number;
    weight_tolerance: number;
    height: number;
    width: number;
    diameter: number;
    length: number;
    low_stock_threshold_alert: number;
    max_stock: number;
    stock_count: number;
    computed_stock_count: number;
    product_shops: number[];
}

export interface ProductResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: Product[];
}

export interface ProductAttribute {
    id: number;
    url: string;
    account: number;
    product: number;
    attribute: number;
    value: string;
}

export interface ProductSlotsModel {
    id: string;
    updatedSlotsList: UpdatedSlotsList | undefined;
}

export interface CreateProductModel {
    account: number;
    category: { value: number; label: string } | null;
    product_shops: number[];
    name: string;
    description: string | undefined;
    image?: File | string | null;
    product_data: string | undefined;
    product_codes: string[] | undefined;
    alternative_product_codes: string[] | undefined;
    ordering_allowed: boolean;
    slots: number[] | undefined;
    weight: number | undefined;
    weight_tolerance: number | undefined;
    height: number | undefined;
    width: number | undefined;
    diameter: number | undefined;
    length: number | undefined;
    max_stock: number | undefined;
    stock_count: number;
    low_stock_threshold_alert: number | undefined;
}

export enum ProductsQueryParams {
    SHOP = 'shop',
    SHOW = 'show_all',
    PAGE = 'page',
    PAGE_SIZE = 'page_size',
    ACCOUNT = 'account',
    SEARCH = 'search'
}

export interface UploadOptions {
    account_id: number;
    images?: FormData;
    csv_file?: FormData;
}

export interface CreateProductParams {
    body: FormData;
}

export interface UpdateProductParams extends CreateProductParams {
    id: number;
}

const productViewSet: ApiViewSet = {
    baseName: 'products'
};

export async function fetchProductsApi(queryParams?: ApiQueryParams<ProductsQueryParams> | null): Promise<ProductResponse> {
    return await apiPagination<ProductResponse, ProductsQueryParams>(productViewSet, queryParams);
}

export function fetchProductApi(options: DetailOptions): Promise<Product> {
    return apiDetail<Product>(productViewSet, options);
}

export async function updateSlotsOfProductApi(options: ProductSlotsModel): Promise<Product> {
    let slotsUpdateUrl = '/products/';

    if (options && options.id) {
        slotsUpdateUrl += options.id + '/';
    }

    const body = options.updatedSlotsList;
    const response = await patchApi(slotsUpdateUrl, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating Slots');
        }
        throw new ApiError('Error updating Slots');
    }
    return await response.json();
}

export async function createProductApi(options: CreateProductParams): Promise<Product> {
    const URL = '/products/';
    const body = options.body;
    const response = await postApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating product');
        }
        throw new ApiError('Error creating product', json);
    }
    return await response.json();
}

export async function updateProductApi(options: UpdateProductParams): Promise<Product> {
    let URL = '/products/';
    if (options && options.id) {
        URL += options.id + '/';
    }
    const body = options.body;

    const response = await putApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating product');
        }
        throw new ApiError('Error updating product');
    }

    return await response.json();
}

export async function deleteProductApi(productId: number): Promise<boolean> {
    const URL = `/products/${productId}/`;
    const response = await deleteApi(URL);
    return await response.ok;
}

export async function uploadProductImagesApi(body: FormData): Promise<{ data: string }> {
    const URL = `/product-upload/images/`;
    const response = await postApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error uploading images');
        }
        throw new ApiError('Error uploading images');
    }

    return await response.json();
}

export async function uploadProductCSVApi(body: FormData): Promise<{ data: string }> {
    const URL = `/product-upload/`;
    const response = await postApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error uploading CSV');
        }
        throw new ApiError('Error uploading CSV');
    }
    return await response.json();
}

export async function searchProductsApi(queryParams: ApiQueryParams<ProductsQueryParams>): Promise<ProductResponse> {
    return await apiPagination<ProductResponse, ProductsQueryParams>(productViewSet, queryParams);
}
