import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiQueryParams } from '../api/BaseQueryParams';
import { SpotLayoutItem, SpotLayoutItemQueryParams, fetchSpotLayoutItemsApi } from '../api/SpotLayoutItems';
import { SpotLayout, SpotLayoutQueryParams, fetchSpotLayoutsApi } from '../api/SpotLayouts';
import { ApiError } from '../api/utils';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface SpotLayoutState {
    spotLayoutList: SpotLayout[] | null;
    status: SliceStatus;
    lastUpdate: number;
    message: string;
    error: ReduxError | null;

    spotLayoutItemsMap: { [spotLayoutId: number]: SpotLayoutItem[] };
    itemsStatus: SliceStatus;
    itemsLastUpdate: { [spotLayoutId: number]: number };
}

const initialState: SpotLayoutState = {
    spotLayoutList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: '',
    error: null,

    spotLayoutItemsMap: {},
    itemsStatus: SliceStatus.INIT,
    itemsLastUpdate: {}
};

export const SpotLayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpotLayouts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotLayouts.fulfilled, (state, action) => {
                state.spotLayoutList = action.payload;
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSpotLayouts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })

            .addCase(fetchSpotLayoutItems.pending, (state) => {
                state.itemsStatus = SliceStatus.LOADING;
            })
            .addCase(fetchSpotLayoutItems.fulfilled, (state, action) => {
                const spotId = action.meta.arg['spot-layout'];
                if (!spotId) {
                    console.error('No ID was provided.');
                } else {
                    state.spotLayoutItemsMap[+spotId] = action.payload;
                    state.itemsLastUpdate[+spotId] = Date.now();
                }
                state.itemsStatus = SliceStatus.IDLE;
            })
            .addCase(fetchSpotLayoutItems.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchSpotLayouts = createAsyncThunk<SpotLayout[], ApiQueryParams<SpotLayoutQueryParams>>(
    'fetchSpotLayouts',
    async (queryParams: ApiQueryParams<SpotLayoutQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchSpotLayoutsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotLayoutItems = createAsyncThunk<SpotLayoutItem[], ApiQueryParams<SpotLayoutItemQueryParams>>(
    'fetchSpotLayoutItems',
    async (queryParams: ApiQueryParams<SpotLayoutItemQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchSpotLayoutItemsApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default SpotLayoutSlice.reducer;
