import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { Attribute } from '../../../api/catalogue/Attributes';
import { ClassType } from '../../../common/BootstrapValues';
import Filter from '../../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import CreateModal from '../../../components/modals/CreateModal';
import Table from '../../../components/tables/Table';
import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAttributes } from '../../../store/attributeSlice';
import { fetchCategories } from '../../../store/categorySlice';
import { fetchProducts } from '../../../store/productSlice';
import { SliceStatus, shouldFetch } from '../../../store/utils/Redux';
import AttributeDetailModal from '../attribute-detail/AttributeDetailModal';
import CreateAttribute from '../forms/CreateAttributeForm';
import AttributeListColumns from './AttributeListColumns';
import AttributeListElement from './AttributeListElement';

const ATTRIBUTE_LIST_ID = 'AttributeList';
export default function AttributeList() {
    const authorised = useAuthorization(DefaultRole.SUPER_ADMIN, true);

    const { shopId } = useParams();
    const [URL_ATTRIBUTE_ID, changeURL_ATTRIBUTE_ID] = useState<string | null>(new URLSearchParams(window.location.search).get('id'));
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const attributes = useAppSelector((state) => state.attributes);
    const products = useAppSelector((state) => state.products);
    const categories = useAppSelector((state) => state.categories);
    const [createAttributeOpen, changeCreateAttributeOpen] = useState<boolean>(false);

    const [selectedAttribute, changeSelectedAttribute] = useState<Attribute>();
    const [filteredAttributes, changeFilteredAttributes] = useState<Attribute[] | null>(attributes.attributeList);
    const [filterValue, setFilterValue] = useState<string>();
    const [textFilter, changeTextFilter] = useState<Attribute[] | undefined>(undefined);

    useEffect(() => {
        if (authorised) dispatch(fetchAttributes({ account: accountId.toString() }));
    }, []);
    useEffect(() => {
        if (authorised && attributes.attributeList !== null && attributes.status !== SliceStatus.LOADING) {
            if (shouldFetch(products.status, products.lastUpdate))
                dispatch(fetchProducts({ show_all: 'true', page: '1', page_size: '1000', account: accountId.toString() }));
            if (shouldFetch(categories.status, categories.lastUpdate)) dispatch(fetchCategories({ account: accountId.toString() }));
        }
        if (selectedAttribute && attributes.attributeList) {
            const foundAttribute = attributes.attributeList.find((a) => a.id === selectedAttribute.id);
            if (foundAttribute) changeSelectedAttribute(foundAttribute);
        }
    }, [attributes.attributeList, attributes.status]);
    useEffect(() => {
        if (!attributes.attributeList) return;

        let validAttributes: Attribute[] = attributes.attributeList;

        if (textFilter && textFilter.length === 0 && filterValue === '') {
            validAttributes = attributes.attributeList;
        }

        if (textFilter && filterValue && filterValue.length > 0) {
            validAttributes = validAttributes.filter((a) => textFilter.some((tf) => tf.id === a.id));
        }
        changeFilteredAttributes(validAttributes);
    }, [attributes, filterValue]);

    const data = useMemo(() => {
        if (filteredAttributes) return filteredAttributes;
        else return [];
    }, [filteredAttributes]);
    const columns: Column<Attribute>[] = useMemo(() => AttributeListColumns, []);

    if (filteredAttributes === null) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }

    return (
        <>
            <div
                id={ATTRIBUTE_LIST_ID}
                className='card'>
                <div className='card-header d-flex'>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <div className='d-flex flex-row justify-content-between align-items-end w-100'>
                            <HorizontalButtonGroup
                                buttons={[
                                    {
                                        type: ClassType.PRIMARY,
                                        hide: false,
                                        id: 'AttributeList-CreateAttributeButton',
                                        text: (
                                            <FormattedMessage
                                                id='AttributeList.CreateAttribute'
                                                description='Button for creating attribute'
                                                defaultMessage='Create Attribute'
                                            />
                                        ),
                                        onClick: () => changeCreateAttributeOpen(true),
                                        icon: FaPlus
                                    }
                                ]}
                                direction='left'
                            />
                            <div className='ms-md-auto pe-md-3 d-flex align-items-center'>
                                <Filter
                                    objects={filteredAttributes}
                                    filterKeys={['name']}
                                    filterValue={(value) => setFilterValue(value.toLowerCase())}
                                    filterCallback={changeTextFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={data}
                    onSelectElement={changeSelectedAttribute}
                    RowElement={AttributeListElement}
                />
            </div>
            {createAttributeOpen && (
                <CreateModal
                    show={createAttributeOpen}
                    handleClose={() => changeCreateAttributeOpen(false)}
                    header='Create Attribute'>
                    <CreateAttribute handleClose={() => changeCreateAttributeOpen(false)} />
                </CreateModal>
            )}
            {selectedAttribute && (
                <AttributeDetailModal
                    attribute={selectedAttribute}
                    handleClose={() => changeSelectedAttribute(undefined)}
                    show={!!selectedAttribute}
                    customWidth={30}
                />
            )}
        </>
    );
}
