import React, { useEffect, useState } from 'react';
import Select, { Props } from 'react-select';

import { getInputClass, getInputClassMultiSelect } from '../../../utils/FormUtils';

export interface SelectInputProps extends Props {
    label: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: any[] | any;
    useConditionedStyling?: boolean;
    errorMessage?: React.ReactNode;
    resetValue?: boolean;
}

const SelectInput = (props: SelectInputProps) => {
    const [value, changeValue] = useState<any>();
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if ((props.value === undefined || props.value === null) && props.required === true) check = false;
        if (props.isMulti) {
            if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        } else {
            if (props.value && (props.value.value || props.value.value === undefined || props.value.value.length === 0)) {
                if (
                    check === true &&
                    props.required === true &&
                    (props.value.value === undefined || props.value.value === null || props.value.value.length === 0)
                )
                    check = false;
            } else {
                if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
            }
        }
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    useEffect(() => {
        if (props.resetValue) {
            changeValue(null);
        }
    }, [props.resetValue]);

    useEffect(() => {
        if (props.options) {
            if (props.value) {
                if (props.value.value) {
                    changeValue(props.options.find((op: any) => op.value == props.value.value));
                } else {
                    changeValue(props.options.find((op: any) => op.value == props.value));
                }
            }
        } else changeValue(null);
    }, [props.value]);

    const multiValues = props.isMulti === true && props.value !== undefined ? props.options?.filter((op: any) => props.value.includes(op.value)) : [];
    return (
        <div
            className='form-group'
            style={{ zIndex: 999 }}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
            </label>
            <Select
                {...props}
                options={props.options}
                value={props.isMulti ? multiValues : value}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        minWidth: '190px',
                        padding: '1.204px 0px 1.204px 10px',
                        borderRadius: '0.5rem'
                    }),
                    menu: (baseStyle, state) => ({
                        ...baseStyle,
                        zIndex: 9999
                    }),
                    valueContainer: (baseStyle, state) => ({
                        ...baseStyle,
                        padding: '0px'
                    })
                }}
                id={props.id}
                classNames={{
                    control: (state) =>
                        props.useConditionedStyling === false
                            ? 'form-control'
                            : props.isMulti
                            ? getInputClassMultiSelect(touched, valid, props.value)
                            : getInputClass(touched, valid, props.value)
                }}
                onChange={(newValue: any, actionMeta) => {
                    props.onChange && props.onChange(props.isMulti ? newValue.map((s: any) => s.value) : newValue, actionMeta);
                    changeValue(newValue);
                    isValid();
                }}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                onFocus={() => {
                    changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                isClearable={props.isClearable}
                isDisabled={props.isDisabled}
                defaultValue={props.defaultValue}
            />
            {props.useConditionedStyling === false ? (
                <></>
            ) : valid === false && touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SelectInput;
