import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const UrlBreadcrumbs = () => {
    const location = useLocation();
    const [breadcrumbs, changeBreadcrumbs] = useState<string[]>([]);
    useEffect(() => {
        const temp = location.pathname.split('/');
        temp.shift();
        if (temp[0] === 'qr') return;
        changeBreadcrumbs(
            temp.map((b) => {
                if (b.includes('?')) {
                    return b.split('?')[0];
                }
                return b;
            })
        );
    }, [location]);

    return (
        <nav aria-label='breadcrumb'>
            <ol className='breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5'>
                <li className='breadcrumb-item text-sm'>
                    <a
                        className='opacity-5 text-white'
                        href='/'>
                        Home
                    </a>
                </li>

                {breadcrumbs.map((value, index) => {
                    if (index === breadcrumbs.length - 1) {
                        return (
                            <li
                                key={value}
                                className='breadcrumb-item text-sm text-white active'
                                aria-current='page'>
                                {value}
                            </li>
                        );
                    }

                    return (
                        <li
                            key={value}
                            className='breadcrumb-item text-sm'
                            aria-current='page'>
                            <Link
                                className='opacity-5 text-white'
                                to={'/' + breadcrumbs.slice(0, index + 1).join('/')}>
                                {value}
                            </Link>
                        </li>
                    );
                })}
            </ol>

            <h6 className='font-weight-bolder text-white mb-0'>{breadcrumbs[breadcrumbs.length - 1]}</h6>
        </nav>
    );
};

export default UrlBreadcrumbs;
