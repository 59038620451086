import { Row } from 'react-table';

import { RowProps } from '../../../components/tables/rows/TRow';
import { TableCategory } from './CategoryList';

interface CategoryListElementProps extends RowProps {
    row: Row<TableCategory>;
    onClick: (element: TableCategory) => void;
}

const CategoryListElement = (props: CategoryListElementProps) => {
    const style = props.row.isExpanded && props.row.canExpand ? 'bg bg-secondary text-light' : '';
    const textStyle = props.row.isExpanded && props.row.canExpand ? 'text-light text-xs font-weight-bold' : 'text-secondary text-xs font-weight-bold';
    return (
        <tr
            onClick={() => props.onClick(props.row.original)}
            className={style}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td>
                <span className={textStyle}>{props.row.original.id}</span>
            </td>
            <td>
                <span className={textStyle}>
                    {props.row.original.image ? (
                        <img
                            src={props.row.original.image}
                            height='150'
                        />
                    ) : (
                        <>-</>
                    )}
                </span>
            </td>
            <td>
                <span className={textStyle}>{props.row.original.name}</span>
            </td>

            {props.expandEnable === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[props.row.cells.length - 1].getCellProps()}>
                    {props.row.cells[props.row.cells.length - 1].render('Cell')}
                </td>
            )}
        </tr>
    );
};

export default CategoryListElement;
