import { ContactGroupType } from '../common/BackendEnums';
import { CreateContactGroupModel } from '../contact-groups/forms/CreateContactGroupForm';
import { UpdateContactGroupModel } from '../contact-groups/forms/UpdateContactGroupForm';
import { ApiViewSet, DetailOptions, apiDetail, apiList, apiPagination } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';
import { ApiError, deleteApi, postApi, putApi } from './utils';

export interface ContactGroup {
    id: number;
    url: string;
    name: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    account: number | null;
    parent: string | null;
    children: string | null;
    contacts: string | null;
    group_type: ContactGroupType;
    group_logo: string | null;
    group_color: string;
    sharing_allowed: boolean;
}

export interface ContactGroupResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: ContactGroup[];
}

export const isContactGroup = (contact: any): contact is ContactGroup => {
    return (contact as ContactGroup).url !== undefined && (contact as ContactGroup).url.includes('/contact-groups/');
};

export interface ContactGroupOptions {
    id?: number;
    contactGroup?: UpdateContactGroupModel;
}

export enum ContactGroupQueryParams {
    PARENT = 'parent',
    ACCOUNT = 'account',
    CONTACT = 'contact',
    GROUP_TYPE = 'group_type',
    PAGE = 'page',
    PAGE_SIZE = 'page_size',
    SEARCH = 'search'
}

const contactGroupsViewSet: ApiViewSet = {
    baseName: 'contact-groups'
};

export async function fetchContactGroupsApi(queryParams?: ApiQueryParams<ContactGroupQueryParams> | null): Promise<ContactGroupResponse> {
    return await apiPagination<ContactGroupResponse, ContactGroupQueryParams>(contactGroupsViewSet, queryParams);
}

export function fetchContactGroupApi(options: DetailOptions): Promise<ContactGroup> {
    return apiDetail<ContactGroup>(contactGroupsViewSet, options);
}

export async function createContactGroupApi(contactGroup: CreateContactGroupModel): Promise<ContactGroup> {
    const addContactGroupUrl = '/contact-groups/';
    const response = await postApi(addContactGroupUrl, contactGroup);
    if (!response.ok) {
        let json;
        try {
            json = await response.json;
        } catch (e) {
            throw new ApiError('Error creating contact group');
        }
        throw new ApiError('Error creating contact group');
    }

    return await response.json();
}

export async function updateContactGroupApi(options: ContactGroupOptions): Promise<ContactGroup> {
    let updateContactGroupUrl = '/contact-groups/';
    if (options && options.id) {
        updateContactGroupUrl += options.id + '/';
    }
    const body = options.contactGroup;

    const response = await putApi(updateContactGroupUrl, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating contact group');
        }
        throw new ApiError('Error updating contact group');
    }
    return await response.json();
}

export async function deleteContactGroupApi(contactGroupId: number): Promise<boolean> {
    const URL = `/contact-groups/${contactGroupId}/`;
    const response = await deleteApi(URL);
    return await response.ok;
}

export async function searchContactGroupsApi(queryParams?: ApiQueryParams<ContactGroupQueryParams>): Promise<ContactGroup[]> {
    return await apiList<ContactGroup, ContactGroupQueryParams>(contactGroupsViewSet, queryParams);
}
