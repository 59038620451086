import { Logger } from '../logging/Logger';
import { ApiQueryParams, buildApiEndpoint } from './BaseQueryParams';
import { ApiError, fetchApi } from './utils';

export enum DefaultViewSetActions {
    LIST = 'list',
    DETAIL = 'detail'
}

export interface ApiViewSet {
    baseName: string;
    endpoints?: {
        [key in DefaultViewSetActions]?: string | null;
    };
}

export type DetailOptions =
    | {
          id: string | number;
          url?: never;
      }
    | {
          id?: never;
          url: string;
      };

function getEndpoint(viewSet: ApiViewSet, action: DefaultViewSetActions, id?: string | number | null): string {
    const endpoint = viewSet.endpoints ? viewSet.endpoints[action] : undefined;
    if (endpoint) {
        return endpoint;
    }
    if (action === DefaultViewSetActions.LIST) {
        return `/${viewSet.baseName}/`;
    } else if (action === DefaultViewSetActions.DETAIL) {
        return `/${viewSet.baseName}/${id}/`;
    } else {
        Logger.log('Unexpected viewset action', undefined, action);
        return `/${viewSet.baseName}/`;
    }
}

export async function apiList<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null): Promise<T[]> {
    const baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
    const endpoint = buildApiEndpoint(baseEndpoint, queryParams);
    const response = await fetchApi(endpoint, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError(`Error fetching list of ${viewSet.baseName}`);
        }
        throw new ApiError(`Error fetching list of ${viewSet.baseName}`, json);
    }
    return await response.json();
}

export async function apiDetail<T>(viewSet: ApiViewSet, options: DetailOptions): Promise<T> {
    let endpointUrl = options.url;
    if (!endpointUrl) {
        if (!options.id) {
            Logger.log('Unexpected empty url and id', {}, viewSet, options);
        }
        endpointUrl = getEndpoint(viewSet, DefaultViewSetActions.DETAIL, options.id);
    }
    const response = await fetchApi(endpointUrl, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError(`Error fetching detail of ${viewSet.baseName}`);
        }
        throw new ApiError(`Error fetching detail of ${viewSet.baseName}`, json);
    }
    return await response.json();
}

export async function apiPagination<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null, url?: string): Promise<T> {
    let endpoint;
    if (url) {
        endpoint = buildApiEndpoint(url, queryParams);
    } else {
        const baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
        endpoint = buildApiEndpoint(baseEndpoint, queryParams);
    }

    const response = await fetchApi(endpoint, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError(`Error fetching  ${viewSet.baseName}`);
        }
        throw new ApiError(`Error fetching  ${viewSet.baseName}`, json);
    }
    return await response.json();
}
