export const msalConfig = {
    auth: {
        clientId: 'f20b7ae8-fd36-4031-abae-8908d9d9dee2',
        authority: `https://login.microsoftonline.com/a72d5a72-25ee-40f0-9bd1-067cb5b770d4`,
        redirectUri: process.env.REACT_APP_REDIRECT_URL!
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me'
};

//REACT_APP_MICROSOFT_AUTH_URL       = https://platform.test.telloport.net/rest-auth/microsoft/
//REACT_APP_MICROSOFT_TENANT_ID      = a72d5a72-25ee-40f0-9bd1-067cb5b770d4
//REACT_APP_MICROSOFT_CLEINT_ID      = f20b7ae8-fd36-4031-abae-8908d9d9dee2
