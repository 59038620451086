import { useEffect, useState } from 'react';

import useLocale, { Locale, getCorrectLocale, getLocaleStringRepresentation } from '../../hooks/useLocale';

interface LanguageDropdownButtonProps {
    changeLocale: (locale: Locale) => void;
    buttonType?: string;
}

const LanguageDropdownButton = (props: LanguageDropdownButtonProps) => {
    const { locale } = useLocale();
    const [selected, changeSelected] = useState<string | undefined>(locale?.toString());
    useEffect(() => {
        changeSelected(locale?.toString());
    }, [locale]);
    const buttonClassName = props.buttonType ? props.buttonType + 'dropdown-toggle' : 'btn btn-light dropdown-toggle';

    return (
        <div className='dropdown'>
            <button
                type='button'
                className={buttonClassName}
                id='dropdownMenuButton'
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                {getLocaleStringRepresentation(selected ? getCorrectLocale(selected) : Locale.ENGLISH)}
            </button>
            <ul
                className='dropdown-menu'
                aria-labelledby='dropdownMenuButton'>
                <li>
                    <a
                        className='dropdown-item'
                        onClick={() => {
                            props.changeLocale(Locale.DUTCH);
                            changeSelected(Locale.DUTCH.toString());
                        }}>
                        {getLocaleStringRepresentation(Locale.DUTCH)}
                    </a>
                </li>
                <li>
                    <a
                        className='dropdown-item'
                        onClick={() => {
                            props.changeLocale(Locale.ENGLISH);
                            changeSelected(Locale.ENGLISH.toString());
                        }}>
                        {getLocaleStringRepresentation(Locale.ENGLISH)}
                    </a>
                </li>
                <li>
                    <a
                        className='dropdown-item'
                        onClick={() => {
                            props.changeLocale(Locale.FRENCH);
                            changeSelected(Locale.FRENCH.toString());
                        }}>
                        {getLocaleStringRepresentation(Locale.FRENCH)}
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default LanguageDropdownButton;
