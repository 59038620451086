import { ApiError, postApi } from './utils';

export interface changePassword {
    old_password: string;
    password: string;
    password_confirm: string;
}

export interface resetPasswordLink {
    email: string;
}

export interface resetPassword {
    user_id: string | null;
    timestamp: string | null;
    signature: string | null;
    password: string | null;
}

export async function changeUserPassword(passwordBody: changePassword): Promise<Response> {
    const passUrl = `${process.env.REACT_APP_REST_AUTH}/change-password/`;
    const response = await postApi(passUrl, passwordBody);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error chaning password');
        }
        throw new ApiError('Error changing password', json);
    }
    return await response.json();
}

export async function sendResetPasswordLink(email: resetPasswordLink): Promise<Response> {
    const resetLinkUrl = `${process.env.REACT_APP_REST_AUTH}/send-reset-password-link/`;
    const response = await postApi(resetLinkUrl, email, false);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error sending reset password link');
        }
        throw new ApiError('Error sending reset password link', json);
    }
    return await response.json();
}

export async function resetUserPassword(body: resetPassword): Promise<Response> {
    const resetUrl = `${process.env.REACT_APP_REST_AUTH}/reset-password/`;
    const response = await postApi(resetUrl, body, false);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error resetting password');
        }
        throw new ApiError('Error resetting password', json);
    }
    return await response.json();
}
