import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';

import { Attribute } from '../../../api/catalogue/Attributes';
import { Category } from '../../../api/catalogue/Categories';
import ShopCategoryBadge from '../../../components/badges/ShopCategoryBadge';
import ModalSectionTitle from '../../../components/modals/ModalSectionTitle';
import ObjectField from '../../../components/modals/ObjectField';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAttributes } from '../../../store/attributeSlice';

export default function CategoryDetail(props: { category: Category }) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const attributeState = useAppSelector((state) => state.attributes);
    const [attributeList, changeAttributeList] = useState<Attribute[]>([]);
    const attributeArray: Attribute[] = [];

    useEffect(() => {
        if (attributeState.attributeList === null) {
            dispatch(fetchAttributes({ account: accountId?.toString() }));
        }
    }, []);

    useEffect(() => {
        if (props.category.attributes) {
            props.category.attributes.forEach((a) => {
                if (attributeState.attributeList !== null) {
                    let foundAttribute: Attribute | undefined;
                    if (isNumber(a)) {
                        foundAttribute = attributeState.attributeList.find((att) => att.id === a);
                    } else {
                        foundAttribute = attributeState.attributeList.find((att) => att.id === a.id);
                    }
                    if (foundAttribute && foundAttribute !== undefined) {
                        const existingAttribute = attributeArray.find((att) => att.id === foundAttribute!.id);
                        if (!existingAttribute) {
                            attributeArray.push(foundAttribute);
                        }
                    }
                }
            });
            changeAttributeList(attributeArray);
        } else changeAttributeList([]);
    }, [props.category, attributeState.attributeList]);

    return (
        <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column w-100 h-100'>
                <ObjectField
                    value={props.category.name}
                    fieldName='Name'
                />
                {props.category.parent_id && (
                    <ObjectField
                        value={<ShopCategoryBadge categoryId={props.category.parent_id} />}
                        fieldName='Parent'
                    />
                )}
                {attributeList.length > 0 && (
                    <div className='d-flex justify-content-between'>
                        <strong>Attributes</strong>
                        <div className='d-flex justify-content-end'>
                            {attributeList.map((a) => (
                                <p
                                    key={a.id}
                                    className='pe-1'>
                                    {a.name}
                                </p>
                            ))}
                        </div>
                    </div>
                )}
                {props.category.image && (
                    <>
                        <ModalSectionTitle title='Image' />
                        <img src={props.category.image} />
                    </>
                )}
            </div>
        </div>
    );
}
