import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { Attribute } from '../../../api/catalogue/Attributes';
import { ProductAttributeBody } from '../../../api/catalogue/ProductAttributes';
import { Product } from '../../../api/catalogue/Products';
import { ClassType } from '../../../common/BootstrapValues';
import { CommonMessage } from '../../../common/CommonFormattedMessages/CommonMessage';
import SelectInput from '../../../components/forms/select/Select';
import Spinner from '../../../components/loading/Spinner';
import CreateModalFooter from '../../../components/modals/CreateModalFooter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAttributes } from '../../../store/attributeSlice';
import { createProductAttribute } from '../../../store/productAttributeSlice';
import { SliceStatus } from '../../../store/utils/Redux';
import { ToastUtil } from '../../../utils/toasts/Toasts';

interface CreateProductAttributeProps {
    product: Product;
    handleClose: () => void;
    onSuccess: () => void;
}
const CreateProductAttribute = (props: CreateProductAttributeProps) => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const attributeState = useAppSelector((state) => state.attributes);
    const productAttributeState = useAppSelector((state) => state.productAttributes);
    const [attributeList, setAttributeList] = useState<Attribute[]>([]);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute | undefined>(undefined);
    const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
    const [submitted, changeSubmitted] = useState<boolean>(false);

    const initialState: ProductAttributeBody = {
        account: accountId,
        product: props.product.id,
        attribute: undefined,
        value: undefined
    };
    const [formResult, setFormResult] = useState<ProductAttributeBody>(initialState);

    useEffect(() => {
        if (attributeState.attributeList === null) {
            dispatch(fetchAttributes({ account: accountId?.toString() }));
        } else {
            setAttributeList(attributeState.attributeList);
        }
    }, []);

    useEffect(() => {
        if (attributeState.attributeList) {
            if (props.product.attributes && props.product.attributes.length > 0) {
                const attributeIds = props.product.attributes.map((a) => a.id);
                const filteredAttributeList = attributeState.attributeList.filter((a) => !attributeIds.includes(a.id));
                setAttributeList(filteredAttributeList);
            } else {
                setAttributeList(attributeState.attributeList);
            }
        }
    }, [attributeState]);

    useEffect(() => {
        if (selectedAttribute) {
            setFormResult({ ...formResult, attribute: selectedAttribute.id });
        }

        if (selectedValue) {
            setFormResult({ ...formResult, value: selectedValue });
        }
    }, [selectedAttribute, selectedValue]);

    const [attributeValid, setAttributeValid] = useState<boolean>(false);
    const [valueValid, setValueValid] = useState<boolean>(false);
    const formValid = attributeValid && valueValid;

    const submit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
        if (formValid && event) {
            event.preventDefault();
            dispatch(createProductAttribute(formResult));
            changeSubmitted(true);
        }
    };

    useEffect(() => {
        if (submitted && productAttributeState.status === SliceStatus.IDLE) {
            toast.success(
                ...ToastUtil.generateToastConfig(
                    CommonMessage.STATUS.SUCCESS,
                    <FormattedMessage
                        id='CreateProductAttribute.Success'
                        description='Success message after creating product attribute'
                        defaultMessage='Your product attribute has been created successfully'
                    />,
                    ClassType.SUCCESS
                )
            );
            props.handleClose();
            props.onSuccess();
        } else if (submitted && productAttributeState.status === SliceStatus.ERROR) {
            toast.error(
                ...ToastUtil.generateToastConfig(
                    CommonMessage.STATUS.ERROR,
                    <FormattedMessage
                        id='CreateProductAttribute.Error'
                        description='Error message after failing to creating product attribute'
                        defaultMessage='An error occured while trying to create this product attribute'
                    />,
                    ClassType.DANGER
                )
            );
            props.handleClose();
        }
    }, [productAttributeState]);

    if (!props.product) {
        return <Spinner />;
    }
    return (
        <>
            <form
                method='post'
                onClick={(e) => e.preventDefault()}>
                <div
                    className='modal-body'
                    style={{ overflow: 'unset' }}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <SelectInput
                                id='CreateProductAttribute-AttributeSelect'
                                label={CommonMessage.FORM.ATTRIBUTES.ATTRIBUTE}
                                submitted={false}
                                options={attributeList.map((a) => ({ value: a, label: a.name }))}
                                onChange={(v: any) => setSelectedAttribute(v.value)}
                                value={formResult.attribute}
                                isValidCallback={(v) => setAttributeValid(v)}
                            />
                        </div>
                        <div className='col-md-6'>
                            {selectedAttribute && (
                                <SelectInput
                                    id='CreateProductAttribute-AttributeSelectValue'
                                    label={CommonMessage.FORM.ATTRIBUTES.VALUES}
                                    submitted={false}
                                    options={selectedAttribute.values.map((v) => ({ value: v, label: v }))}
                                    onChange={(v: any) => setSelectedValue(v.value)}
                                    value={formResult.value}
                                    isValidCallback={(v) => setValueValid(v)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <CreateModalFooter
                    handleClose={() => props.handleClose()}
                    onSubmit={(e) => submit(e)}
                    disabled={!selectedAttribute || !selectedValue}
                />
            </form>
        </>
    );
};

export default CreateProductAttribute;
