import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { Locale } from '../../common/BackendEnums';
import { ClassType } from '../../common/BootstrapValues';
import EmailInput from '../../components/forms/emailInput/EmailInput';
import PasswordInput from '../../components/forms/passwordInput/PasswordInput';
import PhoneInput from '../../components/forms/phoneInput/PhoneInput';
import SelectInput from '../../components/forms/select/Select';
import TextInput from '../../components/forms/textInput/TextInput';
import CreateModal from '../../components/modals/CreateModal';
import CreateModalFooter from '../../components/modals/CreateModalFooter';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import { Logger } from '../../logging/Logger';
import { addUser, fetchUsers } from '../../store/userSlice';
import { GetError } from '../../store/utils/GetError';
import { SliceStatus } from '../../store/utils/Redux';
import { Languages } from '../../utils/FormUtils';
import { parseObject } from '../../utils/ParseObject';
import { ToastUtil } from '../../utils/toasts/Toasts';
import AccountSelect from '../accounts/account-select/AccountSelect';
import RoleSelectModal from './roleselect/RoleSelectModal';

const USER_CREATE_MODAL_ID = 'UserCreateModal';

export interface CreateUserFormProps {
    handleClose: () => void;
}

const CreateUserForm = (props: CreateUserFormProps) => {
    const isSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const authorised = useAuthorization(DefaultRole.ADMIN, true);
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.user.selectedMembership?.account);
    const usersName = useAppSelector((state) => state.user.usersList?.results?.map((u) => u.username));
    const users = useAppSelector((state) => state.user);
    const [submitted, changeSubmitted] = useState(false);
    const [formResult, changeFormResult] = useState<UserForm>(initialState);
    const [userWasCreated, changeUserWasCreated] = useState(false);
    const [roleSelectOpen, changeRoleSelectOpen] = useState<boolean>(false);
    const { selectedUser } = useAccount();

    useEffect(() => {
        if (!isSuperAdmin) {
            changeFormResult({ ...formResult, account_members: [{ account: account!.id, role: 'admin' }] });
        }
    }, []);

    useEffect(() => {
        if (!userWasCreated && submitted && authorised) {
            const parsedForm = parseObject(formResult);
            dispatch(
                addUser({
                    account_members: parsedForm.account_members.map((ac: any) => ({ account: { id: ac.account }, role: ac.role })),
                    email: formResult.email,
                    first_name: formResult.first_name,
                    language: parsedForm.language,
                    last_name: formResult.last_name,
                    password: formResult.password
                })
            );
        }
    }, [submitted]);

    useEffect(() => {
        if (users.status === SliceStatus.ERROR && submitted) {
            toast.error(...ToastUtil.generateToastConfig(formResult.email, GetError(users.error), ClassType.DANGER));
            changeSubmitted(false);
            const formObject = parseObject(formResult);
            delete formObject.password;
            Logger.error(`${selectedUser?.email} failed to create a user`, { user: selectedUser?.id }, formObject, users.error?.json);
        }
    }, [users.status]);

    useEffect(() => {
        if (!userWasCreated && submitted && users.status === SliceStatus.IDLE) {
            const createdUser = usersName?.find((a) => a === formResult.email);
            if (createdUser) {
                dispatch(fetchUsers({ account: account?.id?.toString(), page: '1', page_size: '100' }));
                changeUserWasCreated(true);
                const formObject = parseObject(formResult);
                delete formObject.password;
                Logger.log(`${selectedUser?.email} created a user: ${createdUser}`, { user: selectedUser?.id }, formObject);
                toast.success(...ToastUtil.generateToastConfig(formResult.email, userCreatedSuccess, ClassType.SUCCESS));
                handleCancel();
            } else {
                toast.error(...ToastUtil.generateToastConfig(formResult.email, GetError(users.error), ClassType.DANGER));
                changeSubmitted(false);
            }
        }
    }, [users.usersList]);

    const handleCancel = () => {
        changeFormResult(initialState);
        changeSubmitted(false);
        changeUserWasCreated(false);
        props.handleClose();
    };

    const [PWValid, changePWValid] = useState(false);
    const [FNValid, changeFNValid] = useState(false);
    const [LNValid, changeLNValid] = useState(false);
    const [LangValid, changeLangValid] = useState(false);
    const [ACValid, changeACValid] = useState(false);
    const [EmailValid, changeEmailValid] = useState(false);
    const [PNValid, changePNValid] = useState(false);
    const [MBValid, changeMBValid] = useState(false);
    const formValid = LNValid && FNValid && LangValid && ACValid && EmailValid && PWValid && PNValid && MBValid;

    return (
        <>
            <form
                id={USER_CREATE_MODAL_ID}
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                    changeSubmitted(true);
                }}>
                <div
                    className='modal-body'
                    id='create-user-modal'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <EmailInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Email'
                                        description={'Form label for email'}
                                        defaultMessage='Email'></FormattedMessage>
                                }
                                value={formResult.email}
                                onChange={(value) => changeFormResult({ ...formResult, email: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.Email'
                                        description={'Error message for email'}
                                        defaultMessage='Please enter a valid email'
                                    />
                                }
                                isValidCallback={(value) => {
                                    changeEmailValid(value);
                                }}
                                id='CreateUserForm-EmailInput'
                                required
                                submitted={submitted}
                            />
                        </div>
                        <div className='col-md-6'>
                            <PasswordInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Password'
                                        description={'Form label for password'}
                                        defaultMessage='Password'></FormattedMessage>
                                }
                                value={formResult.password}
                                onChange={(value) => changeFormResult({ ...formResult, password: value })}
                                required
                                strength
                                compare={formResult.email}
                                isValidCallback={(value) => {
                                    changePWValid(value);
                                }}
                                id='CreateUserForm-PasswordInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.firstName'
                                        description={'Form label for first name'}
                                        defaultMessage='First name'></FormattedMessage>
                                }
                                value={formResult.first_name}
                                onChange={(value) => changeFormResult({ ...formResult, first_name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.firstName'
                                        description={'Error message for first name'}
                                        defaultMessage='Please enter a valid first name'
                                    />
                                }
                                isValidCallback={(value) => {
                                    changeFNValid(value);
                                }}
                                submitted={submitted}
                                id='CreateUserForm-FirstNameInput'
                            />
                        </div>
                        <div className='col-md-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.lastName'
                                        description={'Form label for last name'}
                                        defaultMessage='Last name'></FormattedMessage>
                                }
                                value={formResult.last_name}
                                onChange={(value) => changeFormResult({ ...formResult, last_name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.lastName'
                                        description={'Error message for last name'}
                                        defaultMessage='Please enter a valid last name'
                                    />
                                }
                                isValidCallback={(value) => {
                                    changeLNValid(value);
                                }}
                                submitted={submitted}
                                id='CreateUserForm-LastNameInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <PhoneInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Phone'
                                        description={'Form label for phone number'}
                                        defaultMessage='Phone number'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.phone}
                                onChange={(value) => changeFormResult({ ...formResult, phone: value.toString() })}
                                isValidCallback={(value) => {
                                    changePNValid(value);
                                }}
                                id='CreateUserForm-PhoneNumberInput'
                            />
                        </div>
                        <div className='col-md-6'>
                            <PhoneInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Mobile'
                                        description={'Form label for mobile number'}
                                        defaultMessage='Mobile number'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.mobile}
                                onChange={(value) => changeFormResult({ ...formResult, mobile: value.toString() })}
                                isValidCallback={(value) => {
                                    changeMBValid(value);
                                }}
                                id='CreateUserForm-MobileNumberInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Language'
                                        description={'Label for selecting language'}
                                        defaultMessage='Language'></FormattedMessage>
                                }
                                submitted={submitted}
                                options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                                value={formResult.language}
                                onChange={(res: any) => {
                                    changeFormResult({ ...formResult, language: res });
                                }}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.Language'
                                        description={'Error message for language'}
                                        defaultMessage='Please select a valid language'
                                    />
                                }
                                required
                                isValidCallback={(value) => {
                                    changeLangValid(value);
                                }}
                                id='CreateUserForm-LanguageSelect'
                            />
                        </div>
                        <div className='col-md-6'>
                            {isSuperAdmin && (
                                <>
                                    <AccountSelect
                                        label={
                                            <FormattedMessage
                                                id='CreateUserForm.Form.Label.Accounts'
                                                description={'Label for adding accounts in the CreateUseForm'}
                                                defaultMessage={'Accounts'}
                                            />
                                        }
                                        submitted={submitted}
                                        required
                                        errorMessage={
                                            <FormattedMessage
                                                id='CreateUserForm.Error.Accounts'
                                                description={'Error message for accounts'}
                                                defaultMessage='Please select a valid account'
                                            />
                                        }
                                        isMulti
                                        id='CreateUserForm-AccountsSelect'
                                        isValidCallback={(value) => changeACValid(value)}
                                        value={formResult.account_members}
                                        placeholder='Select account...'
                                        isClearable={true}
                                        onChange={(value: any) => changeFormResult({ ...formResult, account_members: generateAccountMembers(value) })}
                                        options={users.user!.account_members.map((acc) => ({ value: acc.account.id, label: acc.account.name }))}
                                    />
                                    {formResult.account_members.length > 0 && (
                                        <button
                                            type='button'
                                            className='btn bg-gradient-primary'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                changeRoleSelectOpen(true);
                                            }}>
                                            Change roles
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <CreateModalFooter
                    handleClose={() => handleCancel()}
                    onSubmit={() => undefined}
                    disabled={!formValid || userWasCreated}
                />
            </form>
            {changeRoleSelectOpen && (
                <CreateModal
                    handleClose={() => changeRoleSelectOpen(false)}
                    show={roleSelectOpen}
                    header='Change roles'
                    customWidth={100}>
                    <RoleSelectModal
                        handleClose={() => changeRoleSelectOpen(false)}
                        accounts={formResult.account_members}
                        onChange={(value) => changeFormResult({ ...formResult, account_members: value })}
                    />
                </CreateModal>
            )}
        </>
    );
};

export default CreateUserForm;

export interface UserForm {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: Locale | undefined;
    email: string;
    mobile: string;
    phone: string;
    password: string;
}

const initialState = {
    account_members: [],
    first_name: '',
    last_name: '',
    language: undefined,
    email: '',
    mobile: '',
    phone: '',
    password: ''
};

export interface AccountMember {
    account: number;
    role: string;
}

const userCreatedSuccess = (
    <FormattedMessage
        id='CreateUserForm.Created.User.Success'
        description={'Message when successfully creating a user'}
        defaultMessage='Your user was successfully created'></FormattedMessage>
);

const userFoundMessage = (
    <FormattedMessage
        id='CreateUserForm.Found.User.Success'
        description={'Message when finding a user that already exists'}
        defaultMessage='A user with this username already exists, please provide a different username'></FormattedMessage>
);

const userCreatedError = (
    <FormattedMessage
        id='CreateUserForm.Error.User.Create'
        description={'Error message when creating a user'}
        defaultMessage='An error occurred while creating this user'></FormattedMessage>
);

export function generateAccountMembers(numbersArray: number[], existingAccountMembers?: AccountMember[]): AccountMember[] {
    if (existingAccountMembers) {
        return numbersArray.map((number) => ({
            account: number,
            role: existingAccountMembers.find((ac) => ac.account === number) ? existingAccountMembers.find((ac) => ac.account === number)!.role : 'member'
        }));
    } else {
        return numbersArray.map((number) => ({
            account: number,
            role: 'member'
        }));
    }
}
