import { useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import { Category } from '../../../api/catalogue/Categories';
import { ClassType } from '../../../common/BootstrapValues';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CreateModal from '../../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../../components/modals/Modal';
import { useAppDispatch } from '../../../hooks/redux';
import { deleteCategory } from '../../../store/categorySlice';
import UpdateCategory from '../forms/UpdateCategory';
import CategoryDetail from './CategoryDetail';

interface Props extends ModalProps {
    category: Category;
}

export default function CategoryDetailModal(props: Props) {
    const dispatch = useAppDispatch();
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);

    const onDeleteCategory = () => {
        dispatch(deleteCategory(props.category.id));
        changeShowDeleteModal(false);
        props.handleClose();
    };

    return (
        <Modal
            type={ModalType.PANE}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.category.name}</h3>
            </div>
            <div className='modal-body'>
                <CategoryDetail category={props.category} />
            </div>
            <div className='modal-footer d-flex flex-row justify-content between align-items center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            text: (
                                <FormattedMessage
                                    id='CategoryDetailModal.Button.Update'
                                    description='Button for updating category'
                                    defaultMessage='Update'
                                />
                            ),
                            hide: false,
                            onClick: () => changeShowUpdateModal(true),
                            type: ClassType.WARNING,
                            icon: FiEdit2
                        },
                        {
                            text: (
                                <FormattedMessage
                                    id='CategoryDetailModal.Button.Delete'
                                    description='Button for deleting category'
                                    defaultMessage='Delete'
                                />
                            ),
                            hide: false,
                            onClick: () => changeShowDeleteModal(true),
                            type: ClassType.DANGER,
                            icon: IoMdTrash
                        }
                    ]}
                    direction='left'
                />
            </div>
            {showUpdateModal && (
                <CreateModal
                    handleClose={() => changeShowUpdateModal(false)}
                    show={showUpdateModal}
                    header='Update Category'>
                    <UpdateCategory
                        category={props.category}
                        handleClose={() => changeShowUpdateModal(false)}
                    />
                </CreateModal>
            )}
            {showDeleteModal && (
                <ConfirmationModal
                    show={showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => changeShowDeleteModal(false)}
                    onConfirm={onDeleteCategory}
                    message={
                        <FormattedMessage
                            id='CategoryDetailModa.ConfirmMessage'
                            description='The confirmation message checking if the user wants to delete this category'
                            defaultMessage='Are you sure you want to delete this category?'
                        />
                    }
                />
            )}
        </Modal>
    );
}
