import { Row } from 'react-table';

import { Invite } from '../../api/UserInvites';
import BooleanBadge from '../../components/badges/BooleanBadge';
import EmailBadge from '../../components/badges/EmailBadge';
import { RowProps } from '../../components/tables/rows/TRow';

interface Props extends RowProps {
    selectEnabled?: boolean;
    row: Row<Invite>;
    onClick: (element: Invite) => void;
}

const InviteListElement = (props: Props) => {
    return (
        <tr onClick={() => props.onClick(props.row.original)}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td>
                <span className='text-seconday font-weight-bold'>
                    <EmailBadge email={props.row.original.email} />
                </span>
            </td>
            <td>
                <span className='text-seconday font-weight-bold'>
                    <BooleanBadge value={true} />
                </span>
            </td>
        </tr>
    );
};

export default InviteListElement;
