import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../App';
import { DefaultRole } from './useAuthorizationConfig';

const useAuthorization = (permissions: DefaultRole, shoudlRedirect?: boolean): boolean => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const authorized = authContext.authCheck(permissions);

    useEffect(() => {
        if (shoudlRedirect === true) {
            if (!authorized) navigate('/unauthorized');
        }
    }, [window.location.href, permissions, shoudlRedirect]);
    return authorized;
};

export default useAuthorization;
