import { ApiViewSet, DetailOptions, apiDetail, apiList } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';

export interface SpotLayout {
    id: number;
    url: string;
    spot_url: string;
    spot: number | string;
    spot_layout_items: string;
    height: number;
    width: number;
    depth: number;
}

export enum SpotLayoutQueryParams {
    ACCOUNT = 'account'
}

const spotLayoutViewSet: ApiViewSet = {
    baseName: 'spot-layouts'
};

export async function fetchSpotLayoutsApi(queryParams?: ApiQueryParams<SpotLayoutQueryParams> | null): Promise<SpotLayout[]> {
    return apiList<SpotLayout, SpotLayoutQueryParams>(spotLayoutViewSet, queryParams);
}

export async function fetchSpotLayoutApi(options: DetailOptions): Promise<SpotLayout> {
    return apiDetail<SpotLayout>(spotLayoutViewSet, options);
}
