import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    Terminal,
    TerminalQueryParams,
    TerminalSettingsBody,
    TerminalsPostOptions,
    fetchTerminalsApi,
    setTerminalSettingsApi,
    switchTerminalApi
} from '../api/Terminals';
import { ApiError } from '../api/utils';
import { ReduxError, RootState } from './store';
import { SliceStatus } from './utils/Redux';

interface TerminalsState {
    terminalsList: Terminal[] | null;
    status: SliceStatus;
    message: string;
    lastUpdated: number;
    error: ReduxError | null;
}

const initialState: TerminalsState = {
    terminalsList: null,
    status: SliceStatus.INIT,
    message: '',
    lastUpdated: Date.now(),
    error: null
};

export const terminalSlice = createSlice({
    name: 'terminals',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTerminals.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTerminals.fulfilled, (state, action) => {
                state.terminalsList = action.payload;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTerminals.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(setTerminalSettings.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(setTerminalSettings.fulfilled, (state, action) => {
                const terminal = action.payload;
                if (state.terminalsList) {
                    const foundTerminal = state.terminalsList.find((t) => t.id === terminal.id);
                    if (foundTerminal) {
                        const index = state.terminalsList.indexOf(foundTerminal);
                        state.terminalsList.splice(index, 1, terminal);
                    }
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdated = Date.now();
            });
    }
});

export const fetchTerminals = createAsyncThunk<Terminal[], ApiQueryParams<TerminalQueryParams>>('fetchTerminals', async (options, { rejectWithValue }) => {
    try {
        return await fetchTerminalsApi(options);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const switchTerminal = createAsyncThunk<Terminal, TerminalsPostOptions>('switchTerminal', async (terminal, { rejectWithValue }) => {
    try {
        return await switchTerminalApi(terminal);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const setTerminalSettings = createAsyncThunk<Terminal, { body: TerminalSettingsBody; id: number }>(
    'setTerminalSettings',
    async (options, { rejectWithValue }) => {
        try {
            return await setTerminalSettingsApi(options.body, options.id);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const selectTerminals = (state: RootState) => state.terminals.terminalsList;

export default terminalSlice.reducer;
