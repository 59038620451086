import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import { Slot } from '../../../api/Slots';
import { SpotLayoutItem } from '../../../api/SpotLayoutItems';
import { SpotLayout } from '../../../api/SpotLayouts';
import { Spot } from '../../../api/Spots';
import { Product } from '../../../api/catalogue/Products';
import { ClassType } from '../../../common/BootstrapValues';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchProducts } from '../../../store/productSlice';
import { fetchSlots } from '../../../store/slotSlice';
import { fetchSpotLayoutItems, fetchSpotLayouts } from '../../../store/spotLayoutSlice';
import { fetchSpots } from '../../../store/spotSlice';
import { shouldFetch } from '../../../store/utils/Redux';
import SlotAssignmentLayout from './SlotAssignmentLayout';
import { UpdatedSlotsList } from './TransferListAssignedSlots/TransferListAssignedSlots';

interface SlotAssignmentProps {
    product?: Product;
    callback: (value: UpdatedSlotsList) => void;
    onSubmit: () => void;
}

const SlotAssignment = (props: SlotAssignmentProps) => {
    const dispatch = useAppDispatch();
    const spotState = useAppSelector((state) => state.spots);
    const spotLayoutState = useAppSelector((state) => state.spotLayouts);
    const slotState = useAppSelector((state) => state.slots);
    const productState = useAppSelector((state) => state.products);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [selectedSpot, changeSelectedSpot] = useState<Spot | undefined>();
    const [selectedSpotLayout, changeSelectedSpotLayout] = useState<SpotLayout | undefined>();
    const [selectedSpotLayoutItems, changeSelectedSpotLayoutItems] = useState<SpotLayoutItem[] | undefined>();
    const [selectedSlots, changeSelectedSlots] = useState<UpdatedSlotsList>();

    useEffect(() => {
        dispatch(fetchProducts({ account: accountId?.toString(), show_all: 'true', page: '1', page_size: '1000' }));
        if (shouldFetch(spotState.status, spotState.lastUpdate)) dispatch(fetchSpots({ account: accountId?.toString() }));
        if (shouldFetch(spotLayoutState.status, spotLayoutState.lastUpdate)) dispatch(fetchSpotLayouts({ account: accountId?.toString() }));
    }, []);

    useEffect(() => {
        if (selectedSpot !== undefined && spotLayoutState.spotLayoutList) {
            const spotLayout = spotLayoutState.spotLayoutList.find((sp) => sp.spot === +selectedSpot.id.replace('SPT', ''));
            if (shouldFetch(slotState.status, slotState.lastUpdate[selectedSpot.id]) || slotState.slotList[selectedSpot.id] === undefined)
                dispatch(fetchSlots({ account: accountId?.toString(), spot: selectedSpot.id, page: '1', page_size: '1000' }));
            if (spotLayout) {
                changeSelectedSpotLayout(spotLayout);
            }
        }
    }, [selectedSpot]);

    useEffect(() => {
        if (selectedSpotLayout) {
            if (
                shouldFetch(spotLayoutState.itemsStatus, spotLayoutState.itemsLastUpdate[selectedSpotLayout.id]) ||
                spotLayoutState.itemsLastUpdate[selectedSpotLayout.id] === undefined
            )
                dispatch(fetchSpotLayoutItems({ 'spot-layout': selectedSpotLayout.id.toString() }));
            if (selectedSpot && selectedSpotLayout && spotLayoutState.spotLayoutItemsMap[selectedSpotLayout.id]) {
                changeSelectedSpotLayoutItems(spotLayoutState.spotLayoutItemsMap[selectedSpotLayout.id]);
            }
        }
    }, [selectedSpotLayout, spotLayoutState]);

    useEffect(() => {
        if (selectedSlots && props.callback) props.callback(selectedSlots);
    }, [selectedSlots]);

    const isSelected = selectedSlots
        ? (item: Slot) => {
              if (selectedSpot && selectedSlots.slots && slotState.slotList[selectedSpot.id]) {
                  const foundSlot = selectedSlots.slots.find((s) => s === item.real_id);
                  if (foundSlot) return true;
              }
              return false;
          }
        : undefined;

    const isOccupied = (item: SpotLayoutItem) => {
        const allProductSlots = productState.productList?.results
            .filter((p) => p.id !== props.product?.id)
            .map((p) => p.slots)
            .flat();
        if (item.slot && allProductSlots?.includes(+item.slot.replace('SLT', ''))) {
            return true;
        } else return false;
    };

    return (
        <>
            <div className='modal-body'>
                {spotState.spotList !== null && selectedSpot === undefined && (
                    <div className='d-flex flex-row justify-content-between align-items-center ms-3 me-3 mt-3'>
                        {spotState.spotList.map((s) => (
                            <button
                                key={s.id}
                                className='btn bg-gradient-primary'
                                id={s.id}
                                onClick={() => changeSelectedSpot(s)}>
                                {s.name}
                            </button>
                        ))}
                    </div>
                )}
                {selectedSpotLayoutItems && selectedSpot && selectedSpotLayout && (
                    <div>
                        <SlotAssignmentLayout
                            spot={selectedSpot}
                            spotLayout={selectedSpotLayout}
                            spotLayoutItems={selectedSpotLayoutItems}
                            selected={isSelected}
                            occupied={isOccupied}
                            product={props.product}
                            selectedSlots={(value: Slot[]) => changeSelectedSlots({ slots: value.map((v) => v.real_id) })}
                        />
                    </div>
                )}
            </div>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            type: ClassType.DANGER,
                            hide: !selectedSpot,
                            text: (
                                <FormattedMessage
                                    id='SlotAssignment.Button.Clear'
                                    description='Button to clear spot on slot assignment component'
                                    defaultMessage='Clear'
                                />
                            ),
                            icon: IoMdTrash,
                            onClick: () => {
                                changeSelectedSpot(undefined);
                                changeSelectedSpotLayoutItems(undefined);
                            }
                        },
                        {
                            type: ClassType.PRIMARY,
                            hide: false,
                            disabled: !!(selectedSlots && selectedSlots.slots && selectedSlots.slots.length === 0),
                            id: 'SlotAssignment-SubmitButton',
                            text: (
                                <FormattedMessage
                                    id='CreateModal.Submit.Button'
                                    description={'Text shown in the submit button in an create modal'}
                                    defaultMessage='Submit'
                                />
                            ),
                            icon: FaPlus,
                            onClick: () => props.onSubmit && props.onSubmit()
                        }
                    ]}
                    direction='left'
                />
            </div>
        </>
    );
};

export default SlotAssignment;
