import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    AccountMembership,
    CreateUserModel,
    UserData,
    UserInfo,
    UserQueryParams,
    UsersOptions,
    addUserApi,
    deleteUserApi,
    fetchUserInfoApi,
    fetchUsersApi,
    updateUserApi
} from '../api/User';
import { ApiError } from '../api/utils';
import { TELLOPORT_SELECTED_ACCOUNT_COOKIE, removeCookie, setCookie } from '../utils/Cookies';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface UserState {
    user: UserInfo | null;
    usersList: UserData | null;
    userListStatus: SliceStatus;
    selectedMembership: AccountMembership | null;
    status: SliceStatus;
    lastUpdate: number;
    message: string;
    error: ReduxError | null;
}

const initialState: UserState = {
    user: null,
    usersList: null,
    userListStatus: SliceStatus.INIT,
    selectedMembership: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: '',
    error: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        selectAccountMembership(state, action: PayloadAction<AccountMembership>) {
            const existingMembership = state.user?.account_members.find((am) => am.account.id === action.payload.account.id);
            if (existingMembership === undefined) throw new Error('User is not subscribed to this account or account does not exist.');
            setCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE, existingMembership.account.id.toString(), 30);
            state.selectedMembership = existingMembership;
            state.lastUpdate = Date.now();
        },
        removeSelectedAccountMembership(state) {
            removeCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE);
            state.selectedMembership = null;
            state.lastUpdate = Date.now();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.user = action.payload;
                if (action.payload.account_members !== undefined && action.payload.account_members.length === 1) {
                    state.selectedMembership = action.payload.account_members[0];
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchUsers.pending, (state) => {
                state.userListStatus = SliceStatus.LOADING;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.usersList == null) {
                    state.usersList = {
                        count: action.payload.count,
                        next: action.payload.next,
                        previous: action.payload.previous,
                        results: new Array(action.payload.count)
                    };
                    state.usersList?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.usersList?.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.userListStatus = SliceStatus.IDLE;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.userListStatus = SliceStatus.ERROR;
            })
            .addCase(addUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                const user = action.payload;
                if (state.usersList == null) state.usersList = { count: 0, next: null, previous: null, results: [] };
                state.usersList.results.push(user);
                state.status = SliceStatus.IDLE;
            })
            .addCase(addUser.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(updateUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const user = action.payload;
                if (state.usersList == null) state.usersList = { count: 0, next: null, previous: null, results: [] };
                const existingUser = state.usersList.results.find((u) => u.id === user.id);
                if (existingUser) {
                    const userIndex = state.usersList.results.indexOf(existingUser);
                    state.usersList.results.splice(userIndex, 1, user);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateUser.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                if (action.payload === true && state.usersList) {
                    const userId = action.meta.arg;
                    state.usersList.results = state.usersList.results.filter((u) => u.id !== userId);
                }

                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchUserInfo = createAsyncThunk<UserInfo>('fetchUserInfo', async (_, { rejectWithValue }) => {
    try {
        return await fetchUserInfoApi();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchUsers = createAsyncThunk<UserData, ApiQueryParams<UserQueryParams>>(
    'fetchUsers',
    async (queryParams: ApiQueryParams<UserQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchUsersApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const addUser = createAsyncThunk<UserInfo, CreateUserModel>('addUser', async (user, { rejectWithValue }) => {
    try {
        return await addUserApi(user);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateUser = createAsyncThunk<UserInfo, UsersOptions>('updateUser', async (userID, { rejectWithValue }) => {
    try {
        return await updateUserApi(userID);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteUser = createAsyncThunk<boolean, number>('deleteUser', async (userId, { rejectWithValue }) => {
    try {
        const res = await deleteUserApi(userId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete user with id: ${userId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const { selectAccountMembership, removeSelectedAccountMembership } = userSlice.actions;
export default userSlice.reducer;
