import { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { IoMdTrash } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { UserInfo } from '../../api/User';
import { ClassType } from '../../common/BootstrapValues';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import HorizontalButtonGroup from '../../components/horizontal-button-group/HorizontalButtonGroup';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import CreateModal from '../../components/modals/CreateModal';
import Modal, { ModalProps, ModalType } from '../../components/modals/Modal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import { Logger } from '../../logging/Logger';
import { deleteUser } from '../../store/userSlice';
import { SliceStatus } from '../../store/utils/Redux';
import { ToastUtil } from '../../utils/toasts/Toasts';
import UpdateUser from '../forms/UpdateUser';
import UserDetail from './UserDetail';

interface Props extends ModalProps {
    user?: UserInfo;
}

export default function UserDetailModal(props: Props) {
    const dispatch = useAppDispatch();
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const userState = useAppSelector((state) => state.user);
    const [submitted, changeSubmitted] = useState<boolean>(false);

    const canUpdate = props.user !== undefined;
    const canDelete = props.user !== undefined;
    const { selectedUser } = useAccount();

    const onDeleteUser = () => {
        dispatch(deleteUser(props.user!.id));
        setIsDeleted(true);
        changeSubmitted(true);
        changeShowDeleteModal(false);
    };

    useEffect(() => {
        if (isDeleted && submitted) {
            if (userState.status === SliceStatus.IDLE) {
                toast.success(
                    ...ToastUtil.generateToastConfig(CommonMessage.STATUS.SUCCESS, `Successfully deleted user: ${props.user?.email}`, ClassType.SUCCESS)
                );
                Logger.log(`${selectedUser?.email} deleted user: ${props.user?.email}`, { user: selectedUser?.id });
                changeSubmitted(false);
                props.handleClose();
            } else if (userState.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, `Failed to delete user: ${props.user?.email}`, ClassType.DANGER));
                Logger.error(`${selectedUser?.email} failed to delete user: ${props.user?.email}`, { user: selectedUser?.id }, userState.error?.json);
                changeSubmitted(false);
                props.handleClose();
            }
        }
    }, [userState.usersList]);

    return (
        <Modal
            type={ModalType.PANE}
            {...props}>
            <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
                <h3 className='font-weight-bolder text-info text-gradient'>{props.user?.username}</h3>
                <button
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>

            <div className='modal-body'>
                <UserDetail user={props.user} />
            </div>

            <div className='modal-footer d-flex flex-row justify-content-between align-items-center flex-end'>
                <HorizontalButtonGroup
                    buttons={[
                        {
                            type: ClassType.WARNING,
                            onClick: () => changeShowUpdateModal(true),
                            icon: FiEdit2,
                            hide: false,
                            text: (
                                <FormattedMessage
                                    id='UserDetail.options.updateUser'
                                    description='Label for updating user'
                                    defaultMessage='Update User'
                                />
                            )
                        },
                        {
                            onClick: () => changeShowDeleteModal(true),
                            icon: IoMdTrash,
                            type: ClassType.DANGER,
                            hide: false,
                            id: 'UserDetailModal-DeleteUserButton',
                            text: (
                                <FormattedMessage
                                    id='UserDetail.options.delete'
                                    description='Label for deleting a user'
                                    defaultMessage='Delete'
                                />
                            )
                        }
                    ]}
                    direction='left'
                />
            </div>

            {canUpdate && (
                <CreateModal
                    show={showUpdateModal}
                    handleClose={() => changeShowUpdateModal(false)}
                    header='Update User'>
                    <UpdateUser
                        handleClose={() => changeShowUpdateModal(false)}
                        user={props.user!}
                    />
                </CreateModal>
            )}
            {canDelete && (
                <ConfirmationModal
                    show={showDeleteModal}
                    severity={ClassType.DANGER}
                    handleClose={() => changeShowDeleteModal(false)}
                    message={
                        <FormattedMessage
                            id='deleteUser.ConfirmMessage'
                            description='The confirmation message checking if the user is sure about removing a user'
                            defaultMessage='Are you sure you want to delete this user? This action cannot be undone.'
                        />
                    }
                    onConfirm={onDeleteUser}
                />
            )}
        </Modal>
    );
}
