interface NavbarDropdownProps {
    elements?: any[];
    title: string;
    icon: React.ReactElement;
    id?: string;
    expanded: boolean;
    onToggle: () => void;
}

const NavbarDropdown = (props: NavbarDropdownProps) => {
    const { id, icon, title, elements, expanded, onToggle } = props;

    return (
        <>
            <a
                style={{ cursor: 'pointer' }}
                className={expanded ? 'card shadow-lg' : ''}>
                <li
                    data-bs-toggle='collapse'
                    data-bs-target={`#${id}`}
                    aria-controls={id}
                    role='button'
                    className={`nav-item  ${expanded ? 'bg-secondary text-primary' : ''}`}
                    onClick={onToggle}>
                    <a className={`nav-link ${expanded ? 'text-white' : 'collapsed'}`}>
                        <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                            <i className='text-primary text-sm opacity-10'>{icon}</i>
                        </div>
                        <span className='nav-link-text m-2'>{title}</span>
                    </a>
                </li>
                <div className={expanded ? 'collapse show' : 'collapse'}>
                    <ul className='nav nav-sm flex-column'>{elements?.map((e) => e)}</ul>
                </div>
            </a>
        </>
    );
};

export default NavbarDropdown;
