import { FormattedMessage } from 'react-intl';

export const TRANSACTIONS = {
    COLUMNS: {
        RECEIVER: (
            <FormattedMessage
                id='TransactionList.Table.Title.Receiver'
                description='Table title for receiver'
                defaultMessage='Receiver'
            />
        )
    }
};
