import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    Spot,
    SpotActionSettings,
    SpotSettingsBody,
    SpotsQueryParams,
    changeSpotSettingsApi,
    fetchSpotApi,
    fetchSpotsApi,
    switchSpotLightsApi
} from '../api/Spots';
import { ApiError } from '../api/utils';
import { ReduxError, RootState } from './store';
import { SliceStatus } from './utils/Redux';

interface SpotsState {
    spotList: Spot[] | null;
    status: SliceStatus;
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: SpotsState = {
    spotList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    error: null
};

export const spotSlice = createSlice({
    name: 'spots',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpots.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpots.fulfilled, (state, action) => {
                state.spotList = action.payload;
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSpots.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchSpotById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotById.fulfilled, (state, action) => {
                const spot = action.payload;
                if (state.spotList !== null) {
                    state.spotList = state.spotList.filter((s) => spot.id == s.id);
                    state.spotList.push(spot);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSpotById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchSpotByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotByUrl.fulfilled, (state, action) => {
                const spot = action.payload;
                if (state.spotList !== null) {
                    state.spotList = state.spotList.filter((s) => spot.id == s.id);
                    state.spotList.push(spot);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSpotByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(changeSpotSettings.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(changeSpotSettings.fulfilled, (state, action) => {
                const spot = action.payload;
                if (state.spotList) {
                    const foundSpot = state.spotList.find((s) => s.id === action.meta.arg.id.toString());
                    if (foundSpot) {
                        const index = state.spotList.indexOf(foundSpot);
                        state.spotList.splice(index, 1, spot);
                    }
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(changeSpotSettings.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchSpots = createAsyncThunk<Spot[], ApiQueryParams<SpotsQueryParams>>(
    'fetchSpots',
    async (queryParams: ApiQueryParams<SpotsQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchSpotsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotById = createAsyncThunk<Spot, string>('fetchSpotById', async (spotId, { rejectWithValue }) => {
    try {
        return await fetchSpotApi({ id: spotId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchSpotByUrl = createAsyncThunk<Spot, string>('fetchSpotByUrl', async (spotUrl, { rejectWithValue }) => {
    try {
        return await fetchSpotApi({ url: spotUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const switchSpotLights = createAsyncThunk<Spot, SpotActionSettings>('switchSpotLights', async (spot, { rejectWithValue }) => {
    try {
        return await switchSpotLightsApi(spot);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const changeSpotSettings = createAsyncThunk<Spot, { id: number; body: SpotSettingsBody }>('changeSpotSettings', async (options, { rejectWithValue }) => {
    try {
        return await changeSpotSettingsApi(options.id, options.body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});
//export const {} = spotSlice.actions;

export const selectSpots = (state: RootState) => state.spots.spotList;

export default spotSlice.reducer;
