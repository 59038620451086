import { ApiViewSet, apiList } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';
import { ReportInfo } from './Reports';

export interface ReportCategory {
    id: number;
    name: string;
    order_num: number;
    created_at: Date;
    udpated_at: Date | null;
    created_by: number;
    updated_by: number | null;
    reports: ReportInfo[];
}

export enum ReportCategoriesQueryParams {
    ACCOUNT = 'account'
}

const reportCategoriesViewSet: ApiViewSet = {
    baseName: 'report-categories'
};

export async function fetchReportCategoriesApi(queryParams?: ApiQueryParams<ReportCategoriesQueryParams>): Promise<ReportCategory[]> {
    return apiList<ReportCategory, ReportCategoriesQueryParams>(reportCategoriesViewSet, queryParams);
}
