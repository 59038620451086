import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import _, { toNumber } from 'lodash';

import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    BulkTransactionStatusUpdateModel,
    CancelTransactionQueryParams,
    CreateTransactionModel,
    MoveTransactionOptions,
    Transaction,
    TransactionResponse,
    TransactionsQueryParams,
    UpdateBulkTransactionReceiverOptions,
    addTransactionApi,
    bulkUpdateTransactionReceiverApi,
    bulkUpdateTransactionStatusApi,
    cancelTransactionApi,
    fetchTransactionApi,
    fetchTransactionsApi,
    moveTransactionApi
} from '../api/Transactions';
import { ApiError } from '../api/utils';
import { TransactionStatus, TransactionStatusName } from '../common/TransactionStatus';
import TransactionStatusChangeEvent from '../common/TransactionStatusChangeEvent';
import { FEATURES } from '../services/feature-flags/Features';
import { getAllValuesFromStore } from '../utils/KeyValueStores';
import { ReduxError, RootState } from './store';
import { SliceStatus } from './utils/Redux';

interface TransactionState {
    transactionList: { [transactionStatus: string]: TransactionResponse };
    userTransactions: { [transactionContact: string]: TransactionResponse };
    searchTransactionList: { [tt_number: string]: TransactionResponse } | null;
    lastUpdate: { [transactionStatus: string]: number };
    pendingTransactions: { transaction: Transaction; status: TransactionStatusName }[];
    status: SliceStatus;
    message: string;
    error: ReduxError | null;
}

const initialState: TransactionState = {
    transactionList: {},
    userTransactions: {},
    searchTransactionList: null,
    pendingTransactions: [],
    status: SliceStatus.INIT,
    lastUpdate: {},
    message: '',
    error: null
};

export const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        removeTransactionById(state, action: PayloadAction<string>) {
            state.transactionList = removeTransactionIfFound(action.payload, state.transactionList);
            state.userTransactions = removeTransactionIfFound(action.payload, state.userTransactions);
            if (state.searchTransactionList) state.searchTransactionList = removeTransactionIfFound(action.payload, state.searchTransactionList);
            if (FEATURES.pubSub === false) state.pendingTransactions = state.pendingTransactions.filter((t) => t.transaction.id != action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                const status = action.meta.arg['status'] || action.meta.arg['transaction_status'];
                const type = action.meta.arg.transaction_type;
                const sender = action.meta.arg.sender;
                const receiver = action.meta.arg.receiver;
                const tt_number = action.meta.arg.tracking_number;
                const fetchedTransactions = action.payload.results;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (type) {
                    state.status = SliceStatus.IDLE;
                    return;
                }

                if (sender || receiver) {
                    const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                    if (receiver) {
                        if (Array.isArray(receiver)) {
                            return undefined;
                        } else {
                            if (!state.userTransactions[receiver])
                                state.userTransactions[receiver] = { ...action.payload, results: new Array(action.payload.count) };
                            state.userTransactions[receiver].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    }
                    if (sender) {
                        if (Array.isArray(sender)) {
                            return undefined;
                        } else {
                            if (!state.userTransactions[sender])
                                state.userTransactions[sender] = { ...action.payload, results: new Array(action.payload.count) };
                            state.userTransactions[sender].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    }
                    state.status = SliceStatus.IDLE;
                    return;
                }

                if (action.payload.results === undefined) return;

                if (tt_number) {
                    if (tt_number === undefined || tt_number === null || Array.isArray(tt_number)) {
                        return undefined;
                    } else {
                        if (state.searchTransactionList === null) {
                            state.searchTransactionList = {};
                            state.searchTransactionList[tt_number] = { ...action.payload, results: new Array(action.payload.count) };
                            state.searchTransactionList[tt_number].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                        if (state.searchTransactionList[tt_number]) {
                            state.searchTransactionList[tt_number] = {
                                ...action.payload,
                                results: state.searchTransactionList[tt_number].results
                            };
                            state.searchTransactionList[tt_number].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        } else if (state.searchTransactionList[tt_number] === undefined) {
                            state.searchTransactionList[tt_number] = {
                                ...action.payload,
                                results: new Array(action.payload.count)
                            };
                            state.searchTransactionList[tt_number].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        }
                    }
                    state.status = SliceStatus.IDLE;
                    return;
                }
                if (status === undefined || status === null || Array.isArray(status)) {
                    const transactions: { [transactionStatus: string]: TransactionResponse } = {};
                    const lastUpdateList: { [transactionStatus: string]: number } = {};
                    fetchedTransactions.forEach((transaction, index) => {
                        if (transactions[transaction.status] === undefined)
                            transactions[transaction.status.toString()] = { ...action.payload, results: new Array(action.payload.count) };
                        transactions[transaction.status].results.splice(index, 1, transaction);
                        lastUpdateList[transaction.status] = Date.now();
                    });
                    if (Array.isArray(status)) {
                        const joinedStatus = status.join('-');
                        const statusArray = Object.keys(state.transactionList).filter((s) => s.includes('-'));
                        if (state.transactionList[joinedStatus] === undefined && (statusArray.length === 0 || status.length === 1)) {
                            // Case 1: Add to a new array if the status does not exist and there are no existing arrays
                            state.transactionList[joinedStatus] = {
                                ...action.payload,
                                results: new Array(action.payload.count)
                            };
                            state.transactionList[joinedStatus].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        } else {
                            let foundMatchingStatus = false;
                            for (const s of statusArray) {
                                const storeStatus = s.split('-');
                                const statusLength = joinedStatus.split('-').length;
                                const storeStatusLength = storeStatus.length;

                                if (state.transactionList[joinedStatus] === undefined && !status.every((st) => storeStatus.includes(st))) {
                                    // Case 2: Add to a new array if the status does not exist and the status is not a subset of any existing array
                                    state.transactionList[joinedStatus] = {
                                        ...action.payload,
                                        results: new Array(action.payload.count)
                                    };
                                    state.transactionList[joinedStatus].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                                    foundMatchingStatus = true;
                                    break;
                                } else if (statusLength === storeStatusLength && status.every((st) => storeStatus.includes(st))) {
                                    // Case 3: Add to an existing array with matching status and update using the newState
                                    const newState = _.cloneDeep(state.transactionList[s]);
                                    const temp = new Array(newState.results.length);

                                    newState.results.forEach((value, index) => {
                                        if (value !== undefined) {
                                            temp.splice(index, 1, value);
                                        }
                                    });

                                    newState.results = temp;
                                    state.transactionList[s] = {
                                        ...newState,
                                        count: action.payload.count,
                                        next: action.payload.next,
                                        previous: action.payload.previous
                                    };
                                    state.transactionList[s].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                                    state.lastUpdate[s] = Date.now();

                                    foundMatchingStatus = true;
                                    break;
                                } else if (statusLength > storeStatusLength && status.every((st) => storeStatus.includes(st))) {
                                    // Case 4: Append to an existing array if the status is a subset of an existing array
                                    state.transactionList[s].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                                    state.lastUpdate[s] = Date.now();

                                    foundMatchingStatus = true;
                                    break;
                                }
                            }

                            if (!foundMatchingStatus) {
                                // Case 5: Create a new array if the status is not a subset of any existing array
                                state.transactionList[joinedStatus] = {
                                    ...action.payload,
                                    results: new Array(action.payload.count)
                                };
                                state.transactionList[joinedStatus].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                                state.lastUpdate[joinedStatus] = Date.now();
                            }
                        }
                    } else {
                        state.transactionList = transactions;
                        state.lastUpdate = lastUpdateList;
                    }
                } else {
                    if (state.transactionList[status] === undefined && !type) {
                        state.transactionList[status] = { ...action.payload, results: new Array(action.payload.count) };
                    }
                    state.transactionList[status].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    state.lastUpdate[status] = Date.now();
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })

            .addCase(fetchTransactionById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionById.fulfilled, (state, action) => {
                if (FEATURES.pubSub === false) {
                    const pending = state.pendingTransactions.find((tr) => tr.transaction.id === action.payload.id);
                    if (
                        pending &&
                        pending.status !== TransactionStatusName.DROPOFF_IN_PROGRESS &&
                        pending.status !== TransactionStatusName.PICKUP_IN_PROGRESS
                    ) {
                        state.pendingTransactions.splice(state.pendingTransactions.indexOf(pending), 1);
                    }
                }
                if (action.payload !== undefined) {
                    const newState = propagateTransactionUpdate(action.payload, state);
                    state.transactionList = newState.trList;
                    state.userTransactions = newState.userTrList;
                    state.searchTransactionList = newState.searchTrList;
                } else console.log('Oops, something went wrong');
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTransactionById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(fetchTransactionByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionByUrl.fulfilled, (state, action) => {
                const newState = propagateTransactionUpdate(action.payload, state);
                state.transactionList = newState.trList;
                state.userTransactions = newState.userTrList;
                state.searchTransactionList = newState.searchTrList;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTransactionByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })

            .addCase(addTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addTransaction.fulfilled, (state, action) => {
                const transaction = action.payload;
                const transactionStatus = TransactionStatus.getByString(transaction.status)!.name;
                if (state.transactionList[transactionStatus] !== undefined) {
                    state.transactionList[transactionStatus].results.push(transaction);
                    if (FEATURES.pubSub === false)
                        state.pendingTransactions = [{ transaction: transaction, status: TransactionStatus.getByString(transaction.status)!.name }];
                } else {
                    state.transactionList[transactionStatus] = { count: 1, next: null, previous: null, results: [transaction] };
                    if (FEATURES.pubSub === false)
                        state.pendingTransactions = [{ transaction: transaction, status: TransactionStatus.getByString(transaction.status)!.name }];
                }
                state.status = SliceStatus.IDLE;
                state.message = '';
            })
            .addCase(addTransaction.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })

            .addCase(cancelTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(cancelTransaction.fulfilled, (state, action) => {
                const newState = propagateTransactionUpdate(action.payload, state);
                state.transactionList = newState.trList;
                state.userTransactions = newState.userTrList;
                state.searchTransactionList = newState.searchTrList;
                state.status = SliceStatus.IDLE;
            })
            .addCase(cancelTransaction.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(bulkUpdateTransactionStatus.fulfilled, (state, action) => {
                const body = action.meta.arg;
                const event = TransactionStatusChangeEvent.getByValue(body.event);
                const initState = TransactionStatus.getByName(body.initial_state);
                if (event === undefined || initState === undefined) {
                    console.error('Event or initial status was unknow therefore automatic updates of the transactions will not be done.');
                    return;
                }
                const expectedEndStatus = TransactionStatusChangeEvent.getExpectedEndStatusForEvent(event, initState);
                if (expectedEndStatus === undefined) {
                    console.error('Unable to calculate expected status therefore automatic updates of the transactions will not be done.');
                    return;
                }
                if (FEATURES.pubSub === false) {
                    const transactions = getAllValuesFromStore(state.transactionList).filter((t) => body.transaction_ids.includes(t.real_id));
                    const newPeningTransactions = _.clone(state.pendingTransactions);
                    transactions.forEach((t) => newPeningTransactions.push({ transaction: t, status: expectedEndStatus.name }));
                    state.pendingTransactions = newPeningTransactions;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(bulkUpdateTransactionStatus.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(moveTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(moveTransaction.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
            })
            .addCase(moveTransaction.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(bulkUpdateTransactionReceiver.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(bulkUpdateTransactionReceiver.fulfilled, (state, action) => {
                if (FEATURES.pubSub === false) {
                    const body = action.meta.arg;
                    const transactions = getAllValuesFromStore(state.transactionList).filter((t) => body.transaction_ids.includes(t.real_id));
                    const newPendingTransactions = _.clone(state.pendingTransactions);
                    transactions.forEach((t) => {
                        const status = TransactionStatus.getByString(t.status);
                        if (status) {
                            newPendingTransactions.push({ transaction: t, status: status.name });
                        }
                    });
                    state.pendingTransactions = newPendingTransactions;
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(bulkUpdateTransactionReceiver.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

/**
 * Always try to provide a transaction status param.
 * If no status is provided the entire list will be overwritten with the backend response.
 * This of course is not ideal.
 *
 * You can provide one status (recomended)
 * or multiple (this may take a bit longer)
 */
export const fetchTransactions = createAsyncThunk<TransactionResponse, ApiQueryParams<TransactionsQueryParams>>(
    'fetchTransactions',
    async (queryParams: ApiQueryParams<TransactionsQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchTransactionsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchTransactionById = createAsyncThunk<Transaction, string>('fetchTransactionById', async (transactionId, { rejectWithValue }) => {
    try {
        return await fetchTransactionApi({ id: transactionId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchTransactionByUrl = createAsyncThunk<Transaction, string>('fetchTransactionByUrl', async (transactionUrl, { rejectWithValue }) => {
    try {
        return await fetchTransactionApi({ url: transactionUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addTransaction = createAsyncThunk<Transaction, CreateTransactionModel>('addTransaction', async (transaction, { rejectWithValue }) => {
    try {
        return await addTransactionApi(transaction);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const cancelTransaction = createAsyncThunk<Transaction, CancelTransactionQueryParams>(
    'cancelTransaction',
    async (transactionId, { rejectWithValue }) => {
        try {
            return await cancelTransactionApi(transactionId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const moveTransaction = createAsyncThunk<Transaction, MoveTransactionOptions>(
    'moveTransaction',
    async (options: MoveTransactionOptions, { rejectWithValue }) => {
        try {
            return await moveTransactionApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const bulkUpdateTransactionStatus = createAsyncThunk<void, BulkTransactionStatusUpdateModel>(
    'bulkUpdateTransactionStatus',
    async (body: BulkTransactionStatusUpdateModel, { rejectWithValue }) => {
        try {
            return await bulkUpdateTransactionStatusApi(body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const bulkUpdateTransactionReceiver = createAsyncThunk<void, UpdateBulkTransactionReceiverOptions>(
    'bulkUpdateTransactionReceiver',
    async (body: UpdateBulkTransactionReceiverOptions, { rejectWithValue }) => {
        try {
            return await bulkUpdateTransactionReceiverApi(body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const selectTransactions = (state: RootState) => state.transactions.transactionList;
export const getTransactionById = (state: TransactionState, id: string): Transaction | null => {
    for (const status in state.transactionList) {
        const transactionResponse = state.transactionList[status];
        const transaction = findTransactionInResponse(id, transactionResponse);
        if (transaction) {
            return transaction;
        }
    }

    // Search through userTransactions
    for (const contact in state.userTransactions) {
        const transactionResponse = state.userTransactions[contact];
        const transaction = findTransactionInResponse(id, transactionResponse);
        if (transaction) {
            return transaction;
        }
    }

    // Search through searchTransactionList if it exists
    for (const tt_number in state.searchTransactionList) {
        const transactionResponse = state.searchTransactionList[tt_number];
        const transaction = findTransactionInResponse(id, transactionResponse);
        if (transaction) {
            return transaction;
        }
    }

    return null; // Transaction with the given ID not found
};

export const { removeTransactionById } = transactionSlice.actions;
export default transactionSlice.reducer;

function findTransactionInResponse(id: string, response: TransactionResponse): Transaction | null {
    for (const transaction of response.results) {
        if (transaction && transaction.id === id) {
            return transaction;
        }
    }
    return null; // Transaction with the given ID not found in this response
}

function propagateTransactionUpdate(newTransaction: Transaction, state: WritableDraft<TransactionState>) {
    const trList = updateTransactionInList(newTransaction, state.transactionList);
    const userTrList = updateTransactionInList(newTransaction, state.userTransactions);
    let searchTrList = null;
    if (state.searchTransactionList) searchTrList = updateTransactionInList(newTransaction, state.searchTransactionList);

    return {
        trList,
        userTrList,
        searchTrList
    };
}
function updateTransactionInList(newTransaction: Transaction, list: { [key: string]: TransactionResponse }) {
    const newState: { [key: string]: TransactionResponse } = _.cloneDeep(list);

    Object.keys(newState).forEach((key) => {
        newState[key].results = new Array(list[key].count);
        list[key].results.forEach((v, i) => {
            if (v != undefined) newState[key].results[i] = v;
        });
    });

    Object.entries(newState).forEach(([key, value]) => {
        const foundtransaction = value.results.find((tr) => tr?.id == newTransaction.id);
        if (foundtransaction && foundtransaction.status !== newTransaction.status) {
            if (!key.includes(TransactionStatus.getByString(newTransaction.status)!.name)) {
                newState[key].results.splice(value.results.indexOf(foundtransaction), 1);
            }
            if (newState[TransactionStatus.getByString(newTransaction.status)!.name]) {
                newState[TransactionStatus.getByString(newTransaction.status)!.name].results.push(newTransaction);
            } else {
                newState[TransactionStatus.getByString(newTransaction.status)!.name] = { count: 1, next: null, previous: null, results: [newTransaction] };
            }
        }
        if (foundtransaction) newState[key].results[value.results.indexOf(foundtransaction)] = newTransaction;
        if (!foundtransaction && key.includes(TransactionStatus.getByString(newTransaction.status)!.name)) {
            newState[key].results.push(newTransaction);
        }
    });
    return newState;
}

function removeTransactionIfFound(transactionId: string, list: { [key: string]: TransactionResponse }): { [key: string]: TransactionResponse } {
    const newState: { [key: string]: TransactionResponse } = _.cloneDeep(list);

    Object.keys(newState).forEach((key) => {
        newState[key].results = new Array(list[key].count);
        list[key].results.forEach((v, i) => {
            if (v != undefined) newState[key].results[i] = v;
        });
    });

    Object.entries(newState).forEach(([key, value]) => {
        const foundtransaction = value.results.find((tr) => tr?.id == transactionId);
        if (foundtransaction) {
            newState[key].results.splice(value.results.indexOf(foundtransaction), 1);
        }
    });

    return newState;
}
