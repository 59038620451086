import { PubSubMessage } from './usePubSubEventListener';

enum PubSubActions {
    SUBSCRIBE = 'subscribe',
    UNSUBSCRIBE = 'unsubscribe'
}

enum FilterPolicyScope {
    ATTRIBUTES = 'MessageAttributes',
    BODY = 'MessageBody'
}

/**
 * SNS filtering documentation:
 * https://docs.aws.amazon.com/sns/latest/dg/sns-message-filtering.html
 */
export interface FilterPolicy {
    [key: string]: FilterPolicy | (string | number | undefined | Matching)[];
}

interface Matching {
    numeric?: string | number[];
    'anything-but'?: number[] | string[] | Matching;
    prefix?: string;
    suffix?: string;
    cidr?: string;
    'equals-ignore-case'?: string;
}

export enum PubSubTopic {
    TRANSACTIONS = 'transactions',
    CONTACTS = 'contacts'
}

export default class PubSubSubscription {
    subscribed = false;
    name: string; //should be handled as the ID field
    topic: PubSubTopic;
    filter_policy_scope = FilterPolicyScope.BODY;
    filter_policy: FilterPolicy = {};

    constructor(topic: PubSubTopic, name: string = crypto.randomUUID(), filter_policy?: FilterPolicy, filter_policy_scope?: FilterPolicyScope) {
        this.topic = topic;
        this.name = name;
        if (filter_policy != undefined) {
            if (getTotalCombinations(filter_policy) > 150) throw new Error('Filter policy to large. Try to reduce the amount of nested keys and/or values.');
            else this.filter_policy = filter_policy;
        }
        if (filter_policy_scope != undefined) this.filter_policy_scope = filter_policy_scope;
    }

    subscribe = (send: (message: string) => boolean): void => {
        send(this.getSubscribeBody());
        const listener = (event: CustomEvent<PubSubMessage>) => {
            if (event.detail.content.name == this.name) {
                this.subscribed = true;
                removeEventListener('PubSub:subscribe', listener);
            }
        };
        addEventListener('PubSub:subscribe', listener);
    };

    unsubscribe = (send: (message: any) => void): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!this.subscribed) resolve();
            else {
                send(this.getUnsubscribeBody());
                const listener = (event: CustomEvent<PubSubMessage>) => {
                    if (event.detail.content.name == this.name) {
                        this.subscribed = false;
                        resolve();
                        removeEventListener('PubSub:unsubscribe', listener);
                    }
                };
                addEventListener('PubSub:unsubscribe', listener);
            }
        });
    };

    getSubscribeBody = (): string => {
        return JSON.stringify({
            action: PubSubActions.SUBSCRIBE,
            name: this.name,
            topic: this.topic,
            filter_policy_scope: this.filter_policy_scope,
            filter_policy: this.filter_policy
        });
    };
    getUnsubscribeBody = (): string => {
        return JSON.stringify({
            action: PubSubActions.UNSUBSCRIBE,
            name: this.name
        });
    };
}

function getTotalCombinations(obj: FilterPolicy): number {
    let total = 1;

    function calculateCombinations(obj: FilterPolicy, dept: number): void {
        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                total *= (obj[key] as []).length * dept;
            } else {
                calculateCombinations(obj[key] as FilterPolicy, dept + 1);
            }
        }
    }
    calculateCombinations(obj, 1);
    return total;
}
