import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiQueryParams } from '../api/BaseQueryParams';
import { ReportCategoriesQueryParams, ReportCategory, fetchReportCategoriesApi } from '../api/reports/ReportCategories';
import { ApiError } from '../api/utils';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface ReportCategoriesState {
    reportCategoriesList: ReportCategory[] | null;
    status: SliceStatus;
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: ReportCategoriesState = {
    reportCategoriesList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    error: null
};

export const reportCategorySlice = createSlice({
    name: 'report-categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReportCategories.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchReportCategories.fulfilled, (state, action) => {
                state.reportCategoriesList = action.payload.sort((a, b) => a.order_num - b.order_num);
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchReportCategories.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchReportCategories = createAsyncThunk<ReportCategory[], ApiQueryParams<ReportCategoriesQueryParams>>(
    'fetchReportCategories',
    async (queryParams: ApiQueryParams<ReportCategoriesQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchReportCategoriesApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default reportCategorySlice.reducer;
