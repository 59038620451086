import { FaPlus } from 'react-icons/fa';
import { CommonMessage } from '../../common/CommonFormattedMessages/CommonMessage';
import { CreateModalFooterProps } from './CreateModalFooter';

export interface UpdateModalFooterProps extends CreateModalFooterProps {
    onDelete?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disableDelete?: boolean;
}

const UpdateModalFooter = (props: UpdateModalFooterProps) => {
    return (
        <>
            <div className='modal-footer d-flex flex-row justify-content-between align-items-center'>
                <button
                    type='button'
                    onClick={props.handleClose}
                    id='CreateModal-CancelButton'
                    className='btn bg-gradient-light'>
                    {CommonMessage.BUTTON.CANCEL}
                </button>
                <div>
                    {props.onDelete && (
                        <button
                            type='button'
                            disabled={props.disableDelete}
                            onClick={props.onDelete}
                            id='CreateModal-DeleteButton'
                            className='btn bg-gradient-danger me-2'>
                            {CommonMessage.BUTTON.DELETE}
                        </button>
                    )}
                    {props.onSubmit ? (
                        <button
                            type='submit'
                            disabled={props.disabled}
                            className='btn bg-gradient-primary me-1'
                            id='CreateModal-SubmitButton'
                            onClick={(e) => props.onSubmit(e)}>
                            <div className='d-flex flex-row align-items-center'>
                                <FaPlus />
                                <span className='ms-2'>
                                    {CommonMessage.BUTTON.SUBMIT}
                                </span>
                            </div>
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default UpdateModalFooter;
