import React, { Suspense, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import Auth from './authorization/Auth';
import ShopOverview from './catalogue/ShopOverview';
import Spinner from './components/loading/Spinner';
import MainContextProvider from './contexts/MainContext';
import useAuthorizationConfig, { DefaultRole } from './hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import useAccount from './hooks/useAccount';
import useLocale from './hooks/useLocale';
import useScripts from './hooks/useScripts';
import useSendLogsBeforeUnload from './hooks/useSendLogsBeforeUnload';
import CloseCurrentPage from './navigation/CloseCurrentPage';
import Unauthorized from './navigation/Unauthorized';
import Footer from './navigation/footer/Footer';
import Header from './navigation/header/Header';
import Navbar from './navigation/navbar/Navbar';
import ResetPassword from './navigation/password/reset-password/resetPassword';
import usePubSubEventListener from './pub-sub/usePubSubEventListener';
import { FeatureFlagsProvider } from './services/feature-flags/FeatureFlags';
import { FEATURES } from './services/feature-flags/Features';
import { fetchUserInfo } from './store/userSlice';
import UserList from './users/user-list/UserList';
import { AuthenticationManager } from './utils/AuthenticationManager';
import { BackendStatusUpdate } from './utils/BackendStatusUpdate';

const LoginModule = React.lazy(() => import('./navigation/authentication/Login'));
const Logout = React.lazy(() => import('./navigation/authentication/Logout'));
const RegisterUserInvitation = React.lazy(() => import('./users/invitations/RegisterUserInvitation'));
const SelectAccountMembership = React.lazy(() => import('./navigation/authentication/SelectAccountMembership'));
const TransactionList = React.lazy(() => import('./transactions/transaction-list/TransactionList'));
const ScheduleTransactionPickupForm = React.lazy(() => import('./transactions/forms/schedule-transaction-pickup-form/ScheduleTransactionPickupForm'));
const ContactList = React.lazy(() => import('./contacts/contact-list/ContactList'));
const ContactGroupList = React.lazy(() => import('./contact-groups/contact-group-list/ContactGroupList'));
const PrinterOverview = React.lazy(() => import('./iot/printing/printer-list/PrinterList'));
const SpotLayoutView = React.lazy(() => import('./spotLayout/layout/SpotLayoutView'));
const PageNotFound = React.lazy(() => import('./navigation/PageNotFound'));
const UserInfoPage = React.lazy(() => import('./settings/user-information/UserInfoPage'));
const ChangePassword = React.lazy(() => import('./navigation/password/change-password/changePassword'));
const RequestPasswordReset = React.lazy(() => import('./navigation/password/reset-password/RequestPasswordReset'));
const SlotList = React.lazy(() => import('./slots/slot-list/SlotList'));
const SpotNavigation = React.lazy(() => import('./spots/spot-navigation/SpotNavigation'));
const Shops = React.lazy(() => import('./catalogue/Shops'));
const ReportsOverview = React.lazy(() => import('./reports/ReportsOverview'));
const ReportDetail = React.lazy(() => import('./reports/report-detail/ReportDetail'));
const Changelog = React.lazy(() => import('./changelog/Changelog'));
const Dashboard = React.lazy(() => import('./dashboard/Dashboard'));
const QRView = React.lazy(() => import('./qrview/QRView'));
const Support = React.lazy(() => import('./navigation/support/Support'));
const Logs = React.lazy(() => import('./logs/logs-list/LogList'));
const Iot = React.lazy(() => import('./iot/IotView'));
const AccessControllerView = React.lazy(() => import('./iot/accessControllers/accessControllers-list/AccessControllersList'));
const LostItemsView = React.lazy(() => import('./dashboard/Views/LostItemsView'));
const StockFillView = React.lazy(() => import('./dashboard/Views/stockfill/StockFillView'));
const StockFillDetail = React.lazy(() => import('./dashboard/Views/stockfill/StockFillDetail'));

interface AuthContextInterface {
    auth: any;
    authCheck: (requiredPermissions: any) => boolean;
}

export const AuthContext = React.createContext<AuthContextInterface>({
    auth: {},
    authCheck: (requiredPermissions: any) => false
});

function App() {
    const [navbarPinned, changeNavbarPinned] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const { messages, locale, changeLocale } = useLocale();
    const { accountId } = useAccount();
    const [realAccountId, setRealAccountId] = useState<number>();

    const authConfig = useAuthorizationConfig();

    useSendLogsBeforeUnload(realAccountId);
    usePubSubEventListener();

    useEffect(() => {
        if (AuthenticationManager.getInstance().hasValidToken()) dispatch(fetchUserInfo());
    }, [AuthenticationManager.getInstance().loggedIn]);

    useEffect(() => {
        if (accountId !== undefined) {
            setRealAccountId(accountId);
        }
    }, [accountId]);

    useEffect(() => {
        if (accountId) {
            const intervalId = setInterval(() => {
                BackendStatusUpdate(accountId);
            }, 5 * 60 * 1000);

            return () => {
                clearInterval(intervalId);
            };
        } else if (accountId === undefined) {
            if (realAccountId) {
                BackendStatusUpdate(realAccountId);
            }
        }
    }, [accountId, realAccountId]);

    return (
        <IntlProvider
            messages={messages}
            locale='en'
            defaultLocale={locale}>
            <BrowserRouter>
                <MainContextProvider>
                    <AuthContext.Provider value={authConfig}>
                        <FeatureFlagsProvider>
                            <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned ? 'pinned' : 'hidden'}`}>
                                <div className='min-height-300 bg-primary position-absolute w-100'></div>
                                {AuthenticationManager.getInstance().loggedIn && user.selectedMembership !== null ? <Navbar /> : <></>}

                                <main className='main-content position-relative border-radius-lg'>
                                    <Header
                                        changeLocale={changeLocale}
                                        navbarPinned={() => changeNavbarPinned(!navbarPinned)}
                                    />
                                    <div className='container-fluid ps-4'>
                                        <Suspense fallback={<Spinner show={true} />}>
                                            {AuthenticationManager.getInstance().loggedIn ? (
                                                <>
                                                    {user.selectedMembership === null ? (
                                                        <SelectAccountMembership />
                                                    ) : (
                                                        <Routes>
                                                            <Route path='/transactions'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <Auth
                                                                            role={DefaultRole.ADMIN}
                                                                            show>
                                                                            <TransactionList />
                                                                        </Auth>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='/transactions/:type'
                                                                    element={<TransactionList />}
                                                                />
                                                                <Route path='forms'>
                                                                    <Route
                                                                        path='schedule-pickup'
                                                                        element={<ScheduleTransactionPickupForm />}
                                                                    />
                                                                </Route>
                                                            </Route>

                                                            <Route path='/contacts'>
                                                                <Route
                                                                    index
                                                                    element={<ContactList />}
                                                                />
                                                            </Route>
                                                            <Route path='/groups'>
                                                                <Route
                                                                    index
                                                                    element={<ContactGroupList />}
                                                                />
                                                            </Route>
                                                            <Route path='/spots'>
                                                                <Route
                                                                    index
                                                                    element={<SpotNavigation />}
                                                                />
                                                                <Route
                                                                    path='/spots/:spotId/slots/*'
                                                                    element={<SlotList />}
                                                                />
                                                                <Route
                                                                    path='/spots/layout/:id'
                                                                    element={<SpotLayoutView />}
                                                                />
                                                                <Route
                                                                    path='/spots/layout'
                                                                    element={<Navigate to='/spots' />}
                                                                />
                                                            </Route>
                                                            <Route path='catalogues'>
                                                                <Route
                                                                    index
                                                                    element={<Shops />}
                                                                />
                                                                <Route
                                                                    path=':shopId/*'
                                                                    element={<ShopOverview />}
                                                                />
                                                            </Route>
                                                            <Route path='/reports'>
                                                                <Route
                                                                    index
                                                                    element={<ReportsOverview />}
                                                                />
                                                                <Route
                                                                    path=':reportId'
                                                                    element={<ReportDetail />}
                                                                />
                                                            </Route>
                                                            <Route path='iot'>
                                                                <Route
                                                                    index
                                                                    element={<Iot />}
                                                                />
                                                                <Route
                                                                    path='printing'
                                                                    element={<PrinterOverview />}
                                                                />
                                                                <Route
                                                                    path='access-controllers'
                                                                    element={<AccessControllerView />}
                                                                />
                                                            </Route>
                                                            <Route path='/me'>
                                                                <Route
                                                                    index
                                                                    element={<UserInfoPage />}
                                                                />
                                                                <Route
                                                                    path='change-password'
                                                                    element={<ChangePassword />}
                                                                />
                                                            </Route>
                                                            <Route path='/users'>
                                                                <Route
                                                                    index
                                                                    element={<UserList />}
                                                                />
                                                            </Route>
                                                            <Route path='/changelog'>
                                                                <Route
                                                                    index
                                                                    element={<Changelog />}
                                                                />
                                                            </Route>
                                                            <Route
                                                                path='/qr/*'
                                                                element={<QRView />}
                                                            />
                                                            <Route
                                                                path='/support'
                                                                element={<Support />}
                                                            />
                                                            <Route
                                                                path='/logs'
                                                                element={
                                                                    <Auth
                                                                        role={DefaultRole.ADMIN}
                                                                        show>
                                                                        <Logs />
                                                                    </Auth>
                                                                }
                                                            />
                                                            <Route
                                                                path='/success'
                                                                element={<CloseCurrentPage />}
                                                            />
                                                            <Route
                                                                path='/unauthorized'
                                                                element={<Unauthorized />}
                                                            />
                                                            <Route
                                                                path='/dashboard'
                                                                element={<Dashboard />}
                                                            />
                                                            <Route
                                                                path='/warehouse/*'
                                                                element={
                                                                    <Routes>
                                                                        <Route
                                                                            path='lost-items'
                                                                            element={<LostItemsView />}
                                                                        />
                                                                        {FEATURES.stockFillReport && (
                                                                            <Route
                                                                                path='stock-fill/*'
                                                                                element={
                                                                                    <Routes>
                                                                                        <Route
                                                                                            path=':id'
                                                                                            element={<StockFillDetail />}
                                                                                        />
                                                                                        <Route
                                                                                            index
                                                                                            element={<StockFillView />}
                                                                                        />
                                                                                    </Routes>
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Routes>
                                                                }></Route>
                                                            <Route
                                                                path='/'
                                                                element={
                                                                    <Navigate
                                                                        replace
                                                                        to='/dashboard'
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path='/logout'
                                                                element={<Logout />}
                                                            />
                                                            <Route
                                                                path='/login'
                                                                element={
                                                                    <Navigate
                                                                        replace
                                                                        to='/'
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path='/*'
                                                                element={<PageNotFound />}
                                                            />
                                                        </Routes>
                                                    )}
                                                </>
                                            ) : (
                                                <Routes>
                                                    <Route
                                                        path='/login'
                                                        element={<LoginModule />}
                                                    />
                                                    <Route
                                                        path='/signup-invitation/*'
                                                        element={<RegisterUserInvitation />}
                                                    />
                                                    <Route
                                                        path='request-password-change'
                                                        element={<RequestPasswordReset />}
                                                    />
                                                    <Route
                                                        path='reset-password'
                                                        element={<ResetPassword />}
                                                    />
                                                    <Route
                                                        path='/'
                                                        element={
                                                            <Navigate
                                                                replace
                                                                to='/login'
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path='/*'
                                                        element={
                                                            <Navigate
                                                                replace
                                                                to={'/login'}
                                                            />
                                                        }
                                                    />
                                                </Routes>
                                            )}
                                        </Suspense>
                                    </div>
                                    <Footer />
                                </main>
                            </div>
                        </FeatureFlagsProvider>
                    </AuthContext.Provider>
                </MainContextProvider>
            </BrowserRouter>
            <ToastContainer />
        </IntlProvider>
    );
}

export default App;
