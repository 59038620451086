import { Link, useLocation } from 'react-router-dom';

import { NavbarItems } from './Navbar';

interface NavbarListItemProps {
    text: string;
    link: NavbarItems;
    icon: React.ReactNode;
    parent?: string;
}

const NavbarListItem = (props: NavbarListItemProps) => {
    const location = useLocation();

    return (
        <Link
            id={'nav-' + props.link.toLowerCase()}
            to={props.parent ? `/${props.parent}/` + props.link.toLowerCase() : '/' + props.link.toLowerCase()}
            className={location.pathname.includes(props.link.toLocaleLowerCase()) ? 'w-100 bg-light rounded' : ''}>
            <li
                className='nav-item'
                key={props.text}>
                <a className='nav-link'>
                    <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                        <i className='text-primary text-sm opacity-10'>{props.icon}</i>
                    </div>
                    <span className='nav-link-text m-2'>{props.text}</span>
                </a>
            </li>
        </Link>
    );
};

export default NavbarListItem;
