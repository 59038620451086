import { useEffect, useState } from 'react';
import { BiCategory } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { Category } from '../../api/catalogue/Categories';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchCategories } from '../../store/categorySlice';
import { SliceStatus, shouldFetch } from '../../store/utils/Redux';
import { getAllChildren } from '../../utils/GetAllChildren';
import UnauthorizedBadge from './UnauthorizedBadge';

interface Props {
    categoryId?: number;
}

export default function ShopCategoryBadge(props: Props) {
    const authorised = useAuthorization(DefaultRole.ADMIN);
    const dispatch = useAppDispatch();
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const categoryState = useAppSelector((state) => state.categories);
    const [category, changeCategory] = useState<Category | undefined>();

    useEffect(() => {
        if (authorised === true && shouldFetch(categoryState.status, categoryState.lastUpdate)) dispatch(fetchCategories({ account: accountId.toString() }));
    }, []);

    useEffect(() => {
        if (categoryState.categoryList) {
            const allChildren = getAllChildren(categoryState.categoryList);
            changeCategory(allChildren.find((c) => c.id === props.categoryId));
        }
    }, [categoryState, props.categoryId]);

    if (!authorised) return <UnauthorizedBadge />;
    if (!category && (categoryState.status === SliceStatus.LOADING || categoryState.status === SliceStatus.INIT))
        return (
            <p className='d-flex placeholder-glow w-25'>
                <span className='placeholder col-12'></span>
            </p>
        );
    if (!category) return <p>No category found</p>;
    //TODO request shop id for cateogries otherwise its impossible to navigate to the correct url
    return (
        <p>
            <Link
                className='d-flex flex-row justify-content-center align-items-center text-primary'
                to={`../categories/?category=${props.categoryId}`}
                relative='path'>
                <BiCategory className='me-2' /> {category.name}
            </Link>
        </p>
    );
}
