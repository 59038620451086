import { WorkflowName } from '../common/Workflow';
import { ApiViewSet, apiList } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';
import { ApiError, putApi } from './utils';

export interface Terminal {
    id: number;
    url: string;
    name: string;
    workflow: string;
    spot_layout_url: string;
    spot_layout: number;
    delivery_contacts_url: string | null;
    delivery_contacts: number | null;
    delivery_contact_other_url: string | null;
    delivery_contact_other: number | null;
    default_receiver_contact: number | null;
    default_receiver_contact_url: string | null;
    receiver_contact_group: number | null;
    receiver_contact_group_url: string | null;
    shops: number[];
    shops_url: string;
    additional_data: object;
    actions_switch_terminal_on: boolean;
    computed_authentication_methods: ComputedAuthenticationMethods[];
    settings_allow_itsme_authentication: boolean;
    settings_switch_terminal_on_at: string;
    settings_switch_terminal_off_at: string;
    settings_informational_message: string;
    settings_registration_allowed: boolean;
    settings_contact_registration_allowed: boolean;
    settings_unknown_contact_registration_allowed: boolean;
    settings_door_open_in_seconds: number;
    settings_enable_ws_logger: boolean;
    settings_keep_ws_logs_in_days: number;
}

export enum TerminalQueryParams {
    ACCOUNT = 'account'
}

export interface TerminalsPostOptions {
    actions_switch_terminal_on: boolean;
    id: string;
}

export interface ComputedAuthenticationMethods {
    label: string;
    value: string;
    default_dropoff_action: boolean;
    default_pickup_action: boolean;
    default_remove_parcel_action: boolean;
}

export interface TerminalSettingsBody {
    account: number;
    name: string;
    workflow?: string | WorkflowName;
    spot_layout: number;
    delivery_contacts?: number | null;
    delivery_contact_other?: number | null;
    default_receiver_contact?: number | null;
    receiver_contact_group?: number | null;
    shops: number[];
    additional_data: object;
    settings_switch_terminal_on_at: string | null;
    settings_switch_terminal_off_at: string | null;
    settings_informational_message: string | null;
    settings_registration_allowed?: boolean;
    settings_contact_registration_allowed?: boolean;
    settings_unknown_contact_registration_allowed?: boolean;
    settings_door_open_time_in_seconds?: number | null;
    settings_enable_ws_logger?: boolean;
    settings_keep_ws_logs_in_days: number | null;
}

const terminalViewset: ApiViewSet = {
    baseName: 'terminals'
};

export async function fetchTerminalsApi(options?: ApiQueryParams<TerminalQueryParams>): Promise<Terminal[]> {
    return await apiList<Terminal, TerminalQueryParams>(terminalViewset, options);
}

export async function switchTerminalApi(terminals: TerminalsPostOptions): Promise<Terminal> {
    let terminalsUrl = '/terminals/';
    if (terminals.id) {
        terminalsUrl += terminals.id + '/switch_terminal/';
    }
    const body = { actions_switch_terminal_on: terminals.actions_switch_terminal_on };
    const response = await putApi(terminalsUrl, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating terminal');
        }
        throw new ApiError('Error updating terminal', json);
    }
    return await response.json();
}

export async function setTerminalSettingsApi(body: TerminalSettingsBody, id: number): Promise<Terminal> {
    const url = `/terminals/${id}/`;
    const response = await putApi(url, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating terminal settings');
        }
        throw new ApiError('Error updating terminal settings', json);
    }
    return await response.json();
}
