import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

import { Product } from '../../../api/catalogue/Products';

const ProductListColumns: Column<Product>[] = [
    {
        Header: 'ID',
        accessor: 'id'
    },
    {
        Header: (
            <FormattedMessage
                id='ProductList.tableHeader.Image'
                description='The image table header for the product list.'
                defaultMessage='Image'
            />
        ),
        accessor: 'image'
    },
    {
        Header: (
            <FormattedMessage
                id='ProductList.tableHeader.name'
                description='The name table header for the product list.'
                defaultMessage='Name'
            />
        ),
        accessor: 'name'
    },
    {
        id: 'description',
        Header: (
            <FormattedMessage
                id='ProductList.tableHeader.description'
                description='The description table header for the product list.'
                defaultMessage='Description'
            />
        ),
        accessor: (row) => {
            return `${row.weight} ${row.height} ${row.width} ${row.diameter} ${row.length}`;
        }
    },
    {
        id: 'stock',
        Header: (
            <FormattedMessage
                id='ProductList.tableHeader.stockCount'
                description='The stock count table header for the product list.'
                defaultMessage='Stock Count'
            />
        ),
        accessor: 'stock_count'
    }
];

export default ProductListColumns;
