import { Locale, NotificationChannel } from '../common/BackendEnums';
import { ApiViewSet, apiPagination } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';
import { TransactionAuthenticationMethods } from './Transactions';
import { ApiError, deleteApi, fetchApi, postApi, putApi } from './utils';

const USER_INFO_ENDPOINT = '/me/';

export interface UserInfo {
    id: number;
    url: string;
    first_name: string;
    last_name: string;
    language: Locale;
    username: string;
    email: string;
    mobile: string;
    phone: string;
    account_members: AccountMembership[];
    is_terminal: boolean;
    authentication_backend: AuthenticationMethod;
    accounts: number[];
}

export interface UserData {
    count: number;
    next: string | null;
    previous: string | null;
    results: UserInfo[];
}

export enum AuthenticationMethod {
    LOGIN_FORM = 'login_form',
    SSO = 'sso'
}

export interface AccountMembership {
    account: Account;
    role: string;
}

export interface Account {
    contacts: AccountContactModel[];
    id: number;
    name: string;
    transation_scheduling_config: AccountSchedulingConfig;
    default_receiver_contact?: number | null;
    default_receiver_contact_url?: string | null;
    untracked_transaction_receiver?: number | null;
    untracked_transaction_receiver_url?: string | null;
    computed_authentication_methods: ComputedAuthenticationMethod[];
}

interface ComputedAuthenticationMethod {
    label: string;
    value: TransactionAuthenticationMethods;
    default_dropoff_action: boolean;
    default_pickup_action: boolean;
    default_remove_parcel_action: boolean;
}

export interface AccountSchedulingConfig {
    allow_weekends?: boolean;
    time_slot_length_in_min?: number;
    initial_time_slot_offset?: string; //HH:mm format
}

export interface AccountContactModel {
    pk: number;
    id: number;
    url: string;
    first_name: string;
    last_name: string;
    title: string;
    birthday: Date;
    mobile: string;
    notification_channels: NotificationChannel[];
    language: Locale;
    account: number;
    user: number;
    contact_groups: number[];
    contact_group_urls: string[];
    additional_date: object;
    qr_code_access_keys: [{ [key: number]: string }];
    email: string;
}

export enum UserQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGE_SIZE = 'page_size'
}

const userViewSet: ApiViewSet = {
    baseName: 'users'
};

export interface CreateUserModel {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: Locale;
    email: string;
    password: string | null;
}

export interface UpdateUserModel {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: Locale;
    email: string;
    password: string | null;
}

export interface UsersOptions {
    id: number;
    user: UpdateUserModel;
}

export interface RegisterUserModel {
    key: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    mobile: string;
}

export async function fetchUserInfoApi(): Promise<UserInfo> {
    return (await fetchApi(USER_INFO_ENDPOINT, undefined)).json();
}

export async function fetchUsersApi(queryParams: ApiQueryParams<UserQueryParams> | null): Promise<UserData> {
    return await apiPagination<UserData, UserQueryParams>(userViewSet, queryParams);
}

export async function addUserApi(user: UpdateUserModel): Promise<UserInfo> {
    const userAddUrl = `/users/`;
    const response = await postApi(userAddUrl, user);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating user');
        }
        throw new ApiError('Error creating user', json);
    }
    return await response.json();
}

export async function updateUserApi(options: UsersOptions): Promise<UserInfo> {
    let userUpdateURL = `/users/`;

    if (options && options.id) {
        userUpdateURL += options.id + '/';
    }
    const body = options.user;
    const response = await putApi(userUpdateURL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating user');
        }
        throw new ApiError('Error updating user', json);
    }
    return await response.json();
}

export async function deleteUserApi(userId: number): Promise<boolean> {
    const URL = `/users/${userId}/`;
    const response = await deleteApi(URL);
    return await response.ok;
}

export async function registerUser(body: RegisterUserModel, inviteId: string) {
    let url = `${process.env.REACT_APP_API_URL}/rest-auth/invite-registration/${inviteId}/`;
    url = url.replace('/api', '');

    const response = await postApi(url, body, false);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating user');
        }
        throw new ApiError('Error creating user', json);
    }
    return await response.json();
}
