import { useEffect, useState } from 'react';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { UserInfo } from '../../api/User';
import { Locale } from '../../common/BackendEnums';
import { ClassType } from '../../common/BootstrapValues';
import EmailInput from '../../components/forms/emailInput/EmailInput';
import PhoneInput from '../../components/forms/phoneInput/PhoneInput';
import SelectInput from '../../components/forms/select/Select';
import TextInput from '../../components/forms/textInput/TextInput';
import CreateModal from '../../components/modals/CreateModal';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useAccount from '../../hooks/useAccount';
import { Logger } from '../../logging/Logger';
import { fetchContact } from '../../store/contactSlice';
import { fetchUserInfo, updateUser } from '../../store/userSlice';
import { GetError } from '../../store/utils/GetError';
import { SliceStatus } from '../../store/utils/Redux';
import { Languages } from '../../utils/FormUtils';
import { FindAccountRoleChange, FindChangedValue } from '../../utils/Logs';
import { parseObject } from '../../utils/ParseObject';
import { ToastUtil } from '../../utils/toasts/Toasts';
import AccountSelect from '../accounts/account-select/AccountSelect';
import { generateAccountMembers } from './CreateUserForm';
import RoleSelectModal from './roleselect/RoleSelectModal';

interface UpdateUserProps {
    user: UserInfo;
    handleClose: () => void;
}

const UpdateUser = (props: UpdateUserProps) => {
    const authorised = useAuthorization(DefaultRole.MEMBER, true);
    const isSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.user.selectedMembership?.account);
    const users = useAppSelector((state) => state.user);
    const [submitted, changeSubmitted] = useState(false);
    const [accountUsed, changeAccountUsed] = useState(false);
    const userAccount = props.user.account_members.map((a) => a.account);
    const [roleSelectOpen, changeRoleSelectOpen] = useState<boolean>(false);
    const { selectedUser } = useAccount();

    const initialState = {
        account_members: props.user.account_members.map((ac) => ({ account: ac.account.id, role: ac.role })),
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        language: props.user.language
            ? { value: props.user.language, label: Languages.find((l) => l.value === props.user.language)!.displayName }
            : { value: '', label: '' },
        email: props.user.email,
        mobile: props.user.mobile,
        phone: props.user.phone,
        password: null
    };

    useEffect(() => {
        if (userAccount.find((a) => a.id == account?.id)) {
            changeAccountUsed(true);
        }
    }, []);

    const submit = () => {
        changeSubmitted(true);
        const parsedForm = parseObject(formResult);
        if (authorised === true)
            dispatch(
                updateUser({
                    id: props.user.id,
                    user: {
                        email: formResult.email,
                        first_name: formResult.first_name,
                        language: parsedForm.language,
                        last_name: formResult.last_name,
                        password: formResult.password,
                        account_members: parsedForm.account_members.map((ac: any) => ({ account: { id: ac.account }, role: ac.role }))
                    }
                })
            );
    };

    useEffect(() => {
        if (submitted) {
            const existingUser = users.usersList?.results.find((u) => u.id === props.user.id);
            if (users.status === SliceStatus.IDLE && existingUser) {
                toast.success(...ToastUtil.generateToastConfig(formResult.email, userUpdatedSuccess, ClassType.SUCCESS));
                const difference = FindChangedValue(props.user, existingUser, undefined, ['account_members', 'accounts']);
                const accountDifference = FindAccountRoleChange(props.user, existingUser);
                Logger.log(`${selectedUser?.email} updated user ${existingUser?.email}`, { user: selectedUser?.id }, difference, accountDifference);
                dispatch(fetchUserInfo());
                users.selectedMembership?.account.contacts.forEach((c) => {
                    dispatch(fetchContact({ contactId: c.id }));
                });
                handleCancel();
            } else if (users.status === SliceStatus.ERROR) {
                toast.error(...ToastUtil.generateToastConfig(formResult.email, GetError(users.error), ClassType.DANGER));
                Logger.error(`${selectedUser?.email} failed to update user: ${existingUser?.email}`, { user: selectedUser?.id }, users.error?.json);
                changeSubmitted(false);
            }
        }
    }, [users.status, submitted]);

    const handleCancel = () => {
        changeSubmitted(false);
        changeFormResult(initialState);
        props.handleClose();
    };

    const [formResult, changeFormResult] = useState<UserForm>(initialState);
    const [FNValid, changeFNValid] = useState(false);
    const [LNValid, changeLNValid] = useState(false);
    const [LangValid, changeLangValid] = useState(false);
    const [ACValid, changeACValid] = useState(false);
    const [EmailValid, changeEmailValid] = useState(false);
    const [PNValid, changePNValid] = useState(false);
    const [MBValid, changeMBValid] = useState(false);
    const formValid = LNValid && FNValid && LangValid && ACValid && EmailValid && PNValid && MBValid;

    return (
        <>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                    submit();
                }}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <EmailInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Email'
                                        description={'Form label for email'}
                                        defaultMessage='Email'></FormattedMessage>
                                }
                                value={formResult.email}
                                onChange={(value) => changeFormResult({ ...formResult, email: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.Email'
                                        description={'Error message for email'}
                                        defaultMessage='Please enter a valid email'
                                    />
                                }
                                isValidCallback={(value) => {
                                    changeEmailValid(value);
                                }}
                                required
                                submitted={submitted}
                                id='Email-UpdateUser'></EmailInput>
                        </div>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.firstName'
                                        description={'Form label for first name'}
                                        defaultMessage='First name'></FormattedMessage>
                                }
                                value={formResult.first_name}
                                onChange={(value) => changeFormResult({ ...formResult, first_name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.firstName'
                                        description={'Error message for first name'}
                                        defaultMessage='Please enter a valid first name'
                                    />
                                }
                                isValidCallback={(value) => {
                                    changeFNValid(value);
                                }}
                                submitted={submitted}
                                id='firstName-UpdateUser'></TextInput>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.lastName'
                                        description={'Form label for last name'}
                                        defaultMessage='Last name'></FormattedMessage>
                                }
                                value={formResult.last_name}
                                onChange={(value) => changeFormResult({ ...formResult, last_name: value })}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.lastName'
                                        description={'Error message for last name'}
                                        defaultMessage='Please enter a valid last name'
                                    />
                                }
                                isValidCallback={(value) => {
                                    changeLNValid(value);
                                }}
                                submitted={submitted}
                                id='lastName-UpdateUser'></TextInput>
                        </div>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateUserForm.Label.Language'
                                        description={'Label for selecting language'}
                                        defaultMessage='Language'></FormattedMessage>
                                }
                                submitted={submitted}
                                options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                                value={formResult.language}
                                onChange={(value: any) => {
                                    changeFormResult({ ...formResult, language: value });
                                }}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateUserForm.Error.Language'
                                        description={'Error message for language'}
                                        defaultMessage='Please select a valid language'
                                    />
                                }
                                required
                                isValidCallback={(value) => {
                                    changeLangValid(value);
                                }}
                                id='Language-UpdateUser'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <PhoneInput
                                label={
                                    <FormattedMessage
                                        id='UpdateUser.Label.Phone'
                                        description={'Form label for phone number'}
                                        defaultMessage='Phone number'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.phone}
                                onChange={(value) => changeFormResult({ ...formResult, phone: value.toString() })}
                                isValidCallback={(value) => {
                                    changePNValid(value);
                                }}
                                id='phoneNumer-UpdateUser'
                            />
                        </div>
                        <div className='col-md-6'>
                            <PhoneInput
                                label={
                                    <FormattedMessage
                                        id='UpdateUser.Label.Mobile'
                                        description={'Form label for mobile number'}
                                        defaultMessage='Mobile number'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.mobile}
                                onChange={(value) => changeFormResult({ ...formResult, mobile: value.toString() })}
                                isValidCallback={(value) => {
                                    changeMBValid(value);
                                }}
                                id='mobileNumber-UpdateUser'
                            />
                        </div>
                    </div>
                    {isSuperAdmin && (
                        <div className='row'>
                            <div className='col-6'>
                                <AccountSelect
                                    isMulti
                                    label={
                                        <FormattedMessage
                                            id='CreateUserForm.Accounts.Select'
                                            description={'Label for selecting account'}
                                            defaultMessage='Account'></FormattedMessage>
                                    }
                                    submitted={submitted}
                                    options={users.user!.account_members.map((acc) => ({ value: acc.account.id, label: acc.account.name }))}
                                    value={formResult.account_members}
                                    isDisabled={accountUsed}
                                    onChange={(value: any) => {
                                        changeFormResult({ ...formResult, account_members: generateAccountMembers(value, formResult.account_members) });
                                    }}
                                    errorMessage={
                                        <FormattedMessage
                                            id='CreateUserForm.Error.Account'
                                            description={'Error message for account'}
                                            defaultMessage='Please select a valid account'
                                        />
                                    }
                                    isValidCallback={(value) => {
                                        changeACValid(value);
                                    }}
                                    id='Account-UpdateUser'
                                />
                                {formResult.account_members.length > 0 && (
                                    <button
                                        type='button'
                                        className='btn bg-gradient-primary'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            changeRoleSelectOpen(true);
                                        }}>
                                        Change roles
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className='modal-footer d-flex justify-content-between'>
                    <button
                        type='button'
                        className='btn bg-gradient-light'
                        onClick={handleCancel}>
                        <div className='d-flex flex-row align-items-center'>
                            <FaRegTimesCircle />
                            <span className='ms-2'>
                                {
                                    <FormattedMessage
                                        id='UpdateUser.Update.User.Cancel'
                                        description={'Label for cancel update user'}
                                        defaultMessage='Cancel'></FormattedMessage>
                                }
                            </span>
                        </div>
                    </button>
                    <button
                        type='submit'
                        className='btn bg-gradient-primary me-1'
                        disabled={!formValid}>
                        <div className='d-flex flex-row align-items-center'>
                            <FaRegCheckCircle />
                            <span className='ms-2'>
                                {
                                    <FormattedMessage
                                        id='UpdateUser.Update.User'
                                        description={'Label for updating a user'}
                                        defaultMessage='Update'></FormattedMessage>
                                }
                            </span>
                        </div>
                    </button>
                </div>
            </form>
            {changeRoleSelectOpen && (
                <CreateModal
                    handleClose={() => changeRoleSelectOpen(false)}
                    show={roleSelectOpen}
                    header='Change roles'
                    customWidth={100}>
                    <RoleSelectModal
                        handleClose={() => changeRoleSelectOpen(false)}
                        accounts={formResult.account_members}
                        onChange={(value) => changeFormResult({ ...formResult, account_members: value })}
                    />
                </CreateModal>
            )}
        </>
    );
};

export default UpdateUser;

export interface UserForm {
    account_members: { account: number; role: string }[];
    first_name: string;
    last_name: string;
    language: { value: Locale | string; label: string };
    email: string;
    mobile: string;
    phone: string;
    password: string | null;
}

const userUpdatedSuccess = (
    <FormattedMessage
        id='UpdateUser.Updated.User.Success'
        description={'Message when successfully updating a user'}
        defaultMessage='Your user was successfully updated'
    />
);

const userUpdatedError = (
    <FormattedMessage
        id='CreateUser.Error.User.Update'
        description={'Error message when updating a user'}
        defaultMessage='An error occurred while updating this user'
    />
);
