export enum SliceStatus {
    INIT = 'init',
    IDLE = 'idle',
    LOADING = 'loading',
    ERROR = 'error'
}
export enum SliceOperation {
    GET,
    PUT,
    POST,
    DELETE
}
/**
 * Will determin if it is usefull to refetch data
 * @param status
 * @param lastUpdate
 * @param staleTime overwrite the time between the last update in minutes (defaults to 2 minutes if no param is provided)
 * @returns
 */
export function shouldFetch(status: SliceStatus, lastUpdate: number, staleTime?: number): boolean {
    if (status === SliceStatus.INIT) return true;
    const actualStaleTime = staleTime !== undefined ? staleTime : 2;
    if (status === SliceStatus.IDLE && (new Date().getTime() - lastUpdate) / 60000 > actualStaleTime) return true;
    return false;
}
