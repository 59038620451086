import { Locale, NotificationChannel } from '../common/BackendEnums';
import { ApiViewSet, apiDetail, apiList, apiPagination } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';
import { ApiError, deleteApi, fetchApi, postApi, putApi } from './utils';

export interface CreateContactModel {
    first_name: string;
    last_name?: string;
    title?: string;
    mobile?: string;
    email: string;
    birthday?: string | null;
    notification_channels: NotificationChannel[];
    language?: Locale;
    account: number;
    user: number | null;
    contact_groups?: number[];
    additional_data?: object | null;
}

export interface Contact {
    id: number;
    url: string;
    first_name: string;
    last_name: string;
    title: string;
    mobile: string;
    email: string;
    birthday?: Date | null;
    notification_channels: NotificationChannel[];
    language: Locale | '';
    account: number;
    user: number | null;
    contact_groups: number[];
    contact_group_urls: string[];
    additional_data: {
        login_allowed?: boolean;
        [key: string]: unknown;
    };
    qr_code_access_keys: string[];
    created_at: string;
    computed_days_since_last_activity: number;
    last_active_time: string;
}

export interface ContactResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: Contact[];
}

export interface ContactsOptions {
    contactId?: string | number | null;
    contactLoginId?: string | number | null;
    contactUrl?: string | null;
    updateBody?: CreateContactModel;
}

export enum ContactQueryParams {
    ACCOUNT = 'account',
    CONTACT_GROUP_ID = 'contact-group',
    CONTACT_GROUP_HIERACHICAL = 'contact-group-hierarchical',
    PAGE = 'page',
    PAGES_SIZE = 'page_size',
    SEARCH = 'search',
    ORDERING = 'ordering'
}

const contactViewSet: ApiViewSet = {
    baseName: 'contacts'
};

export const isContact = (contact: any): contact is Contact => {
    return (contact as Contact).url !== undefined && (contact as Contact).url.includes('/contacts/');
};

export async function fetchContactsApi(queryParams?: ApiQueryParams<ContactQueryParams>): Promise<ContactResponse> {
    return await apiPagination<ContactResponse, ContactQueryParams>(contactViewSet, queryParams);
}

export async function fetchContactApi(options: ContactsOptions): Promise<Contact> {
    if (options && options.contactUrl) {
        return apiDetail<Contact>(contactViewSet, { url: options.contactUrl });
    } else if (options && options.contactId) {
        return apiDetail<Contact>(contactViewSet, { id: options.contactId });
    }

    if (!options.contactUrl && options.contactLoginId) {
        options.contactUrl = `/contacts/${options.contactLoginId!}/`;
    } else options.contactUrl = `/contacts/${options.contactId!}/`;
    const response = await fetchApi(options.contactUrl, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error fetching contacts');
        }
        throw new ApiError('Error fetching contacts', json);
    }
    return await response.json();
}

export async function addContactApi(contact: CreateContactModel): Promise<Contact> {
    const contactAddUrl = `/contacts/`;
    const response = await postApi(contactAddUrl, contact);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error adding contact');
        }
        throw new ApiError('Error adding contact', json);
    }
    return await response.json();
}

export async function updateContactApi(options: ContactsOptions): Promise<Contact> {
    let updateContactUrl = '/contacts/';
    if (options && options.contactId) {
        updateContactUrl += options.contactId + '/';
    }
    const body = options.updateBody;

    const response = await putApi(updateContactUrl, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating contact');
        }
        throw new ApiError('Error updating contact', json);
    }
    return await response.json();
}

export async function deleteContactApi(contactId: string | number): Promise<boolean> {
    const URL = `/contacts/${contactId}/`;
    const response = await deleteApi(URL);
    return await response.ok;
}

export async function getContactQRApi(qrCode: string): Promise<{ id: number }> {
    let URL = `/qr/`;
    if (qrCode) {
        URL += qrCode + `/`;
    }
    const response = await fetchApi(URL, undefined);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('No contact found with this QR');
        }
        throw new ApiError('No contact found with this QR');
    }
    return await response.json();
}

export async function importContactsApi(body: FormData): Promise<void> {
    const URL = '/contacts/import-csv/';
    const response = await postApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error uploading csv');
        }
        throw new ApiError('Error uploading csv', json);
    }
    return await response.json();
}

export async function searchContactsApi(queryParams?: ApiQueryParams<ContactQueryParams>): Promise<Contact[]> {
    return await apiList<Contact, ContactQueryParams>(contactViewSet, queryParams);
}
