import React, { useEffect, useState } from 'react';

import { getInputClass } from '../../../utils/FormUtils';

interface PhoneInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    submitted: boolean;
    id?: string;
}

const internationalRegex =
    /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
//const nonInternational = /^[0-9]{0,14}$/;

export default function PhoneInput(props: PhoneInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const allowedInput = (value: string): boolean => {
        if (value == '' || value == '+') return true;
        return /^[0-9]+$/.test(value.replace('+', ''));
    };

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === '' || props.value === undefined)) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;

        if (props.value && check) {
            check = internationalRegex.test(props.value);
            /*if(props.value.startsWith('+')) check = internationalRegex.test(props.value);
            else check = nonInternational.test(props.value);*/
        }

        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label ? props.label : 'Phone number'}
                </label>
                <input
                    className={getInputClass(touched, valid, props.value)}
                    id={props.id ? props.id : 'PhonenumberInput'}
                    value={props.value}
                    type='tel'
                    placeholder='ex. +32123456789'
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        if (props.onChange && allowedInput(event.target.value)) props.onChange(event.target.value);
                        isValid();
                    }}
                />
                {valid === false && touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ? props.errorMessage : 'Please enter a valid phone number.'}</small>
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
