import { toNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FaFileImport } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { Category } from '../../../api/catalogue/Categories';
import { Product } from '../../../api/catalogue/Products';
import { ClassType } from '../../../common/BootstrapValues';
import Filter from '../../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import CreateModal from '../../../components/modals/CreateModal';
import DisplayModal from '../../../components/modals/DisplayModal';
import Table from '../../../components/tables/Table';
import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAttributes } from '../../../store/attributeSlice';
import { fetchCategories } from '../../../store/categorySlice';
import { fetchProductById, fetchProducts, searchProducts } from '../../../store/productSlice';
import { SliceStatus, shouldFetch } from '../../../store/utils/Redux';
import FilterBar, { SelectedAttributes } from '../FilterBar/FilterBar';
import ProductBreadcrumb from '../ProductBreadcrumb/ProductBreadcrumb';
import CreateProductForm from '../forms/create-product-form/CreateProductForm';
import ImportModal from '../import/ImportModal';
import ProductDetailModal from '../products-detail/ProductDetailModal';
import ProductListColumns from './ProductListColumns';
import ProductListElement from './ProductListElement';

const PRODUCT_LIST_ID = 'ProductList';

export default function ProductList() {
    const authorised = useAuthorization(DefaultRole.SUPER_ADMIN, true);

    const { shopId } = useParams();
    const [URL_PRODUCT_ID, changeURL_PRODUCT_ID] = useState<string | null>(new URLSearchParams(window.location.search).get('id'));
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const [pageSize, changePageSize] = useState(25);
    const [pageIndex, changePageIndex] = useState(1);
    const products = useAppSelector((state) => state.products);
    const attributes = useAppSelector((state) => state.attributes);
    const categories = useAppSelector((state) => state.categories);
    const [isOpen, setIsOpen] = useState(false);
    const isInit = products.productList === null;
    const [textFilter, changeTextFilter] = useState<Product[] | undefined>();
    const [textFilterValue, changeTextFilterValue] = useState<string>();
    const [selectedCategory, changeSelectedCategory] = useState<Category | undefined>();
    const [selectedAttributes, changeSelectedAttributes] = useState<SelectedAttributes[] | undefined>();
    const [importModal, changeImportModal] = useState<boolean>(false);

    const [selectedProduct, changeSelectedProduct] = useState<Product>();
    const [filteredProducts, changeFilteredProducts] = useState<Product[] | undefined>(products.productList?.results);

    const { state } = useLocation();

    useEffect(() => {
        if (!isInit && URL_PRODUCT_ID) {
            if (state === null) {
                const foundProduct = products.productList?.results.find((p) => p !== null && p !== undefined && p.id === toNumber(URL_PRODUCT_ID));
                if (foundProduct) {
                    changeSelectedProduct(products.productList?.results.find((p) => p.id === toNumber(URL_PRODUCT_ID)));
                    changeURL_PRODUCT_ID(null);
                } else {
                    dispatch(fetchProductById(URL_PRODUCT_ID));
                }
            } else {
                changeSelectedProduct(state.product);
                changeURL_PRODUCT_ID(null);
            }
        }
    }, [isInit, URL_PRODUCT_ID, products.productList]);

    useEffect(() => {
        if (authorised && shopId && shouldFetch(products.status, products.lastUpdate))
            dispatch(fetchProducts({ account: accountId?.toString(), shop: shopId, page: pageIndex.toString(), page_size: pageSize.toString() }));
    }, []);

    useEffect(() => {
        if (authorised && shopId && (textFilterValue === undefined || textFilterValue === '')) {
            dispatch(fetchProducts({ account: accountId?.toString(), shop: shopId, page: pageIndex.toString(), page_size: pageSize.toString() }));
        } else {
            dispatch(searchProducts({ search: textFilterValue, account: accountId.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
        }
    }, [pageIndex, pageSize]);

    useEffect(() => {
        if (authorised && products.productList !== null && products.status !== SliceStatus.LOADING) {
            if (shouldFetch(attributes.status, attributes.lastUpdate)) dispatch(fetchAttributes({ account: accountId.toString() }));
            if (shouldFetch(categories.status, categories.lastUpdate)) dispatch(fetchCategories({ account: accountId.toString() }));
        }
    }, [products.productList, products.status]);

    useEffect(() => {
        if (textFilterValue !== undefined && textFilterValue !== '') {
            dispatch(searchProducts({ search: textFilterValue, account: accountId.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
        }
    }, [textFilterValue]);

    useEffect(() => {
        if (!products.productList) return;

        let validProducts: Product[] = [];

        if (textFilterValue !== undefined && textFilterValue !== '' && products.searchProductsList && products.searchProductsList[textFilterValue]) {
            validProducts = products.searchProductsList[textFilterValue].results.filter((p) => p.shops_list.map((s) => s.id).includes(toNumber(shopId)));
        } else {
            validProducts = products.productList.results.filter((p) => p.shops_list.map((s) => s.id).includes(toNumber(shopId)));
        }

        if (selectedCategory) {
            const categoryIds = [selectedCategory.id, ...(selectedCategory.children || []).map((c) => c.id)];
            validProducts = validProducts.filter((p) => p.category && categoryIds.includes(p.category));
        }

        if (selectedAttributes && selectedAttributes.length > 0) {
            const filteredArray = validProducts.filter((p) =>
                p.product_attributes.some((pa) => selectedAttributes.some((sa) => sa.attribute === pa.attribute && sa.values.includes(pa.value)))
            );
            validProducts = filteredArray;
        }

        if (textFilter && textFilter.length !== 0 && textFilterValue && textFilterValue.length !== 0) {
            validProducts = validProducts.filter((p) => textFilter.some((tf) => tf.id === p.id));
        }

        changeFilteredProducts(validProducts);
    }, [products.productList, textFilter, selectedCategory, selectedAttributes, products.searchProductsList, textFilterValue]);

    const data = useMemo(() => {
        if (filteredProducts) {
            return filteredProducts;
        } else return [];
    }, [filteredProducts, pageIndex, pageSize]);
    const columns: Column<Product>[] = useMemo(() => ProductListColumns, []);

    if (filteredProducts === null) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }
    const showFilter = (categories.categoryList && categories.categoryList.length > 0) || (attributes.attributeList && attributes.attributeList.length > 0);
    return (
        <>
            <div
                id={PRODUCT_LIST_ID}
                className='d-flex flex-row'>
                <div className={showFilter ? `col-2 pe-2` : ``}>
                    {showFilter && (
                        <FilterBar
                            onChange={(value) => changeSelectedCategory(value)}
                            selectedCategory={selectedCategory}
                            selectedAttributes={(value) => changeSelectedAttributes(value)}
                        />
                    )}
                </div>
                <div className={showFilter ? `col-10` : `col-12`}>
                    <div className='card'>
                        <div className='card-header d-flex'>
                            <div className='d-flex flex-column align-items-start w-100'>
                                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                    <div className='d-flex flex-row justify-content-start align-items-center'>
                                        <HorizontalButtonGroup
                                            buttons={[
                                                {
                                                    type: ClassType.PRIMARY,
                                                    hide: false,
                                                    text: (
                                                        <FormattedMessage
                                                            id='UserList-Create-Product'
                                                            description='Label for creating a product'
                                                            defaultMessage='Create Product'
                                                        />
                                                    ),
                                                    icon: FaPlus,
                                                    id: 'ProductList-CreateProductButton',
                                                    onClick: () => setIsOpen(true)
                                                },
                                                {
                                                    type: ClassType.SECONDARY,
                                                    hide: false,
                                                    text: (
                                                        <FormattedMessage
                                                            id='ProductList.Button.Import'
                                                            description='The button for import on the product list component'
                                                            defaultMessage='Import'
                                                        />
                                                    ),
                                                    icon: FaFileImport,
                                                    onClick: () => changeImportModal(true)
                                                }
                                            ]}
                                            direction='left'
                                        />
                                    </div>
                                    <div className='d-flex flex-row px-1'>
                                        <ProductBreadcrumb
                                            selectedCategory={selectedCategory}
                                            onChange={(value) => changeSelectedCategory(value)}
                                        />
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <div className='px-1'>
                                            <Filter
                                                objects={data}
                                                filterCallback={changeTextFilter}
                                                filterValue={(value) => changeTextFilterValue(value.toLowerCase())}
                                                filterKeys={['name']}
                                                placeholder='Search'
                                                useSearch
                                                id='ProductList-Filter'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            data={data}
                            next={products.productList?.next}
                            pageCount={
                                textFilterValue && textFilterValue.length > 0
                                    ? products.searchProductsList && products.searchProductsList[textFilterValue]
                                        ? Math.ceil(products.searchProductsList[textFilterValue].count / pageSize)
                                        : 0
                                    : products.productList
                                    ? Math.ceil(products.productList.count / pageSize)
                                    : undefined
                            }
                            pageSize={(value) => changePageSize(value)}
                            page={(value) => changePageIndex(value + 1)}
                            onSelectElement={changeSelectedProduct}
                            RowElement={ProductListElement}
                        />
                    </div>
                    {selectedProduct && (
                        <ProductDetailModal
                            show={!!selectedProduct}
                            handleClose={() => changeSelectedProduct(undefined)}
                            product={selectedProduct}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            filter={textFilterValue}
                        />
                    )}
                    {isOpen && (
                        <CreateModal
                            show={isOpen}
                            handleClose={() => setIsOpen(false)}
                            header='Create Product'>
                            <CreateProductForm
                                pageSize={pageSize}
                                handleClose={() => setIsOpen(false)}
                            />
                        </CreateModal>
                    )}
                    {importModal && (
                        <>
                            <DisplayModal
                                show={importModal}
                                header='Import products'
                                handleClose={() => changeImportModal(false)}>
                                <div className='modal-body'>
                                    <p>
                                        {Helptext1}
                                        <ul>
                                            <li>
                                                {Helptext2}
                                                <br />
                                                Name, Description, Category, EAN, SKU, Image Name, Weight, Weight Tolerance, Length, Width, Height, Diameter,
                                                Low Stock Threhsold Alert, Max Stock
                                            </li>
                                            <li>{Helptext3}</li>
                                            <li>{Helptext4}</li>
                                            <li>{Helptext5}</li>
                                            <li>{Helptext6}</li>
                                        </ul>

                                        {Helptext7}
                                    </p>
                                </div>
                                <ImportModal
                                    extension='image'
                                    handleClose={() => changeImportModal(false)}
                                    helpText={
                                        <FormattedMessage
                                            id='ImportModal.helpText.Image'
                                            description='Help text for the image import'
                                            defaultMessage='Make sure the images you select have the exact name and extension as in your csv file'
                                        />
                                    }
                                />
                                <ImportModal
                                    extension='csv'
                                    handleClose={() => changeImportModal(false)}
                                    helpText={
                                        <FormattedMessage
                                            id='ImportModal.helpText.CSV'
                                            description='Help text for the image import'
                                            defaultMessage='Make sure to upload your images first & that your csv contains the exact name and extension of the images'
                                        />
                                    }
                                />
                            </DisplayModal>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

const Helptext1 = (
    <FormattedMessage
        id='ImportModal.helpText.1'
        description='Help text for the product import'
        defaultMessage='When you are trying to upload products using a CSV import you should follow the following steps.'
    />
);
const Helptext2 = (
    <FormattedMessage
        id='ImportModal.helpText.2'
        description='Help text for the product import'
        defaultMessage='The csv file should contain the following columns (in the same order):'
    />
);
const Helptext3 = (
    <FormattedMessage
        id='ImportModal.helpText.3'
        description='Help text for the product import'
        defaultMessage='None of the fields are required except for the Name field. Note that the columns should always be there, the values can simply be empty.'
    />
);
const Helptext4 = (
    <FormattedMessage
        id='ImportModal.helpText.4'
        description='Help text for the product import'
        defaultMessage='When filling in fields like weight or lenght, do not add units. They will be added by the server.'
    />
);
const Helptext5 = (
    <FormattedMessage
        id='ImportModal.helpText.5'
        description='Help text for the product import'
        defaultMessage='Upload all images mentioned inside the csv file.'
    />
);
const Helptext6 = (
    <FormattedMessage
        id='ImportModal.helpText.6'
        description='Help text for the product import'
        defaultMessage='Upload the csv file itself.'
    />
);
const Helptext7 = (
    <FormattedMessage
        id='ImportModal.helpText.7'
        description='Help text for the product import'
        defaultMessage='Note that when filling in fields like weight or lenght, do not add units. They will be added by the server. For lengths use millimeters and for weights use grams.'
    />
);
