import React, { useEffect, useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';

import { getInputClass } from '../../../utils/FormUtils';
import { compareTwoStrings } from '../../../utils/Strings';

interface TextInputProps {
    compareMultiple?: string[];
    compare?: string;
    strength?: boolean;
    toRepeat?: string;
    required?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    id?: string;
}

export default function PasswordInput(props: TextInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [visible, changeVisible] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(true);
    const [strength, changeStrength] = useState<number>(0);
    const [error, changeError] = useState<PasswordInputError>(PasswordInputError.NONE);
    const style = { width: strength + '%' };
    const color = strength < 50 ? 'danger' : strength < 90 ? 'warning' : 'success';

    const isValid = (): boolean => {
        let error = PasswordInputError.NONE;
        if (error === PasswordInputError.NONE && props.required === true && (props.value === '' || props.value === undefined))
            error = PasswordInputError.REQUIRED;
        if (props.toRepeat !== undefined && props.toRepeat != props.value) error = PasswordInputError.REPEAT_NOT_IDENTICAL;
        if (props.strength && !(props.value && strength === 100)) error = PasswordInputError.STRENGTH;
        if (props.compare !== undefined && props.value !== undefined && compareTwoStrings(props.value, props.compare) >= 0.3)
            error = PasswordInputError.SIMILARITY;
        if (props.compareMultiple !== undefined && props.value !== undefined && props.compareMultiple.find((s) => compareTwoStrings(props.value!, s) >= 0.3))
            error = PasswordInputError.SIMILARITY;
        changeError(error);
        changeValid(error === PasswordInputError.NONE);
        return error === PasswordInputError.NONE;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.compare, props.compareMultiple, props.toRepeat]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label ? props.label : 'Password'}
                </label>

                <div className='input-group'>
                    <input
                        className={getInputClass(touched, valid, props.value)}
                        autoFocus={props.autoFocus}
                        data-testid='passwordInput'
                        id={props.id}
                        value={props.value}
                        autoComplete='current-password'
                        type={visible ? 'text' : 'password'}
                        onFocus={() => {
                            changeTouched(true);
                            props.onFocusChange && props.onFocusChange();
                        }}
                        required={props.required}
                        onChange={(event) => {
                            if (props.onChange) props.onChange(event.target.value);
                            changeStrength(calcStrengthPercentage(event.target.value));
                            isValid();
                        }}
                    />
                    <button
                        className='btn btn-outline-secondary mb-0'
                        type='button'
                        id='button-addon2'
                        onClick={() => changeVisible(!visible)}>
                        {visible ? <BsEyeSlash /> : <BsEye />}
                    </button>
                </div>
                {props.strength && (
                    <div className='progress mt-2'>
                        <div
                            className={'progress-bar bg-gradient-' + color}
                            role='progressbar'
                            aria-valuenow={strength}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={style}
                        />
                    </div>
                )}
                {!(props.compare == undefined && props.compareMultiple == undefined) && (
                    <span className='mt-2'>
                        <small>
                            <FormattedMessage
                                id='passwordInput.helpMessage'
                                description='The password input help message'
                                defaultMessage='Your password should contain at least 1 small letter, 1 capital letter, 1 number, 1 symbol and be a minimum of 8 characters long.'
                            />
                        </small>
                    </span>
                )}

                {valid === false && touched === true && (
                    <>
                        {props.errorMessage === undefined ? (
                            <>
                                {error === PasswordInputError.REQUIRED && requiredPWError}
                                {error === PasswordInputError.STRENGTH && strengthPWError}
                                {error === PasswordInputError.SIMILARITY && similarityPWError}
                                {error === PasswordInputError.REPEAT_NOT_IDENTICAL && repeatPWError}
                            </>
                        ) : (
                            <span className='badge bg-gradient-danger mt-2'>
                                <small>{props.errorMessage}</small>
                            </span>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

enum PasswordInputError {
    NONE,
    REQUIRED,
    STRENGTH,
    SIMILARITY,
    REPEAT_NOT_IDENTICAL
}

const repeatPWError = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='passwordInput.repeat.errorMessage'
                description='The error message for the repeat password input.'
                defaultMessage='Both passwords should be the same.'
            />
        </small>
    </span>
);
const strengthPWError = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='passwordInput.strength.errorMessage'
                description='The error message for to low password strength.'
                defaultMessage='Make sure your password meets all strength requirements.'
            />
        </small>
    </span>
);
const similarityPWError = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='passwordInput.similarity.errorMessage'
                description='The error message for the password input when the password is to similar to other information.'
                defaultMessage='Your password shares similarity with other information you have given. Please make it more random.'
            />
        </small>
    </span>
);
const requiredPWError = (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='passwordInput.required.errorMessage'
                description='The error message for the password input when no password is filled in.'
                defaultMessage='A password is required.'
            />
        </small>
    </span>
);

function calcStrengthPercentage(value: string): number {
    let strength = 0;

    if (value.length >= 8) strength += 20;
    if (/[A-Z]/.test(value)) strength += 20;
    if (/[a-z]/.test(value)) strength += 20;
    if (/\d/.test(value)) strength += 20;
    // eslint-disable-next-line no-useless-escape
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) strength += 20;
    return strength;
}
