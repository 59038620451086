import { ApiViewSet, DetailOptions, apiDetail, apiList, apiPagination } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';
import { ApiError, PaginationResponse, deleteApi, postApi, putApi } from '../utils';
import { Attribute } from './Attributes';

export interface Category {
    id: number;
    url: string;
    name: string;
    image: string | null;
    parent_id: number | null;
    children: Category[] | null;
    account: number;
    attributes: number[] | Attribute[];
}

export type CategoryResponse = PaginationResponse<Category>;

const categoryTreeViewSet: ApiViewSet = {
    baseName: 'category-trees'
};
const categoryViewSet: ApiViewSet = {
    baseName: 'categories'
};

export enum CategoriesQueryParams {
    NAME = 'name',
    PARENT = 'parent',
    ACCOUNT = 'account',
    SHOP = 'shop',
    PAGE = 'page',
    PAGE_SIZE = 'page_size'
}

export function fetchCategoryTreeApi(queryParams?: ApiQueryParams<CategoriesQueryParams> | null): Promise<Category[]> {
    return apiList<Category, CategoriesQueryParams>(categoryTreeViewSet, queryParams);
}

export function fetchCategoryApi(options: DetailOptions): Promise<Category> {
    return apiDetail<Category>(categoryViewSet, options);
}

export function fetchAllCategoriesApi(queryParams?: ApiQueryParams<CategoriesQueryParams> | null): Promise<CategoryResponse> {
    return apiPagination<CategoryResponse, CategoriesQueryParams>(categoryViewSet, queryParams);
}

export async function createCategoryApi(category: FormData): Promise<Category> {
    const URL = `/categories/`;
    const response = await postApi(URL, category);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating category');
        }
        throw new ApiError('Error creating category');
    }
    return await response.json();
}

export async function updateCategoryApi(category: FormData, id: number): Promise<Category> {
    let URL = `/categories/`;
    if (id) {
        URL += id + '/';
    }

    const response = await putApi(URL, category);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating category');
        }
        throw new ApiError('Error updating category');
    }
    return await response.json();
}

export async function deleteCategoryApi(categoryId: number): Promise<boolean> {
    const URL = `/categories/${categoryId}/`;
    const response = await deleteApi(URL);
    return await response.ok;
}
