import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    Attribute,
    AttributesQueryParams,
    createAttributeApi,
    deleteAttributeApi,
    fetchAttributeApi,
    fetchAttributesApi,
    updateAttributeApi
} from '../api/catalogue/Attributes';
import { ApiError } from '../api/utils';
import { CreateAttributeModel } from '../catalogue/attributes/forms/CreateAttributeForm';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface AttributesState {
    attributeList: Attribute[] | null;
    status: SliceStatus;
    lastUpdate: number;
    error: ReduxError | null;
}

const initialState: AttributesState = {
    attributeList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    error: null
};

export const attributeSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttributes.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAttributes.fulfilled, (state, action) => {
                state.attributeList = action.payload;
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAttributes.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAttributeById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAttributeById.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList !== null) {
                    state.attributeList = state.attributeList.filter((s) => attribute.id !== s.id);
                    state.attributeList.push(attribute);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAttributeById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAttributeByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAttributeByUrl.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList !== null) {
                    state.attributeList = state.attributeList.filter((s) => attribute.id == s.id);
                    state.attributeList.push(attribute);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAttributeByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(addAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(addAttribute.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList !== null) {
                    state.attributeList.push(attribute);
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(addAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateAttribute.pending, (state) => {
                state.status === SliceStatus.LOADING;
            })
            .addCase(updateAttribute.fulfilled, (state, action) => {
                const attribute = action.payload;
                if (state.attributeList) {
                    const existingAttribute = state.attributeList.find((a) => a.id === attribute.id);
                    if (existingAttribute) {
                        const attributeIndex = state.attributeList.indexOf(existingAttribute);
                        state.attributeList.splice(attributeIndex, 1, attribute);
                    }
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(updateAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(deleteAttribute.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteAttribute.fulfilled, (state, action) => {
                if (action.payload === true && state.attributeList) {
                    const attributeId = action.meta.arg;
                    state.attributeList = state.attributeList.filter((a) => a.id != attributeId);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteAttribute.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchAttributes = createAsyncThunk<Attribute[], ApiQueryParams<AttributesQueryParams>>(
    'fetchAttributes',
    async (queryParams: ApiQueryParams<AttributesQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchAttributesApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAttributeById = createAsyncThunk<Attribute, string>('fetchAttributeById', async (attributeId, { rejectWithValue }) => {
    try {
        return await fetchAttributeApi({ id: attributeId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchAttributeByUrl = createAsyncThunk<Attribute, string>('fetchAttributeByUrl', async (attributeUrl, { rejectWithValue }) => {
    try {
        return await fetchAttributeApi({ url: attributeUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const addAttribute = createAsyncThunk<Attribute, CreateAttributeModel>('addAttribute', async (attribute, { rejectWithValue }) => {
    try {
        return await createAttributeApi(attribute);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateAttribute = createAsyncThunk<Attribute, { attribute: CreateAttributeModel; id: number }>(
    'updateAttribute',
    async (options: { attribute: CreateAttributeModel; id: number }, { rejectWithValue }) => {
        try {
            return await updateAttributeApi(options.attribute, options.id);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteAttribute = createAsyncThunk<boolean, number>('deleteAttribute', async (attributeId, { rejectWithValue }) => {
    try {
        return await deleteAttributeApi(attributeId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});
export default attributeSlice.reducer;
