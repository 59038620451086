import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiQueryParams } from '../api/BaseQueryParams';
import { LogQueryParams, Logs, fetchLogsApi } from '../api/logs/Logs';
import { ApiError } from '../api/utils';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface logState {
    logList: Logs | null;
    status: SliceStatus;
    lastUpdate: number;
    message: string;
    error: ReduxError | null;
}

const initialState: logState = {
    logList: null,
    status: SliceStatus.INIT,
    lastUpdate: Date.now(),
    message: '',
    error: null
};

export const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogs.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchLogs.fulfilled, (state, action) => {
                if (state.logList === null) {
                    state.logList = {
                        data: {
                            ...action.payload.data,
                            logs: action.payload.data.logs
                        }
                    };
                }
                if (action.meta.arg['fetchNext'] === true) {
                    state.logList.data = {
                        ...state.logList.data,
                        nextToken: action.payload.data.nextToken
                    };
                    state.logList.data.logs.push(...action.payload.data.logs);
                } else {
                    state.logList = action.payload;
                }
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchLogs.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            });
    }
});

export const fetchLogs = createAsyncThunk<Logs, { fetchNext?: boolean; queryparams: ApiQueryParams<LogQueryParams> }>(
    'fetchLogs',
    async (options: { fetchNext?: boolean; queryparams?: ApiQueryParams<LogQueryParams> }, { rejectWithValue }) => {
        try {
            return await fetchLogsApi(options.fetchNext, options.queryparams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default logSlice.reducer;
