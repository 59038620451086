import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Slot } from '../../../api/Slots';
import { SpotLayoutItem } from '../../../api/SpotLayoutItems';
import { SpotLayout } from '../../../api/SpotLayouts';
import { Spot } from '../../../api/Spots';
import { Product } from '../../../api/catalogue/Products';
import { useAppSelector } from '../../../hooks/redux';
import Item from '../../../spotLayout/layout/Item';

interface Props {
    spot: Spot;
    spotLayout: SpotLayout;
    spotLayoutItems: SpotLayoutItem[];
    product?: Product;
    highlighted?: (spotlayoutItem: SpotLayoutItem) => boolean;
    occupied?: (spotLayoutItem: SpotLayoutItem) => boolean;
    selected?: (slot: Slot) => boolean;
    selectedSlots?: (value: any) => void;
}

const SlotAssignmentLayout = (props: Props) => {
    const [width, changeWidth] = useState('100%');
    const height = '100%';
    const [aspectRatio, changeAspectRatio] = useState(0);
    const layoutBox = useRef<HTMLDivElement>(null);
    const spotAspectRatio = props.spotLayout.width / props.spotLayout.height;
    const slotState = useAppSelector((state) => state.slots);
    const [selectedSlotsList, changeSelectedSlotsList] = useState<Slot[]>([]);
    const slotList: Slot[] = [];

    const containerStyle = {
        width: width,
        height: height
    };

    const currentLayoutBoxWidth = layoutBox ? (layoutBox.current ? layoutBox.current.clientWidth : null) : null;
    const currentLayoutBoxHeight = layoutBox ? (layoutBox.current ? layoutBox.current.clientHeight : null) : null;

    useLayoutEffect(() => {
        if (layoutBox && layoutBox.current && spotAspectRatio !== 0 && aspectRatio === 0) {
            const containerAspectRatio = layoutBox.current.clientWidth / layoutBox.current.clientHeight;
            if (spotAspectRatio >= containerAspectRatio) {
                changeWidth(`${spotAspectRatio * layoutBox.current.clientHeight}px`);
                changeAspectRatio(spotAspectRatio);
            } else {
                changeWidth(`${layoutBox.current.clientWidth}px`);
                changeAspectRatio(spotAspectRatio);
            }
        }
    }, [spotAspectRatio, aspectRatio, currentLayoutBoxWidth, currentLayoutBoxHeight]);

    const handleSelect = (value: SpotLayoutItem) => {
        const itemIndex = selectedSlotsList.findIndex((item) => item.id === value.slot);

        if (itemIndex !== -1) {
            const updatedList = [...selectedSlotsList];
            updatedList.splice(itemIndex, 1);
            changeSelectedSlotsList(updatedList);
        } else {
            const foundSlot = slotState.slotList[props.spot.id].results.find((s) => s.id === value.slot);
            if (foundSlot) changeSelectedSlotsList([...selectedSlotsList, foundSlot]);
        }
    };

    useEffect(() => {
        if (props.product && slotState.slotList[props.spot.id]) {
            props.product.slots.forEach((ps) => {
                const foundSlot = slotState.slotList[props.spot.id].results.find((s) => ps === s.real_id);
                if (foundSlot && !slotList.some((slot) => slot.real_id === foundSlot.real_id)) {
                    slotList.push(foundSlot);
                }
            });
            changeSelectedSlotsList(slotList);
        }
    }, [props.product, slotState.slotList[props.spot.id]]);

    useEffect(() => {
        props.selectedSlots && props.selectedSlots(selectedSlotsList);
    }, [selectedSlotsList]);

    return (
        <>
            <div
                ref={layoutBox}
                className={`${aspectRatio !== 0 ? '' : 'flex-grow-1 '}mt-4`}>
                <div
                    className='spotlayout-container card bg-light'
                    style={{ height: '60vh' }}>
                    <div
                        style={containerStyle}
                        className='spotlayout'>
                        {props.spotLayoutItems.map((i, index) => {
                            const foundSlot = slotState.slotList[props.spot.id]
                                ? slotState.slotList[props.spot.id].results.find((s) => s.id === i.slot)
                                : undefined;
                            return (
                                <Item
                                    key={index + 'SPLitem'}
                                    spotLayoutItem={i}
                                    label={
                                        slotState.slotList[props.spot.id] ? slotState.slotList[props.spot.id].results.find((s) => s.id === i.slot)?.slot_nr : ''
                                    }
                                    id={
                                        slotState.slotList[props.spot.id] ? slotState.slotList[props.spot.id].results.find((s) => s.id === i.slot)?.slot_nr : ''
                                    }
                                    spotHeight={props.spotLayout.height}
                                    spotWidth={props.spotLayout.width}
                                    selected={props.selected && foundSlot && props.selected(foundSlot)}
                                    occupied={props.occupied && props.occupied(i)}
                                    highlighted={props.highlighted && props.highlighted(i)}
                                    onClick={(value) => handleSelect(value)}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SlotAssignmentLayout;
