import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ApiQueryParams } from '../../api/BaseQueryParams';
import {
    AccessControllerLogsResponse,
    AccessControllerQueryParams,
    AccessControllerResponse,
    fetchAccessControllerLogsApi,
    fetchAccessControllersApi
} from '../../api/iot/AccessController';
import { ApiError } from '../../api/utils';
import { ReduxError } from '../store';
import { SliceStatus } from '../utils/Redux';

interface AccessControllerState {
    accessControllerList: AccessControllerResponse | null;
    accessControllerLogsList: { [accessControllerUUID: string]: AccessControllerLogsResponse } | null;
    status: SliceStatus;
    logStatus: SliceStatus;
    lastUpdate: number;
    logLastUpdate: { [accessControllerUUID: string]: number };
    error: ReduxError | null;
}

const initialState: AccessControllerState = {
    accessControllerList: null,
    accessControllerLogsList: null,
    status: SliceStatus.INIT,
    logStatus: SliceStatus.INIT,
    lastUpdate: Date.now(),
    logLastUpdate: {},
    error: null
};

export const accessControllerSlice = createSlice({
    name: 'accessControllers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccessControllers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccessControllers.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.accessControllerList === null) {
                    state.accessControllerList = { ...action.payload, results: new Array(action.payload.count) };
                    state.accessControllerList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.accessControllerList = {
                    ...action.payload,
                    results: state.accessControllerList.results
                };
                state.accessControllerList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                state.status = SliceStatus.IDLE;
                state.lastUpdate = Date.now();
            })
            .addCase(fetchAccessControllers.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchAccessControllerLogs.pending, (state) => {
                state.logStatus = SliceStatus.LOADING;
            })
            .addCase(fetchAccessControllerLogs.fulfilled, (state, action) => {
                const accessControllerUUID = action.meta.arg.params.uuid;
                const startPos = toNumber(action.meta.arg.queryParams.page_size) * (toNumber(action.meta.arg.queryParams.page) - 1);

                if (accessControllerUUID) {
                    if (state.accessControllerLogsList === null || state.accessControllerLogsList[accessControllerUUID] === undefined) {
                        state.accessControllerLogsList = {
                            [accessControllerUUID]: {
                                ...action.payload,
                                results: new Array(action.payload.count)
                            }
                        };
                    }
                    state.accessControllerLogsList[accessControllerUUID] = {
                        ...action.payload,
                        results: state.accessControllerLogsList[accessControllerUUID].results
                    };
                    state.accessControllerLogsList[accessControllerUUID].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.logStatus = SliceStatus.IDLE;
                state.logLastUpdate[accessControllerUUID] = Date.now();
                state.error = null;
            })
            .addCase(fetchAccessControllerLogs.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.logStatus = SliceStatus.ERROR;
            });
    }
});

export const fetchAccessControllers = createAsyncThunk<AccessControllerResponse, ApiQueryParams<AccessControllerQueryParams>>(
    'fetchAccessControllers',
    async (queryParams: ApiQueryParams<AccessControllerQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchAccessControllersApi(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccessControllerLogs = createAsyncThunk<
    AccessControllerLogsResponse,
    { queryParams: ApiQueryParams<AccessControllerQueryParams>; params: { uuid: string; model: string } }
>(
    'fetchAccessControllerLogs',
    async (options: { queryParams: ApiQueryParams<AccessControllerQueryParams>; params: { uuid: string; model: string } }, { rejectWithValue }) => {
        try {
            return await fetchAccessControllerLogsApi({ uuid: options.params.uuid, model: options.params.model }, options.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default accessControllerSlice.reducer;
