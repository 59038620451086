import { Logger } from '../../logging/Logger';
import { PubSubTopic } from '../PubSubSubscription';
import { PubSubEvent } from '../usePubSubEventListener';
import ContactEventHandler from './ContactEventHandler';
import TransactionEventHandler from './TransactionEventHandler';

export default class PubSubEventHandler {
    private dispatch: ({ ...args }) => void;
    constructor(dispatch: ({ ...args }) => void) {
        this.dispatch = dispatch;
    }

    handle = (event: PubSubEvent): void => {
        if (this.dispatch == null) return;
        switch (event.content.topic) {
            case PubSubTopic.TRANSACTIONS:
                return TransactionEventHandler.handle(this.dispatch, event);
            case PubSubTopic.CONTACTS:
                return ContactEventHandler.handle(this.dispatch, event);
            default:
                Logger.error(`Event received from PubSub contained a unknown/unsupported topic:{${event.content.topic}}`, {}, event);
        }
    };
}
