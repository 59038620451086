export interface LabeledValue {
    label: any;
    value: any;
}

export function parseObject(source: Record<string, any>): Record<string, any> {
    if (Array.isArray(source)) {
        const newArray = [];
        for (let i = 0; i < source.length; i++) {
            if (typeof source[i] === 'object' && source[i] !== null) {
                if ('label' in source[i] && 'value' in source[i]) {
                    newArray[i] = source[i].value;
                } else {
                    newArray[i] = parseObject(source[i]);
                }
            } else {
                newArray[i] = source[i];
            }
        }
        return newArray;
    } else if (typeof source === 'object' && source !== null) {
        const newObj: Record<string, any> = {};
        for (const key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                const field = source[key];

                if (typeof field === 'object' && field !== null) {
                    if ('label' in field && 'value' in field) {
                        newObj[key] = field.value;
                    } else {
                        newObj[key] = parseObject(field);
                    }
                } else {
                    newObj[key] = field;
                }
            }
        }
        return newObj;
    } else {
        return source;
    }
}
