import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _, { toNumber } from 'lodash';

import { DetailOptions } from '../api/BaseApi';
import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    ContactGroup,
    ContactGroupOptions,
    ContactGroupQueryParams,
    ContactGroupResponse,
    createContactGroupApi,
    deleteContactGroupApi,
    fetchContactGroupApi,
    fetchContactGroupsApi,
    searchContactGroupsApi,
    updateContactGroupApi
} from '../api/ContactGroups';
import { ApiError } from '../api/utils';
import { CreateContactGroupModel } from '../contact-groups/forms/CreateContactGroupForm';
import { ReduxError, RootState } from './store';
import { SliceStatus } from './utils/Redux';

interface ContactGroupsState {
    contactGroupsList: ContactGroupResponse | null;
    ContactGroupsChildrenList: { [contactGroupsId: number]: { children: ContactGroup[] | null } };
    searchContactGroupsList: { [searchQuery: string]: ContactGroup[] };
    status: SliceStatus;
    searchStatus: SliceStatus;
    lastUpdate: number;
    lastSearchUpdate: { [searchQuery: string]: number };
    message: string;
    error: ReduxError | null;
}

const initialState: ContactGroupsState = {
    contactGroupsList: null,
    ContactGroupsChildrenList: [{ children: null }],
    searchContactGroupsList: {},
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    lastUpdate: Date.now(),
    lastSearchUpdate: {},
    message: '',
    error: null
};

export const contactGroupSlice = createSlice({
    name: 'contactGroups',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContactGroups.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchContactGroups.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (state.contactGroupsList === null) {
                    state.contactGroupsList = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.contactGroupsList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    state.contactGroupsList.next = action.payload.next;
                    state.contactGroupsList.previous = action.payload.previous;
                    if (state.contactGroupsList.results.length !== action.payload.count) {
                        state.contactGroupsList.count = action.payload.count;
                        state.contactGroupsList.results = new Array(action.payload.count);
                    }
                    state.contactGroupsList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.contactGroupsList.results.forEach((cg) => {
                    const contactGroupId = cg.id;
                    const contactGroupHasParent = cg.parent;
                    const contactGroupParent = cg.url;
                    if (state.contactGroupsList == null) {
                        return undefined;
                    }
                    const contactGroupChild = state.contactGroupsList.results.filter((c) => c.parent == contactGroupParent);
                    if (contactGroupHasParent == null) {
                        state.ContactGroupsChildrenList = { ...state.ContactGroupsChildrenList, [contactGroupId]: { children: contactGroupChild } };
                    }
                });

                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchContactGroups.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchContactGroup.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchContactGroup.fulfilled, (state, action) => {
                const contactGroup = action.payload;
                if (state.contactGroupsList === null) {
                    state.contactGroupsList = { count: 1, next: null, previous: null, results: [] };
                    const existingContactGroup = state.contactGroupsList.results.find((cg) => cg.id === contactGroup.id);
                    if (existingContactGroup) {
                        const cgIndex = state.contactGroupsList.results.indexOf(existingContactGroup);
                        state.contactGroupsList.results.splice(cgIndex, 1, contactGroup);
                    } else state.contactGroupsList.results.push(contactGroup);
                } else if (state.contactGroupsList !== null) {
                    const existingContactGroup = state.contactGroupsList.results.find((cg) => cg !== undefined && cg.id === contactGroup.id);
                    if (existingContactGroup) {
                        const cgIndex = state.contactGroupsList.results.indexOf(existingContactGroup);
                        state.contactGroupsList.results.splice(cgIndex, 1, contactGroup);
                    } else {
                        for (let i = 0; i <= state.contactGroupsList.results.length; i++) {
                            if (state.contactGroupsList.results[i] === undefined) {
                                state.contactGroupsList.results[i] = contactGroup;
                                break;
                            } else if (i === state.contactGroupsList.results.length) {
                                state.contactGroupsList.results.push(contactGroup);
                                break;
                            }
                        }
                    }
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(createContactGroup.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(createContactGroup.fulfilled, (state, action) => {
                const contactGroup = action.payload;
                if (state.contactGroupsList == null) state.contactGroupsList = { count: 1, next: null, previous: null, results: [] };
                state.contactGroupsList.results.push(contactGroup);
                state.status = SliceStatus.IDLE;
            })
            .addCase(createContactGroup.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateContactGroup.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateContactGroup.fulfilled, (state, action) => {
                const contactGroup = action.payload;
                if (state.contactGroupsList == null) state.contactGroupsList = { count: 0, next: null, previous: null, results: [] };
                const cloneList = _.cloneDeep(state.contactGroupsList);
                const clonedSearchList = _.clone(state.searchContactGroupsList);
                const existingContactGroup = state.contactGroupsList.results.find((cg) => cg.id === contactGroup.id);
                if (existingContactGroup) {
                    const contactGroupIndex = cloneList.results.indexOf(existingContactGroup);
                    state.contactGroupsList.results.splice(contactGroupIndex, 1, contactGroup);
                    Object.keys(clonedSearchList).forEach((key) => {
                        const foundContact = clonedSearchList[key].find((c) => c.id === contactGroup.id);
                        if (foundContact) {
                            const index = clonedSearchList[key].indexOf(foundContact);
                            if (index !== -1) {
                                state.searchContactGroupsList[key].splice(index, 1, contactGroup);
                            }
                        }
                    });
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateContactGroup.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(deleteContactGroup.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteContactGroup.fulfilled, (state, action) => {
                if (action.payload === true && state.contactGroupsList) {
                    const contactGroupId = action.meta.arg;
                    const clonedSearchList = _.clone(state.searchContactGroupsList);
                    state.contactGroupsList.results = state.contactGroupsList.results.filter((cg) => cg.id !== contactGroupId);
                    if (state.searchContactGroupsList) {
                        Object.keys(clonedSearchList).forEach((key) => {
                            clonedSearchList[key] = clonedSearchList[key].filter((c) => c.id !== contactGroupId);
                            state.searchContactGroupsList[key] = clonedSearchList[key];
                        });
                    }
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteContactGroup.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchContactGroups.pending, (state) => {
                state.searchStatus = SliceStatus.LOADING;
            })
            .addCase(searchContactGroups.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                if (searchQuery === undefined || searchQuery === null || Array.isArray(searchQuery)) {
                    const searchContactGroups: { [searchQuery: string]: ContactGroup[] } = {};
                    const lastSearchUpdate: { [searchQuery: string]: number } = {};
                    action.payload.forEach((cg) => {
                        if (searchContactGroups[cg.name] === undefined) searchContactGroups[cg.name] = [];
                        searchContactGroups[cg.name].push(cg);
                        lastSearchUpdate[cg.name] = Date.now();
                    });
                    if (Array.isArray(searchQuery)) {
                        searchQuery.forEach((s) => {
                            state.searchContactGroupsList[s] = searchContactGroups[s] !== undefined ? searchContactGroups[s] : [];
                            state.lastSearchUpdate[s] = Date.now();
                        });
                    } else {
                        state.searchContactGroupsList = searchContactGroups;
                        state.lastSearchUpdate = lastSearchUpdate;
                    }
                } else {
                    state.searchContactGroupsList[searchQuery.toLowerCase()] = action.payload;
                    state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                }
                state.searchStatus = SliceStatus.IDLE;
            })
            .addCase(searchContactGroups.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.searchStatus = SliceStatus.ERROR;
            });
    }
});

export const fetchContactGroups = createAsyncThunk<ContactGroupResponse, ApiQueryParams<ContactGroupQueryParams>>(
    'fetchContactGroups',
    async (queryParams: ApiQueryParams<ContactGroupQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchContactGroupsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchContactGroup = createAsyncThunk<ContactGroup, DetailOptions>('fetchContactGroup', async (options: DetailOptions, { rejectWithValue }) => {
    try {
        return await fetchContactGroupApi(options);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const createContactGroup = createAsyncThunk<ContactGroup, CreateContactGroupModel>('createContactGroup', async (contactGroup, { rejectWithValue }) => {
    try {
        return await createContactGroupApi(contactGroup);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateContactGroup = createAsyncThunk<ContactGroup, ContactGroupOptions>('updateContactGroup', async (contactGroupId, { rejectWithValue }) => {
    try {
        return await updateContactGroupApi(contactGroupId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteContactGroup = createAsyncThunk<boolean, number>('deleteContactGroup', async (contactGroupId, { rejectWithValue }) => {
    try {
        const res = await deleteContactGroupApi(contactGroupId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete contact group with id: ${contactGroupId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchContactGroups = createAsyncThunk<ContactGroup[], ApiQueryParams<ContactGroupQueryParams>>(
    'searchContactGroups',
    async (options: ApiQueryParams<ContactGroupQueryParams>, { rejectWithValue }) => {
        try {
            return await searchContactGroupsApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const selectContactGroups = (state: RootState) => state.contactGroups.contactGroupsList;

export default contactGroupSlice.reducer;
