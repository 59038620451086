import { ApiViewSet, apiList } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';

export enum WidgetType {
    LowStockReport = 'LowStockReport',
    StockWarningWidget = 'StockWarningWidget',
    LowStockView = 'LowStockView'
}

export interface WidgetTemplate {
    id: number;
    name: string;
    description: string;
    type: WidgetType;
    settings_params: {
        data: {
            name: string;
            type: string;
            options?: any[];
        }[];
    };
    width: number;
    height: number;
}

export enum WidgetQueryParams {
    ACCOUNT = 'account'
}

const widgetTemplateViewSet: ApiViewSet = {
    baseName: 'widget-templates'
};

export async function fetchWidgetTemplatesApi(queryParams: ApiQueryParams<WidgetQueryParams>): Promise<WidgetTemplate[]> {
    return apiList<WidgetTemplate, WidgetQueryParams>(widgetTemplateViewSet, queryParams);
}
