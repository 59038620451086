import { useEffect, useState } from 'react';

import { Attribute } from '../../../api/catalogue/Attributes';
import { AttributeType } from '../../../common/AttributeType';
import ShopCategoryBadge from '../../../components/badges/ShopCategoryBadge';
import Spinner from '../../../components/loading/Spinner';
import ObjectField from '../../../components/modals/ObjectField';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAttributeById } from '../../../store/attributeSlice';

export default function AttributeDetail(props: { attribute?: Attribute }) {
    const dispatch = useAppDispatch();
    const attributeState = useAppSelector((state) => state.attributes);
    const [attribute, changeAttribute] = useState<Attribute | undefined>();

    useEffect(() => {
        changeAttribute(props.attribute);
        if (props.attribute && props.attribute.id !== attribute?.id) dispatch(fetchAttributeById(props.attribute.id.toString()));
    }, [props.attribute]);

    useEffect(() => {
        changeAttribute(attributeState.attributeList?.find((a) => a.id === props.attribute?.id));
    }, [attribute, attributeState.attributeList]);

    if (attribute === undefined) return <Spinner />;

    return (
        <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column w-100 h-100'>
                {attribute.category && (
                    <ObjectField
                        value={<ShopCategoryBadge categoryId={attribute.category} />}
                        fieldName='Category'
                    />
                )}
                <ObjectField
                    value={attribute.values.join(',')}
                    fieldName='Values'
                />
                <ObjectField
                    value={AttributeType.getByName(attribute.type)?.displayedValue}
                    fieldName='Type'
                />
            </div>
        </div>
    );
}
