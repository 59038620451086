import React from 'react';
import { IconType } from 'react-icons/lib';

import { ClassType } from '../../common/BootstrapValues';

interface DropdownButtonProps {
    buttonType?: string;
    center?: boolean;
    dropup?: boolean;
    arrowRight?: boolean;

    title: React.ReactNode;
    options: DropdownOptionProps[];
}

export default function DropdownButton(props: DropdownButtonProps) {
    const buttonClassName = 'btn btn-' + (props.buttonType ? props.buttonType : 'light') + ' dropdown-toggle';

    const dropClassName = props.dropup === true ? 'dropup' : 'dropdown';
    const finalClassName = props.center === true ? dropClassName + '-center ' + dropClassName : dropClassName;
    const dropMenuClassName = props.arrowRight === true ? 'dropdown-menu right' : 'dropdown-menu';

    return (
        <div className={finalClassName}>
            <button
                type='button'
                className={buttonClassName}
                id='dropdownMenuButton'
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                {props.title}
            </button>
            <ul
                className={dropMenuClassName}
                aria-labelledby='dropdownMenuButton'>
                {props.options.map((option, i) => (
                    <DropdownOption
                        key={i}
                        {...option}
                    />
                ))}
            </ul>
        </div>
    );
}

interface DropdownOptionProps {
    disabled?: boolean;
    type?: ClassType;
    onClick?: () => void;
    icon?: IconType;
    title: React.ReactNode;
}

export function DropdownOption(props: DropdownOptionProps) {
    return (
        <li>
            <a
                className={`dropdown-item ${props.disabled === true && 'disabled'} ${props.type}`}
                onClick={props.onClick}>
                <div className='d-flex flex-row align-items-center'>
                    {props.icon && <props.icon />}
                    <span className='ms-2'>{props.title}</span>
                </div>
            </a>
        </li>
    );
}
