import { ApiViewSet, DetailOptions, apiDetail, apiList } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';

export interface Shop {
    id: number;
    url: string;
    account: number;
    name: string;
    products: string;
}

export enum ShopsQueryParams {
    PRODUCT = 'product',
    TERMINAL = 'terminal',
    ACCOUNT = 'account'
}

const shopViewSet: ApiViewSet = {
    baseName: 'shops'
};

export function fetchShopsApi(queryParams?: ApiQueryParams<ShopsQueryParams> | null): Promise<Shop[]> {
    return apiList<Shop, ShopsQueryParams>(shopViewSet, queryParams);
}

export function fetchShopApi(options: DetailOptions): Promise<Shop> {
    return apiDetail<Shop>(shopViewSet, options);
}
