import { FormattedMessage } from 'react-intl';

const SPOTS = {
    SPOT: (
        <FormattedMessage
            id='TransactionInput.Create.Transaction.Spot'
            description={'Label for transaction spot'}
            defaultMessage='Spot'
        />
    )
};

export default SPOTS;
