import { TransactionEvent } from '../common/TransactionEvents';
import { TransactionStatus, TransactionStatusName, TransactionStatusValue } from '../common/TransactionStatus';
import { TransactionStatusChangeEventValue } from '../common/TransactionStatusChangeEvent';
import { ApiViewSet, DetailOptions, apiDetail, apiPagination } from './BaseApi';
import { ApiQueryParams } from './BaseQueryParams';
import { ProductInstance } from './catalogue/ProductInstances';
import { ApiError, PaginationResponse, patchApi, postApi } from './utils';

export interface TransactionContact {
    id: string;
    first_name?: string;
    last_name?: string;
    mobile: string;
    email: string;
    language: string;
}

export interface CreateTransactionModel {
    account_id: number;
    sender_id?: number;
    sender_group_id?: number;
    receiver_id?: number;
    receiver_group_id?: number;
    tt_number: string;
    spot_id?: number;
    slot_id?: number;
    type?: TransactionType;
    shipping_notes?: string;
    status?: TransactionStatusValue;

    //Products
    product_ids?: number[];
    product_instance_ids?: Array<number | string>;

    //Scheduling
    notification_time_for_scheduled_transaction?: Date;
    expected_dropoff_time?: Date;
    expected_pickup_time?: Date;
    close_transaction_at_expected_pickup_time?: boolean;

    //Notifications
    notifications_config?: unknown;

    //Advanced
    customer_ref?: string;
    callback_url?: string;
    action_authentication_methods?: Record<string, unknown>;
}

export interface TransactionContactGroup {
    id: number | string;
    name: string;
}

export enum TransactionAuthenticationMethods {
    PIN = 'pin_code',
    QR = 'qr_code',
    MICROSOFT = 'microsoft',
    ITSME = 'itsme'
}

export enum TransactionType {
    PUDO = 'pudo',
    LENDING = 'lending',
    VENDING = 'vending',
    RETURN = 'return'
}

export interface TransactionSlotReservation {
    item_count: number;
    created_date: string;
    end_date: string;
    notification_time_for_scheduled_transaction: string;
    expected_dropoff_time: string;
    expected_pickup_time: string;
    close_transaction_at_expected_pickup_time: boolean;
}

export interface Transaction {
    id: string;
    real_id: number;
    url: string;
    parent: string;
    sender: TransactionContact | null;
    sender_group?: TransactionContactGroup | null;
    receiver?: TransactionContact | null;
    receiver_group?: TransactionContactGroup | null;
    spot_id: string;
    spot_url: string;
    slot_nr?: string;
    slot_id: string;
    tt_number: string;
    shipping_notes: string;
    status: string;
    status_update: string;
    type: TransactionType;
    slot_reservation: TransactionSlotReservation;
    notifications_config: Record<string, unknown>;
    product_instances_url: string;
    product_instances: ProductInstance[];
    created_date: string;
    completed_date: string;
    dropoff_code: string;
    dropoff_qr: string;
    dropoff_at: null | Date;
    pickup_code: string;
    pickup_qr: string;
    pickup_at: null | Date;
    remove_parcel_code: string;
    remove_parcel_qr: string;
    settings_dropoff_in_progress_timeout_in_sec_value: string;
    settings_pickup_in_progress_timeout_in_sec_value: string;
    settings_remove_in_progress_timeout_in_sec_value: string;

    transaction_events?: TransactionEvent[];
}

export type TransactionResponse = PaginationResponse<Transaction>;

export interface BulkTransactionStatusUpdateModel {
    account_id: number;
    initial_state: TransactionStatusName;
    event: TransactionStatusChangeEventValue;
    notifications: any;
    transaction_ids: number[];
}

export enum TransactionsQueryParams {
    ACCOUNT = 'account',
    SLOT = 'slot',
    SPOT = 'spot',
    SENDER = 'sender',
    RECEIVER = 'receiver',
    SENDER_GROUP = 'sender_group',
    RECEIVER_GROUP = 'receiver_group',
    STATUS = 'status',
    CREATED_DATE_AFTER = 'created_date_after',
    CREATED_DATE_BEFORE = 'created_date_before',
    PAGE = 'page',
    PAGE_SIZE = 'page_size',
    TRANSACTION_TYPE = 'transaction_type',
    ORDER_BY = 'order_by',
    TRACKING_NUMBER = 'tracking_number',
    TRANSACTION_STATUS = 'transaction_status'
}

export interface MoveTransactionOptions {
    transactionId: string;
    body: {
        new_spot_id: string;
        new_slot_id?: string;
    };
}
export interface CancelTransactionQueryParams {
    id: string;
}

export interface UpdateBulkTransactionReceiverOptions {
    account_id: number;
    receiver_id: number;
    transaction_ids: number[];
}

const transactionViewSet: ApiViewSet = {
    baseName: 'transactions'
};

export async function fetchTransactionsApi(queryParams?: ApiQueryParams<TransactionsQueryParams> | null): Promise<TransactionResponse> {
    return apiPagination<TransactionResponse, TransactionsQueryParams>(transactionViewSet, queryParams);
}

export async function fetchTransactionApi(options: DetailOptions): Promise<Transaction> {
    return apiDetail<Transaction>(transactionViewSet, options);
}

export async function addTransactionApi(transaction: CreateTransactionModel): Promise<Transaction> {
    const transactionAddUrl = `/transactions/`;
    const response = await postApi(transactionAddUrl, transaction);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error adding transaction');
        }
        throw new ApiError('Error adding transaction', json);
    }
    return await response.json();
}

export async function cancelTransactionApi(options: CancelTransactionQueryParams): Promise<Transaction> {
    let transactionUrl = '/transactions/';
    if (options && options.id) {
        transactionUrl += options.id + '/';
    }
    const body = { status: TransactionStatus.CANCELLED };
    const response = await patchApi(transactionUrl, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error cancelling this transaction');
        }
        throw new ApiError('Error cancelling this transaction');
    }
    return await response.json();
}

export async function moveTransactionApi(options: MoveTransactionOptions): Promise<Transaction> {
    const response = await postApi(`/transactions/${options.transactionId}/change_spot_slot/`, options.body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error moving transaction');
        }
        throw new ApiError('Error moving transaction');
    }
    return await response.json();
}

export async function bulkUpdateTransactionStatusApi(body: BulkTransactionStatusUpdateModel): Promise<void> {
    const response = await postApi(`/transactions/bulk/status_update/`, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating transactions');
        }
        throw new ApiError('Error updating transactions');
    }
    return await response.json();
}

export async function bulkUpdateTransactionReceiverApi(body: UpdateBulkTransactionReceiverOptions): Promise<void> {
    const response = await postApi(`/transactions/bulk/receiver_update/`, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating bulk transaction');
        }
        throw new ApiError('Error creating bulk transaction', json);
    }
    return await response.json();
}

export function isBeforeDropoff(statusString: string): boolean {
    const status = TransactionStatus.getByString(statusString);
    return status === TransactionStatus.NEW || status === TransactionStatus.SCHEDULED || status === TransactionStatus.READY_FOR_DROPOFF;
}
export function isBeforePickup(status: TransactionStatus): boolean {
    return (
        status === TransactionStatus.NEW ||
        status === TransactionStatus.SCHEDULED ||
        status === TransactionStatus.READY_FOR_DROPOFF ||
        status === TransactionStatus.DROPOFF_DONE ||
        status === TransactionStatus.DROPOFF_IN_PROGRESS ||
        status === TransactionStatus.READY_FOR_PICKUP ||
        status === TransactionStatus.CANCELLED
    );
}
export function getPrevieusStatuses(status: TransactionStatus): TransactionStatus[] {
    const result: TransactionStatus[] = [];
    if (status !== TransactionStatus.NEW) {
        result.push(TransactionStatus.NEW);
        if (status !== TransactionStatus.SCHEDULED && status !== TransactionStatus.READY_FOR_DROPOFF) {
            result.push(TransactionStatus.READY_FOR_DROPOFF);
            if (status !== TransactionStatus.DROPOFF_IN_PROGRESS) {
                result.push(TransactionStatus.DROPOFF_IN_PROGRESS);
                if (status !== TransactionStatus.DROPOFF_DONE) {
                    result.push(TransactionStatus.DROPOFF_DONE);
                    if (status !== TransactionStatus.READY_FOR_PICKUP && status !== TransactionStatus.CANCELLED) {
                        result.push(TransactionStatus.READY_FOR_PICKUP);
                        if (status !== TransactionStatus.PICKUP_IN_PROGRESS && status !== TransactionStatus.REMOVE_PARCEL_IN_PROGRESS) {
                            result.push(TransactionStatus.PICKUP_IN_PROGRESS);
                            if (status !== TransactionStatus.PICKUP_DONE) {
                                result.push(TransactionStatus.PICKUP_DONE);
                            }
                        }
                    }
                }
            }
        }
    }
    return result;
}
