export enum indexedDbStores {
    WEB_LOGS = 'web_logs'
}

const DB_NAME = 'main';
const DB_VERSION = 5;
const request = indexedDB?.open(DB_NAME, DB_VERSION);
let indexedDbConnection: IDBDatabase | undefined = undefined;

request.onerror = (ev) => {
    console.error(ev);
    console.error('Failed connection to indexed db');
};

request.onsuccess = () => {
    indexedDbConnection = request.result;
};

request.onupgradeneeded = (event: any) => {
    const db = event.target.result;
    if (!event.target.result.objectStoreNames.contains(indexedDbStores.WEB_LOGS)) {
        db.createObjectStore(indexedDbStores.WEB_LOGS, { keyPath: 'id', autoIncrement: true });
    }
};

export async function getConnection(): Promise<IDBDatabase> {
    return new Promise((resolve) => {
        if (indexedDbConnection === undefined) {
            setTimeout(() => getConnection().then(resolve), 1000);
        } else {
            resolve(indexedDbConnection);
        }
    });
}

export async function getStoreConnection(
    storeName: string,
    mode?: IDBTransactionMode | undefined,
    options?: IDBTransactionOptions | undefined
): Promise<IDBObjectStore> {
    return new Promise((resolve) => {
        if (indexedDbConnection === undefined) {
            setTimeout(() => getStoreConnection(storeName, mode, options).then(resolve), 1000);
        } else {
            resolve(indexedDbConnection.transaction(storeName, mode, options).objectStore(storeName));
        }
    });
}
