import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ApiQueryParams } from '../api/BaseQueryParams';
import {
    CreateProductParams,
    Product,
    ProductResponse,
    ProductSlotsModel,
    ProductsQueryParams,
    UpdateProductParams,
    createProductApi,
    deleteProductApi,
    fetchProductApi,
    fetchProductsApi,
    searchProductsApi,
    updateProductApi,
    updateSlotsOfProductApi,
    uploadProductCSVApi,
    uploadProductImagesApi
} from '../api/catalogue/Products';
import { ApiError } from '../api/utils';
import { getAllValuesFromStore } from '../utils/KeyValueStores';
import { ReduxError } from './store';
import { SliceStatus } from './utils/Redux';

interface ProductsState {
    productList: ProductResponse | null;
    searchProductsList: { [searchQuery: string]: ProductResponse } | null;
    status: SliceStatus;
    searchStatus: SliceStatus;
    lastUpdate: number;
    lastSearchUpdate: { [searchQuery: string]: number };
    uploadStatus: SliceStatus;
    uploadMessage: string;
    message: string;
    error: ReduxError | null;
}

const initialState: ProductsState = {
    productList: null,
    searchProductsList: null,
    status: SliceStatus.INIT,
    searchStatus: SliceStatus.INIT,
    lastUpdate: Date.now(),
    lastSearchUpdate: {},
    uploadStatus: SliceStatus.INIT,
    uploadMessage: '',
    message: '',
    error: null
};

export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                if (!action.meta.arg.page_size && !action.meta.arg.page) {
                    state.productList = action.payload;
                } else {
                    const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                    if (state.productList == null) {
                        state.productList = {
                            count: action.payload.count,
                            next: action.payload.next,
                            previous: action.payload.previous,
                            results: new Array(action.payload.count)
                        };
                        state.productList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        state.productList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }

                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchProductById.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductById.fulfilled, (state, action) => {
                const product = action.payload;
                if (state.productList !== null) {
                    const existingProduct = state.productList.results.find((s) => s !== undefined && product.id == s.id);
                    const existingSearchProduct = state.searchProductsList && getAllValuesFromStore(state.searchProductsList).find((s) => s.id === product.id);
                    if (existingProduct) {
                        const productIndex = state.productList.results.indexOf(existingProduct);
                        state.productList.results.splice(productIndex, 1, product);
                    }
                    if (existingSearchProduct && state.searchProductsList) {
                        Object.keys(state.searchProductsList).map((k) => {
                            if (
                                state.searchProductsList &&
                                state.searchProductsList[k].results.find((s) => s !== undefined && s !== null && s.id === existingSearchProduct.id)
                            ) {
                                const index = state.searchProductsList[k].results.indexOf(existingSearchProduct);
                                state.searchProductsList[k].results.splice(index, 1, product);
                            }
                        });
                    }
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProductById.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(fetchProductByUrl.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductByUrl.fulfilled, (state, action) => {
                const product = action.payload;
                if (state.productList !== null) {
                    const existingProduct = state.productList.results.find((s) => product.id == s.id);
                    if (existingProduct) {
                        const productIndex = state.productList.results.indexOf(existingProduct);
                        state.productList.results.splice(productIndex, 1, product);
                    }
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProductByUrl.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(updateSlotsOfProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateSlotsOfProduct.fulfilled, (state, action) => {
                const productId = action.meta.arg.id;
                const newProduct = action.payload;
                if (state.productList !== null) {
                    const product = state.productList.results.find((p) => p.id === parseInt(productId));
                    if (product) {
                        const productIndex = state.productList.results.indexOf(product);
                        state.productList.results.splice(productIndex, 1, newProduct);
                    }
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateSlotsOfProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(createProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                const product = action.payload;
                if (state.productList == null) state.productList = { count: 0, next: null, previous: null, results: [] };
                state.productList.results.push(product);
                state.status = SliceStatus.IDLE;
            })
            .addCase(createProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(updateProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                const product = action.payload;
                if (state.productList === null) state.productList = { count: 0, next: null, previous: null, results: [] };
                const existingProduct = state.productList.results.find((p) => p !== undefined && p.id === product.id);
                const existingSearchProduct = state.searchProductsList && getAllValuesFromStore(state.searchProductsList).find((s) => s.id === product.id);
                if (existingProduct) {
                    const productIndex = state.productList.results.indexOf(existingProduct);
                    state.productList.results.splice(productIndex, 1, product);
                }
                if (existingSearchProduct && state.searchProductsList) {
                    Object.keys(state.searchProductsList).map((k) => {
                        if (state.searchProductsList && state.searchProductsList[k].results.find((s) => s.id === existingSearchProduct.id)) {
                            const index = state.searchProductsList[k].results.indexOf(existingSearchProduct);
                            state.searchProductsList[k].results.splice(index, 1, product);
                        }
                    });
                }
                state.lastUpdate = Date.now();
                state.status = SliceStatus.IDLE;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
                if (action.error.message) state.message = action.error.message;
            })
            .addCase(uploadProductImages.pending, (state) => {
                state.uploadStatus = SliceStatus.LOADING;
            })
            .addCase(uploadProductImages.fulfilled, (state) => {
                state.uploadStatus = SliceStatus.IDLE;
                state.uploadMessage = 'Successfully uploaded images';
            })
            .addCase(uploadProductImages.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.uploadStatus = SliceStatus.ERROR;
                if (action.error.message) state.uploadMessage = action.error.message;
            })
            .addCase(uploadProductCSV.pending, (state) => {
                state.uploadStatus = SliceStatus.LOADING;
            })
            .addCase(uploadProductCSV.fulfilled, (state) => {
                state.uploadStatus = SliceStatus.IDLE;
                state.uploadMessage = 'Successfully uploaded CSV';
            })
            .addCase(uploadProductCSV.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.uploadStatus = SliceStatus.ERROR;
                if (action.error.message) state.uploadMessage = action.error.message;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                if (action.payload === true && state.productList) {
                    const productId = action.meta.arg;
                    const foundProduct = state.productList.results.find((p) => p !== undefined && p !== null && p.id === productId);
                    if (foundProduct) {
                        const index = state.productList.results.indexOf(foundProduct);
                        state.productList.results.splice(index, 1);
                    }
                    if (state.searchProductsList) {
                        Object.keys(state.searchProductsList).forEach((key) => {
                            if (state.searchProductsList && state.searchProductsList[key] !== null) {
                                const foundProduct = state.searchProductsList[key].results.find((p) => p !== undefined && p !== null && p.id === productId);
                                if (foundProduct) {
                                    const index = state.searchProductsList[key].results.indexOf(foundProduct);
                                    state.searchProductsList[key].results.splice(index, 1);
                                }
                            }
                        });
                    }
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.status = SliceStatus.ERROR;
            })
            .addCase(searchProducts.pending, (state) => {
                state.searchStatus = SliceStatus.LOADING;
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                const searchQuery = action.meta.arg.search;
                const startPos = toNumber(action.meta.arg.page_size) * (toNumber(action.meta.arg.page) - 1);
                if (searchQuery === undefined || searchQuery === null || Array.isArray(searchQuery)) {
                    const searchProducts: { [searchQuery: string]: Product[] } = {};
                    const lastSearchUpdate: { [searchQuery: string]: number } = {};
                    action.payload.results.forEach((product) => {
                        if (searchProducts[product.name] === undefined) searchProducts[product.name] = [];
                        searchProducts[product.name].push(product);
                        lastSearchUpdate[product.name] = Date.now();
                    });
                    if (Array.isArray(searchQuery)) {
                        searchQuery.forEach((s) => {
                            if (state.searchProductsList !== null) {
                                state.searchProductsList[s] =
                                    searchProducts[s] !== undefined
                                        ? { ...action.payload, results: searchProducts[s] }
                                        : { count: 0, next: null, previous: null, results: [] };
                                state.lastSearchUpdate[s] = Date.now();
                            }
                        });
                    } else {
                        state.searchProductsList = { ['unknown']: action.payload };
                        state.lastSearchUpdate = lastSearchUpdate;
                    }
                } else {
                    if (state.searchProductsList && state.searchProductsList[searchQuery.toLowerCase()]) {
                        state.searchProductsList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    } else {
                        state.searchProductsList = {
                            ...state.searchProductsList,
                            [searchQuery.toLowerCase()]: { ...action.payload, results: new Array(action.payload.count) }
                        };
                        state.searchProductsList[searchQuery.toLowerCase()].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                        state.lastSearchUpdate[searchQuery.toLowerCase()] = Date.now();
                    }
                }
                state.searchStatus = SliceStatus.IDLE;
            })
            .addCase(searchProducts.rejected, (state, action) => {
                if (action.payload) state.error = action.payload as ReduxError;
                state.searchStatus = SliceStatus.ERROR;
            });
    }
});

export const fetchProducts = createAsyncThunk<ProductResponse, ApiQueryParams<ProductsQueryParams>>(
    'fetchProducts',
    async (queryParams: ApiQueryParams<ProductsQueryParams>, { rejectWithValue }) => {
        try {
            return await fetchProductsApi(queryParams ? queryParams : null);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchProductById = createAsyncThunk<Product, string>('fetchProductById', async (productId, { rejectWithValue }) => {
    try {
        return await fetchProductApi({ id: productId });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchProductByUrl = createAsyncThunk<Product, string>('fetchProductByUrl', async (productUrl, { rejectWithValue }) => {
    try {
        return await fetchProductApi({ url: productUrl });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateSlotsOfProduct = createAsyncThunk<Product, ProductSlotsModel>('updateSlotsOfProduct', async (slots, { rejectWithValue }) => {
    try {
        return await updateSlotsOfProductApi(slots);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const createProduct = createAsyncThunk<Product, CreateProductParams>('CreateProduct', async (body, { rejectWithValue }) => {
    try {
        return await createProductApi(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const updateProduct = createAsyncThunk<Product, UpdateProductParams>('updateProduct', async (productId, { rejectWithValue }) => {
    try {
        return await updateProductApi(productId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteProduct = createAsyncThunk<boolean, number>('deleteProduct', async (productId, { rejectWithValue }) => {
    try {
        const res = await deleteProductApi(productId);
        if (res === true) {
            return res;
        } else return rejectWithValue({ json: `Failed to delete product with id: ${productId}` });
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const uploadProductImages = createAsyncThunk<{ data: string }, FormData>('uploadProductImage', async (body, { rejectWithValue }) => {
    try {
        return await uploadProductImagesApi(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const uploadProductCSV = createAsyncThunk<{ data: string }, FormData>('uploadProductCSV', async (body, { rejectWithValue }) => {
    try {
        return await uploadProductCSVApi(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const searchProducts = createAsyncThunk<ProductResponse, ApiQueryParams<ProductsQueryParams>>(
    'searchProducts',
    async (options: ApiQueryParams<ProductsQueryParams>, { rejectWithValue }) => {
        try {
            return await searchProductsApi(options);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export default productSlice.reducer;
