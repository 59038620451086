import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { resetUserPassword } from '../../../api/Password';
import logo from '../../../assets/images/logo60 × 60.png';
import { ClassType } from '../../../common/BootstrapValues';
import PasswordInput from '../../../components/forms/passwordInput/PasswordInput';
import { AuthenticationManager } from '../../../utils/AuthenticationManager';
import { ToastUtil } from '../../../utils/toasts/Toasts';

const resetPassword = () => {
    const [passwordValid, changePasswordValid] = useState(false);
    const [confirmPasswordValid, changeConfirmPasswordValid] = useState(false);
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        if (!urlParams.get('signature')) {
            navigate('/');
        }
    }, []);
    const userID = urlParams.get('user_id');
    const timestamp = urlParams.get('timestamp');
    const signature = urlParams.get('signature');

    const [formResult, changeFormResult] = useState({
        user_id: userID,
        timestamp: timestamp,
        signature: signature,
        password: '',
        password_confirm: ''
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resetUserPassword(formResult)
            .then(() => {
                ToastUtil.success('Reset success', resetPasswordSuccess);
                setTimeout(() => AuthenticationManager.getInstance().logout(), 3000);
            })
            .catch(() => toast.error(...ToastUtil.generateToastConfig('Reset failed', resetPasswordError, ClassType.DANGER)));
    };

    return (
        <>
            <div className='container d-flex flex-column justify-content-center align-items-center'>
                <div className='d-flex justify-content-center w-100'>
                    <div className='card w-50'>
                        <div className='card-header'>
                            <img src={logo} />
                            <h5>
                                <FormattedMessage
                                    id='resetPasswordForm.title'
                                    description='The title on the reset password page.'
                                    defaultMessage='Reset password'
                                />
                            </h5>
                        </div>
                        <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                            <form
                                onSubmit={handleSubmit}
                                className='d-flex flex-column w-100'>
                                <PasswordInput
                                    required
                                    strength
                                    label={
                                        <FormattedMessage
                                            id='resetPasswordForm.password.label'
                                            description='The label for the password input on the reset password page.'
                                            defaultMessage='Password'
                                        />
                                    }
                                    value={formResult.password}
                                    onChange={(value) => changeFormResult({ ...formResult, password: value })}
                                    isValidCallback={(value) => {
                                        changePasswordValid(value);
                                    }}
                                />
                                <PasswordInput
                                    toRepeat={formResult.password}
                                    required
                                    label={
                                        <FormattedMessage
                                            id='resetPasswordForm.confirmPassword.label'
                                            description='The label for the confirm password input on the reset password page.'
                                            defaultMessage='Confirm password'
                                        />
                                    }
                                    value={formResult.password_confirm}
                                    onChange={(value) => changeFormResult({ ...formResult, password_confirm: value })}
                                    isValidCallback={(value) => {
                                        changeConfirmPasswordValid(value);
                                    }}
                                />
                                <button
                                    type='submit'
                                    disabled={!passwordValid || !confirmPasswordValid}
                                    className='btn btn-primary'>
                                    <FormattedMessage
                                        id='resetPasswordForm.submitButton'
                                        description='The title in the submit button on the reset password page.'
                                        defaultMessage='Submit'
                                    />
                                </button>
                            </form>

                            <hr className='horizontal dark mt-0' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default resetPassword;

const resetPasswordSuccess = (
    <FormattedMessage
        id='resetPasswordForm.Reset.Success'
        description={'Message displayed when resetting password successfully'}
        defaultMessage={'Password reset successfully, you will now be logged out'}
    />
);

const resetPasswordError = (
    <FormattedMessage
        id='resetPasswordForm.Reset.Error'
        description={'Message displayed when resetting password failed'}
        defaultMessage={'Password reset failed, please try again'}
    />
);
