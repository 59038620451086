import React, { useEffect, useState } from 'react';

import { getInputClass } from '../../../utils/FormUtils';

interface NumberInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: number) => void;
    onFocusChange?: () => void;
    value?: string | number;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    disabled?: boolean;
    submitted: boolean;
    min?: number;
    max?: number;
    id?: string;
    minLength?: number;
    maxLength?: number;
}

export default function NumberInput(props: NumberInputProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(false);
    const [value, setValue] = useState<string | number | undefined>();

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === '' || props.value === undefined)) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        if ((props.minLength && !props.value) || (props.minLength && props.value && props.value.toString().length < props.minLength)) {
            check = false;
        }
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);
    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <input
                    className={getInputClass(touched, valid, props.value?.toString())}
                    autoFocus={props.autoFocus}
                    id={props.id}
                    value={value ? value : props.value}
                    min={props.min}
                    max={props.max}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    type='number'
                    step='.01'
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        const numberValue = parseFloat(event.target.value);
                        if (isNaN(numberValue)) {
                            props.isValidCallback && props.isValidCallback(false);
                        }
                        if (!isNaN(numberValue) || event.target.value === '') {
                            if (props.onChange) {
                                setValue(numberValue);
                                props.onChange(numberValue);
                                if (props.min !== undefined) {
                                    if (numberValue < props.min) {
                                        props.onChange(props.min);
                                    }
                                }
                                if (props.max !== undefined) {
                                    if (numberValue > props.max) {
                                        props.onChange(props.max);
                                    }
                                }
                                if (props.maxLength !== undefined) {
                                    if (numberValue.toString().length > props.maxLength) {
                                        const stringValue = numberValue.toString();
                                        setValue(parseFloat(stringValue.slice(0, props.maxLength)));
                                        props.onChange(parseFloat(stringValue.slice(0, props.maxLength)));
                                    }
                                }
                            }
                        }
                        isValid();
                    }}
                    disabled={props.disabled}
                />

                {valid === false && touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage}</small>
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
