import { useMemo } from 'react';
import { Column } from 'react-table';

import { AccountMembership } from '../../../api/User';
import Table from '../../../components/tables/Table';
import AccountsListColumns from './AccountsListColumns';
import AccountsListElement from './AccountsListElement';

export interface AccountsListProps {
    accounts?: AccountMembership[];
}

const AccountsList = (props: AccountsListProps) => {
    const columns: Column<AccountMembership>[] = useMemo(() => AccountsListColumns, []);

    const data = useMemo(() => {
        if (props.accounts) {
            return props.accounts;
        } else return [];
    }, [props.accounts]);

    return (
        <>
            <Table
                columns={columns}
                data={data}
                onSelectElement={() => undefined}
                RowElement={AccountsListElement}
            />
        </>
    );
};

export default AccountsList;
