import { ReduxError } from '../store';

export function GetError(err: ReduxError | null) {
    const errorMessage = [];
    if (err === null) return 'Unknown error';
    for (const key in err.json) {
        if (err.json.hasOwnProperty.call(err.json, key)) {
            errorMessage.push(`${key} : ${err.json[key]}`);
        }
    }
    return errorMessage.map((e) => (
        <div key={e}>
            <strong>{e}</strong>
        </div>
    ));
}
