import React, { useEffect, useState } from 'react';

import { getInputClass } from '../../../utils/FormUtils';

interface TextAreaProps {
    id?: string;
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    submitted: boolean;
}

export default function TextArea(props: TextAreaProps) {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(false);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === '' || props.value === undefined)) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <>
            <div className='form-group'>
                <label>
                    {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
                <textarea
                    className={getInputClass(touched, valid, props.value)}
                    autoFocus={props.autoFocus}
                    id={props.id ? props.id : props.label?.toString()}
                    value={props.value}
                    onFocus={() => {
                        changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value);
                        isValid();
                    }}
                />
                {valid === false && touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage}</small>
                    </span>
                ) : (
                    <></>
                )}
                {!!props.helpText && <span className='text-secondary text-xs'>{props.helpText}</span>}
            </div>
        </>
    );
}
