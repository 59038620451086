import { ApiViewSet, apiPagination } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';
import { ApiError, PaginationResponse, deleteApi, postApi, putApi } from '../utils';
import { ProductAttribute } from './Products';

export type ProductAttributeResponse = PaginationResponse<ProductAttribute>;

export enum ProductAttributeQueryParams {
    ACCOUNT = 'account',
    PAGE = 'page',
    PAGE_SIZE = 'page_size'
}

export interface ProductAttributeBody {
    account: number;
    product: number;
    attribute: number | undefined;
    value: string | undefined;
}

const productAttributesViewset: ApiViewSet = {
    baseName: 'product-attributes'
};

export async function fetchProductAttributesApi(queryParams?: ApiQueryParams<ProductAttributeQueryParams>): Promise<ProductAttributeResponse> {
    return await apiPagination<ProductAttributeResponse, ProductAttributeQueryParams>(productAttributesViewset, queryParams);
}

export async function createProductAttributesApi(body: ProductAttributeBody): Promise<ProductAttribute> {
    const url = '/product-attributes/';
    const response = await postApi(url, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error creating product attribute');
        }
        throw new ApiError('Error creating product attribute', json);
    }
    return await response.json();
}

export async function updateProductAttributesApi(id: number, body: ProductAttributeBody): Promise<ProductAttribute> {
    const url = `/product-attributes/${id}/`;
    const response = await putApi(url, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating product attribute');
        }
        throw new ApiError('Error updating product attribute', json);
    }
    return await response.json();
}

export async function deleteProductAttributesApi(id: number): Promise<boolean> {
    const url = `/product-attributes/${id}/`;
    const response = await deleteApi(url);
    return response.ok;
}
