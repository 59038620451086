import { FormattedMessage } from 'react-intl';

export const SLOTS = {
    OPEN_SLOT: {
        BUTTON: (
            <FormattedMessage
                id='SlotDetailModal.OpenSlotButton'
                description='The button to remotely open a slot.'
                defaultMessage='Open SLOT'
            />
        ),
        MESSAGE: (
            <FormattedMessage
                id='SlotDetailModal.OpenSlot.ConfirmationModal.message'
                description='The message on the confirmation modal when trying to open a door remotely.'
                defaultMessage='Be carefull, this action will open a physical door. If it is not an automatically closing door anyone could have access to its contents.'
            />
        )
    }
};
