import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

import { Category } from '../../../api/catalogue/Categories';
import { ClassType } from '../../../common/BootstrapValues';
import HorizontalButtonGroup from '../../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../../components/loading/Spinner';
import CreateModal from '../../../components/modals/CreateModal';
import Table from '../../../components/tables/Table';
import useAuthorization from '../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAllCategories, fetchCategories } from '../../../store/categorySlice';
import { getAllChildren } from '../../../utils/GetAllChildren';
import CategoryDetailModal from '../category-detail/CategoryDetailModal';
import CreateCategory from '../forms/CreateCategory';
import CategoryListColumns from './CategoryListColumns';
import CategoryListElement from './CategoryListElement';

export default function CategoryList() {
    const authorised = useAuthorization(DefaultRole.SUPER_ADMIN, true);

    const [URL_CATEGORY_ID, changeURL_CATEGORY_ID] = useState<string | null>(new URLSearchParams(window.location.search).get('category'));
    const accountId = useAppSelector((state) => state.user.selectedMembership!.account.id);
    const dispatch = useAppDispatch();
    const categories = useAppSelector((state) => state.categories);
    const [createCategoryOpen, changeCreateCategoryOpen] = useState<boolean>(false);

    const [selectedCategory, changeSelectedCategory] = useState<Category>();
    const [filteredCategories, changeFilteredCategories] = useState<Category[] | null>(categories.categoryList);

    useEffect(() => {
        if (authorised) {
            dispatch(fetchCategories({ account: accountId.toString() }));
            dispatch(fetchAllCategories({ account: accountId.toString(), page: '1', page_size: '1000' }));
        }
    }, []);
    useEffect(() => {
        if (categories.categoryList) changeFilteredCategories(categories.categoryList);
    }, [categories]);

    useEffect(() => {
        changeURL_CATEGORY_ID(new URLSearchParams(window.location.search).get('category'));
    }, [window.location.search]);

    useEffect(() => {
        if (URL_CATEGORY_ID && categories.categoryList) {
            changeSelectedCategory(getAllChildren(categories.categoryList).find((c) => c.id.toString() === URL_CATEGORY_ID));
        }
    }, [URL_CATEGORY_ID, categories]);

    const parseToTableCategory = (category: Category): TableCategory => {
        return {
            subRows: category.children?.map((c) => parseToTableCategory(c)),
            ...category
        } as TableCategory;
    };

    const data = useMemo(() => {
        if (filteredCategories) return filteredCategories.map(parseToTableCategory);
        else return [];
    }, [filteredCategories]);
    const columns: Column<Category>[] = useMemo(() => CategoryListColumns, []);

    if (filteredCategories === null) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}
            />
        );
    }

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <HorizontalButtonGroup
                        buttons={[
                            {
                                type: ClassType.PRIMARY,
                                hide: false,
                                text: (
                                    <FormattedMessage
                                        id='CategoryList.CreateCategory'
                                        description='Button for creating category'
                                        defaultMessage='Create Category'
                                    />
                                ),
                                onClick: () => changeCreateCategoryOpen(true),
                                icon: FaPlus
                            }
                        ]}
                        direction='left'
                    />
                </div>
                <Table
                    columns={columns}
                    data={data}
                    onSelectElement={changeSelectedCategory}
                    RowElement={CategoryListElement}
                    expandEnabled={true}
                />
            </div>
            {selectedCategory && (
                <CategoryDetailModal
                    category={selectedCategory}
                    show={!!selectedCategory}
                    handleClose={() => changeSelectedCategory(undefined)}
                    customWidth={30}
                />
            )}
            {createCategoryOpen && (
                <CreateModal
                    handleClose={() => changeCreateCategoryOpen(false)}
                    header='Create Category'
                    show={createCategoryOpen}>
                    <CreateCategory handleClose={() => changeCreateCategoryOpen(false)} />
                </CreateModal>
            )}
        </>
    );
}

export interface TableCategory extends Category {
    subRows: Category[];
}
