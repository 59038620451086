import { merge } from 'lodash';

import { Logger } from '../logging/Logger';
import { AuthenticationManager } from '../utils/AuthenticationManager';

export interface PaginationResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export class ApiError extends Error {
    json?: unknown;

    constructor(message?: string, json?: string) {
        super(message);

        Object.setPrototypeOf(this, ApiError.prototype);
        this.json = json;
    }
}

export interface ApiNonFieldErrors {
    non_field_errors: string[];
}

export function isApiError(error: unknown): error is ApiError {
    return error !== undefined && error !== null && (error as ApiError).json !== undefined;
}

export function isApiErrorNonFieldErrors(json: unknown): json is ApiNonFieldErrors {
    return json !== undefined && json !== null && (json as ApiNonFieldErrors).non_field_errors !== undefined;
}

export function api(endpoint: string): string {
    return process.env.REACT_APP_API_URL! + endpoint;
}

let lastRequests: { url: string; init?: RequestInit; timestamp: number }[] = [];
const requestTimeout = 2 * 1000;
const maxRequests = 3;

export async function fetchApi(endpoint: string, init?: RequestInit, includeAccessToken = true): Promise<Response> {
    if (includeAccessToken) {
        const credentials = await AuthenticationManager.getInstance().getTokenWhenReady();
        init = merge(init, {
            headers: {
                authorization: 'bearer ' + credentials.accessToken
            }
        });
    }
    if (!endpoint.startsWith('http')) {
        endpoint = api(endpoint);
    }
    lastRequests = lastRequests.filter((request) => Date.now() - request.timestamp < requestTimeout);

    const currentRequest = { url: endpoint, init, timestamp: Date.now() };
    const matchingRequest = lastRequests.find(
        (request) => request.url === currentRequest.url && JSON.stringify(request.init) === JSON.stringify(currentRequest.init)
    );

    if (matchingRequest) {
        return Promise.reject(new Error('Duplicate request'));
    }

    lastRequests.push(currentRequest);
    if (lastRequests.length > maxRequests) {
        lastRequests.splice(0, lastRequests.length - maxRequests);
    }
    let response = await window.fetch(endpoint, init);
    if (response.status === 401) {
        Logger.error('Unauthorized refresching token en refreshing page.');
        AuthenticationManager.getInstance().refreshToken();
        const credentials = await AuthenticationManager.getInstance().getTokenWhenReady();
        init = merge(init, {
            headers: {
                authorization: 'bearer ' + credentials.accessToken
            }
        });
        response = await window.fetch(endpoint, init);
    }
    return response;
}

export function postApi(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
    const isFormData = data instanceof FormData;
    return fetchApi(
        endpoint,
        {
            method: 'POST',
            ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
            body: isFormData ? data : JSON.stringify(data)
        },
        includeAccessToken
    );
}

export function putApi(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
    const isFormData = data instanceof FormData;
    return fetchApi(
        endpoint,
        {
            method: 'PUT',
            ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
            body: isFormData ? data : JSON.stringify(data)
        },
        includeAccessToken
    );
}

export function patchApi(endpoint: string, data?: any, includeAccessToken = true): Promise<Response> {
    return fetchApi(
        endpoint,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data ? JSON.stringify(data) : ''
        },
        includeAccessToken
    );
}

export function deleteApi(endpoint: string, includeAccessToken = true): Promise<Response> {
    return fetchApi(
        endpoint,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        },
        includeAccessToken
    );
}
