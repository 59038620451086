import { useEffect, useState } from 'react';

import { UserInfo } from '../api/User';
import { useAppSelector } from './redux';

export default function useAccount() {
    const accountIdValue = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const user = useAppSelector((state) => state.user.user);
    const [accountId, setAccountId] = useState<number | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);

    useEffect(() => {
        setAccountId(accountIdValue);
    }, [accountIdValue]);

    useEffect(() => {
        setSelectedUser(user);
    }, [user]);

    return { accountId, selectedUser };
}
