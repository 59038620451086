import { FormattedMessage } from 'react-intl';

const FORM = {
    SPOT: {
        TYPE: (
            <FormattedMessage
                id='Forms.SPOT.TYPE'
                description='Label for type'
                defaultMessage='Type'
            />
        ),
        ADDRESS: (
            <FormattedMessage
                id='Forms.SPOT.ADDRESS'
                description='Label for address'
                defaultMessage='Address'
            />
        ),
        ZIP: (
            <FormattedMessage
                id='Forms.SPOT.ZIP'
                description='Label for zip'
                defaultMessage='Zip code'
            />
        ),
        CITY: (
            <FormattedMessage
                id='Forms.SPOT.CITY'
                description='Label for city'
                defaultMessage='City'
            />
        ),
        DROPOFF_TIMEOUT: (
            <FormattedMessage
                id='SpotSettings.General.Dropoff.inProgress'
                description={'Label for dropoff in progress'}
                defaultMessage='Dropoff in progress timeout in sec'
            />
        ),
        PICKUP_TIMEOUT: (
            <FormattedMessage
                id='SpotSettings.General.Pickup.inProgress'
                description={'Label for pickup in progress'}
                defaultMessage='Pickup in progress timeout in sec'
            />
        ),
        REMOVE_PARCEL_TIMEOUT: (
            <FormattedMessage
                id='SpotSettings.General.Remove.Parcel.inProgress'
                description={'Label for remove parcel in progress'}
                defaultMessage='Remove parcel in progress timeout in sec'
            />
        )
    },
    TERMINAL: {
        WORKFLOW: (
            <FormattedMessage
                id='Forms.TERMINAL.WORKFLOW'
                description='Label for workflow'
                defaultMessage='Workflow'
            />
        )
    },
    ERROR: {
        WEEKENDS_NOT_ALLOWED: (
            <FormattedMessage
                id='DateTimeInput.WeekendsNotAllowed'
                description='The error message on the DateTimePicker weekends are not allowed but the user selects one anyway.'
                defaultMessage='Weekends are not allowed.'
            />
        )
    },
    CONTACTS: {
        ADDITIONAL_DATA: (
            <FormattedMessage
                id='UpdateContactForm.Form.AdditionalData'
                description='Label for the additional data field'
                defaultMessage='Additional data'
            />
        ),
        MOBILE: (
            <FormattedMessage
                id='ContactList.tableHeader.mobile'
                description='The mobile table header for the contact list.'
                defaultMessage='Mobile'
            />
        ),
        NAME: (
            <FormattedMessage
                id='ContactList.tableHeader.name'
                description='The name table header for the contact list.'
                defaultMessage='Name'
            />
        ),
        EMAIL: (
            <FormattedMessage
                id='ContactList.tableHeader.email'
                description='The email table header for the contact list.'
                defaultMessage='Email'
            />
        ),
        DEFAULT_RECEIVER: (
            <FormattedMessage
                id='Forms.CONTACTS.DEFAULT_RECEIVER'
                description='Label for the default receiver'
                defaultMessage='Default receiver'
            />
        ),
        DELIVERY_CONTACT: (
            <FormattedMessage
                id='TerminalSettings.delivery_contacts'
                description='Label for the delivery contact'
                defaultMessage='Delivery contact'
            />
        ),
        DELIVERY_CONTACT_OTHER: (
            <FormattedMessage
                id='TerminalSettings.delivery_contact_other'
                description='Label for the delivery contact other'
                defaultMessage='Delivery contact other'
            />
        ),
        RECEIVER_CONTACT_GROUP: (
            <FormattedMessage
                id='TerminalSettings.receiver_contact_group'
                description='Label for receiver contact group'
                defaultMessage='Receiver contact group'
            />
        )
    },
    PRODUCTS: {
        PRODUCT: (
            <FormattedMessage
                id='Form.Products.Product'
                description='Label for displaying product'
                defaultMessage='Product'
            />
        ),
        PRODUCT_ATTRIBUTES: (
            <FormattedMessage
                id='Form.PRODUCTS.PRODUCT_ATTRIBUTES'
                description='Label for displaying product attributes'
                defaultMessage='Product attributes'
            />
        )
    },
    ATTRIBUTES: {
        ATTRIBUTE: (
            <FormattedMessage
                id='Form.Attributes.Attribute'
                description='Label for displaying attribute'
                defaultMessage='Attribute'
            />
        ),
        VALUES: (
            <FormattedMessage
                id='FORM.Attributes.Values'
                description='Label for displaying values'
                defaultMessage='Values'
            />
        )
    }
};
export default FORM;
