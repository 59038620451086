import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { CreateProductModel } from '../../../../api/catalogue/Products';
import { ClassType } from '../../../../common/BootstrapValues';
import { CommonMessage } from '../../../../common/CommonFormattedMessages/CommonMessage';
import CheckBox from '../../../../components/forms/check/CheckBox';
import MultiCheckbox from '../../../../components/forms/check/MultiCheckbox';
import ImageInput from '../../../../components/forms/imageInput/ImageInput';
import NumberInput from '../../../../components/forms/numberInput/NumberInput';
import SelectInput from '../../../../components/forms/select/Select';
import TextArea from '../../../../components/forms/textArea/TextArea';
import TextInput from '../../../../components/forms/textInput/TextInput';
import CreateModalFooter from '../../../../components/modals/CreateModalFooter';
import DisplayModal from '../../../../components/modals/DisplayModal';
import useAuthorization from '../../../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useAccount from '../../../../hooks/useAccount';
import { Logger } from '../../../../logging/Logger';
import { createProduct, fetchProducts } from '../../../../store/productSlice';
import { fetchShops } from '../../../../store/shopSlice';
import { GetError } from '../../../../store/utils/GetError';
import { SliceStatus, shouldFetch } from '../../../../store/utils/Redux';
import { parseObject } from '../../../../utils/ParseObject';
import { ToastUtil } from '../../../../utils/toasts/Toasts';
import SlotAssignment from '../../TransferLists/SlotAssignment';

const PRODUCT_CREATE_MODAL_ID = 'ProductCreateModal';

interface CreateProductFormProps {
    handleClose: () => void;
    pageSize: number;
}

const CreateProductForm = (props: CreateProductFormProps) => {
    useAuthorization(DefaultRole.ADMIN, true);
    const dispatch = useAppDispatch();
    const productState = useAppSelector((state) => state.products);
    const products = useAppSelector((state) => state.products.productList);
    const shopState = useAppSelector((state) => state.shops);
    const shops = useAppSelector((state) => state.shops.shopList);
    const category = useAppSelector((state) => state.categories.categoryList);
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formBody, changeFormBody] = useState<FormData>();
    const [showSpecs, toggleShowSpecs] = useState<boolean>(false);
    const [slotAssignmentOpen, changeSlotAssignmentOpen] = useState<boolean>(false);
    const { selectedUser } = useAccount();

    useEffect(() => {
        if (shops === null) {
            if (shouldFetch(shopState.status, shopState.lastUpdate)) dispatch(fetchShops({ account: accountId?.toString() }));
        }
    }, []);

    //Need to implement shops and low stock threshold when added to
    //PUT in backend

    const initialState: CreateProductModel = {
        account: accountId!,
        category: null,
        name: '',
        image: null,
        product_data: '{}',
        product_codes: undefined,
        alternative_product_codes: undefined,
        ordering_allowed: true,
        low_stock_threshold_alert: undefined,
        slots: undefined,
        product_shops: [],
        weight: undefined,
        weight_tolerance: undefined,
        height: undefined,
        width: undefined,
        diameter: undefined,
        description: undefined,
        length: undefined,
        max_stock: undefined,
        stock_count: 0
    };

    const [formResult, changeFormResult] = useState(initialState);

    const [productDataValid, changeProductDataValid] = useState<boolean>();

    const validJSON = () => {
        if (!formResult.product_data) return changeProductDataValid(false);
        try {
            JSON.parse(formResult.product_data);
            return changeProductDataValid(true);
        } catch (e) {
            changeProductDataValid(false);
        }
    };

    useEffect(() => {
        changeShopValid(formResult.product_shops.length > 0);
        const formData = new FormData();
        formData.append('account', formResult.account.toString());
        formData.append('category', formResult.category ? formResult.category.value.toString() : '');
        formData.append('name', formResult.name);
        formResult.product_shops &&
            formResult.product_shops.forEach((s) => {
                formData.append('product_shops', s.toString());
            });
        formResult.image && formData.append('image', formResult.image);
        formResult.product_data && formData.append('product_data', formResult.product_data);
        formResult.product_codes && formData.append('product_codes', formResult.product_codes.join(','));
        formResult.alternative_product_codes && formData.append('alternative_product_codes', formResult.alternative_product_codes.join(','));
        formResult.ordering_allowed && formData.append('ordering_allowed', formResult.ordering_allowed.toString());
        formResult.low_stock_threshold_alert && formData.append('low_stock_threshold_alert', formResult.low_stock_threshold_alert.toString());
        formResult.slots &&
            formResult.slots.forEach((slot) => {
                formData.append(`slots`, slot.toString());
            });
        formResult.weight && formData.append('weight', formResult.weight.toString());
        formResult.weight_tolerance && formData.append('weight_tolerance', formResult.weight_tolerance.toString());
        formResult.height && formData.append('height', formResult.height.toString());
        formResult.width && formData.append('width', formResult.width.toString());
        formResult.diameter && formData.append('diameter', formResult.diameter.toString());
        formResult.length && formData.append('length', formResult.length.toString());
        formResult.max_stock && formData.append('max_stock', formResult.max_stock.toString());
        formResult.stock_count && formData.append('stock_count', formResult.stock_count.toString());
        formResult.description && formData.append('description', formResult.description.toString());
        changeFormBody(formData);
    }, [formResult]);

    const [nameValid, changeNameValid] = useState<boolean>();
    const [categoryValid, changeCategoryValid] = useState<boolean>(true);
    const [shopValid, changeShopValid] = useState<boolean>(false);

    const valid = nameValid && categoryValid && shopValid && productDataValid;

    useEffect(() => {
        if (submitted && formResult.account && formBody) {
            const foundProduct = products?.results.filter((p) => p !== null).find((p) => p.name === formBody.get('name'));
            if (!foundProduct) {
                dispatch(createProduct({ body: formBody }));
            } else {
                changeSubmitted(false);
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, GetError(productState.error), ClassType.DANGER));
            }
        }
    }, [submitted]);

    useEffect(() => {
        if (submitted && valid) {
            if (productState.status === SliceStatus.IDLE) {
                toast.success(...ToastUtil.generateToastConfig(formResult.name, CreateProductSuccess, ClassType.SUCCESS));
                const createdProduct = productState.productList?.results.find((p) => p !== undefined && p !== null && p.name === formResult.name);
                delete formResult.image;
                Logger.log(
                    `${selectedUser?.email} created product: ${formResult.name}`,
                    { user: selectedUser?.id, product: createdProduct?.id },
                    parseObject(formResult)
                );
                /*if (products) {
                    if (props.pageSize === products.count) {
                        fetchPage = 2;
                    } else fetchPage = Math.ceil(products.count / props.pageSize);
                }*/
                dispatch(fetchProducts({ account: formResult.account.toString(), page: '1', page_size: '1000' }));
                changeSubmitted(false);
                props.handleClose();
            } else if (productState.status === SliceStatus.ERROR) {
                changeSubmitted(false);
                toast.error(...ToastUtil.generateToastConfig(CommonMessage.STATUS.ERROR, GetError(productState.error), ClassType.DANGER));
                Logger.error(`${selectedUser?.email} failed to create a product`, { user: selectedUser?.id }, productState.error?.json);
            }
        }
    }, [productState.status]);

    return (
        <>
            <form
                id={PRODUCT_CREATE_MODAL_ID}
                method='post'
                onSubmit={(e) => e.preventDefault()}>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <label>
                                {
                                    <FormattedMessage
                                        id='CreateProductForm.Label.OrderAllowed'
                                        description={'Label for ordering allowed in form'}
                                        defaultMessage='Order'
                                    />
                                }
                            </label>
                            <CheckBox
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.CheckBox.OrderingAllowed'
                                        description={'Checkbox for allowing or disallowing this product to be ordered'}
                                        defaultMessage='Ordering allowed?'
                                    />
                                }
                                id='CreateProductForm-OrderingAllowed-Checkbox'
                                onChange={(value) => changeFormResult({ ...formResult, ordering_allowed: value })}
                                checked={formResult.ordering_allowed}
                                submitted={submitted}
                            />
                        </div>
                        <div className='col-6'>
                            {shops && (
                                <MultiCheckbox
                                    title={
                                        <FormattedMessage
                                            id='CreateProductForm.Label.Shops'
                                            description={'Label for shops in form'}
                                            defaultMessage='Shops'
                                        />
                                    }
                                    required
                                    valid={shopValid}
                                    errorMessage={
                                        <FormattedMessage
                                            id='CreateProductForm.Label.ShopCheckboxes'
                                            description='Error message for multi checkboxes on the create product page'
                                            defaultMessage='Please select at least 1 shop'
                                        />
                                    }
                                    checkboxes={shops?.map((s) => ({
                                        label: s.name,
                                        key: s.id,
                                        id: s.name,
                                        submitted: submitted,
                                        checked: formResult.product_shops ? formResult.product_shops.includes(s.id) : false,
                                        onChange: (isChecked) => {
                                            changeFormResult({
                                                ...formResult,
                                                product_shops: isChecked
                                                    ? [...formResult.product_shops, s.id]
                                                    : [...formResult.product_shops.filter((p) => p !== s.id)]
                                            });
                                        }
                                    }))}
                                />
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <SelectInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.Cateogry'
                                        description={'Label for category in form'}
                                        defaultMessage='Category'
                                    />
                                }
                                value={formResult.category}
                                onChange={(value: any) => changeFormResult({ ...formResult, category: value })}
                                options={category ? category.map((c) => ({ value: c.id, label: c.name })) : []}
                                submitted={submitted}
                                id='CreateProductForm-CategorySelect'
                                isValidCallback={(value) => changeCategoryValid(value)}
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.ErrorMessage.Category'
                                        description={'Error message for category in form'}
                                        defaultMessage='Please select a category'
                                    />
                                }
                            />
                        </div>
                        <div className='col-6'>
                            <NumberInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.LowStockAlert'
                                        description={'Label for low stock threshold alert in form'}
                                        defaultMessage='Low stock threshold alert'
                                    />
                                }
                                id='CreateProductForm-LowStockInput'
                                value={formResult.low_stock_threshold_alert}
                                onChange={(value) => changeFormResult({ ...formResult, low_stock_threshold_alert: value })}
                                submitted={submitted}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.Name'
                                        description={'Label for name in form'}
                                        defaultMessage='Product name'
                                    />
                                }
                                errorMessage={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.ErrorMessage.Name'
                                        description={'Error message for name in form'}
                                        defaultMessage='Please fill in a product name'
                                    />
                                }
                                value={formResult.name}
                                onChange={(value) => changeFormResult({ ...formResult, name: value })}
                                submitted={submitted}
                                isValidCallback={(value) => changeNameValid(value)}
                                required
                                id='CreateProductForm-ProductNameInput'
                            />
                        </div>
                        <div className='col-6'>
                            <ImageInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.Image'
                                        description={'Label for image in form'}
                                        defaultMessage='Upload image'
                                    />
                                }
                                onChange={(value) => changeFormResult({ ...formResult, image: value })}
                                submitted={submitted}
                                id='CreateProductForm-ImageInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.EAN.Code'
                                        description={'Title for the EAN code label in form'}
                                        defaultMessage='EAN'
                                    />
                                }
                                id='CreateProductForm-EanInput'
                                value={formResult.product_codes?.toString()}
                                onChange={(value) => changeFormResult({ ...formResult, product_codes: [value] })}
                                submitted={submitted}
                            />
                        </div>
                        <div className='col-6'>
                            <TextInput
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.SKU.Code'
                                        description={'Title for the SKU code label in form'}
                                        defaultMessage='SKU'
                                    />
                                }
                                value={formResult.alternative_product_codes?.toString()}
                                onChange={(value) => changeFormResult({ ...formResult, alternative_product_codes: [value] })}
                                submitted={submitted}
                                id='CreateProductForm-SkuInput'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <TextArea
                                label={
                                    <FormattedMessage
                                        id='CreateProductForm.Label.ProductData'
                                        description={'Textarea for product data in form'}
                                        defaultMessage='Product data'
                                    />
                                }
                                value={formResult.product_data}
                                errorMessage='JSON is incorrect'
                                onChange={(value) => changeFormResult({ ...formResult, product_data: value })}
                                valid={productDataValid}
                                isValidCallback={validJSON}
                                submitted={submitted}
                                id='CreateProductForm-ProductDataInput'
                            />
                        </div>
                        <div className='col-6'>
                            <TextArea
                                label={
                                    <FormattedMessage
                                        id='CreatedProductForm.Label.Description'
                                        description='Textarea for product description in form'
                                        defaultMessage='Description'
                                    />
                                }
                                submitted={submitted}
                                value={formResult.description}
                                onChange={(value) => changeFormResult({ ...formResult, description: value })}
                                id='CreateProductForm-DescriptionInput'
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <button
                            type='button'
                            className='btn bg-gradient-primary'
                            id='CreateProductForm-SpecificationsButton'
                            onClick={() => toggleShowSpecs(!showSpecs)}>
                            {
                                <FormattedMessage
                                    id='CreateProductForm.options.Specifications'
                                    description={'Button for showing product specifications'}
                                    defaultMessage={'Specifications'}
                                />
                            }
                        </button>
                        <button
                            type='button'
                            className='btn bg-gradient-warning'
                            id='CreateProductForm-SlotAssignmentButton'
                            onClick={() => changeSlotAssignmentOpen(!slotAssignmentOpen)}>
                            {
                                <FormattedMessage
                                    id='ProductDetailModal.options.slotAssignment'
                                    description={'Button for changing the slot assignment'}
                                    defaultMessage={'Slot Assignment'}
                                />
                            }
                        </button>
                    </div>
                    {showSpecs && (
                        <>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Weight'
                                                description='Label for weight in form'
                                                defaultMessage='Weight'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.weight}
                                        onChange={(value) => changeFormResult({ ...formResult, weight: value })}
                                        id='CreateProductForm-WeightInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.WeightTolerance'
                                                description='Label for weight tolerance in form'
                                                defaultMessage='Weight Tolerance'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.weight_tolerance}
                                        onChange={(value) => changeFormResult({ ...formResult, weight_tolerance: value })}
                                        id='CreateProductForm-WeightToleranceInput'
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Height'
                                                description='Label for height in form'
                                                defaultMessage='Height'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.height}
                                        onChange={(value) => changeFormResult({ ...formResult, height: value })}
                                        id='CreateProductForm-HeightInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Width'
                                                description='Label for width in form'
                                                defaultMessage='Width'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.width}
                                        onChange={(value) => changeFormResult({ ...formResult, width: value })}
                                        id='CreateProductForm-WidthInput'
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Diameter'
                                                description='Label for diameter in form'
                                                defaultMessage='Diameter'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.diameter}
                                        onChange={(value) => changeFormResult({ ...formResult, diameter: value })}
                                        id='CreateProductForm-DiameterInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Length'
                                                description='Label for length in form'
                                                defaultMessage='Length'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.length}
                                        onChange={(value) => changeFormResult({ ...formResult, length: value })}
                                        id='CreateProductForm-LengthInput'
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.MaxStock'
                                                description='Label for max stock in form'
                                                defaultMessage='Max Stock'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.max_stock}
                                        onChange={(value) => changeFormResult({ ...formResult, max_stock: value })}
                                        id='CreateProductForm-MaxStockInput'
                                    />
                                </div>
                                <div className='col-6'>
                                    <NumberInput
                                        label={
                                            <FormattedMessage
                                                id='CreateProductForm.Label.Stock'
                                                description='Label for stock in form'
                                                defaultMessage='Stock'
                                            />
                                        }
                                        submitted={submitted}
                                        value={formResult.stock_count}
                                        onChange={(value) => changeFormResult({ ...formResult, stock_count: value })}
                                        id='CreateProductForm-StockInput'
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <CreateModalFooter
                    disabled={!valid}
                    onSubmit={() => changeSubmitted(true)}
                    handleClose={() => props.handleClose()}
                />
            </form>
            {slotAssignmentOpen && (
                <DisplayModal
                    handleClose={() => changeSlotAssignmentOpen(!slotAssignmentOpen)}
                    show={slotAssignmentOpen}
                    header={formResult.name}
                    customWidth={100}>
                    <SlotAssignment
                        callback={(value) => changeFormResult({ ...formResult, slots: value.slots })}
                        onSubmit={() => changeSlotAssignmentOpen(!slotAssignmentOpen)}
                    />
                </DisplayModal>
            )}
        </>
    );
};

export default CreateProductForm;

const CreateProductSuccess = (
    <FormattedMessage
        id='CreateProductForm.CreateProduct.Success'
        description={'Message when successfully creating a product'}
        defaultMessage='The product was successfully created'
    />
);

const CreateProductError = (
    <FormattedMessage
        id='CreateProductForm.CreateProduct.Error'
        description={'Message when getting an error while creating a product'}
        defaultMessage='There was an error while trying to create a product'
    />
);
