import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

import { UserInfo } from '../../api/User';
import { ClassType } from '../../common/BootstrapValues';
import Filter from '../../components/filters/AsyncFilter';
import HorizontalButtonGroup from '../../components/horizontal-button-group/HorizontalButtonGroup';
import Spinner from '../../components/loading/Spinner';
import CreateModal from '../../components/modals/CreateModal';
import Modal, { ModalType } from '../../components/modals/Modal';
import Table from '../../components/tables/Table';
import useAuthorization from '../../hooks/authorization/useAuthorization';
import { DefaultRole } from '../../hooks/authorization/useAuthorizationConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchUsers } from '../../store/userSlice';
import { SliceStatus } from '../../store/utils/Redux';
import CreateUserForm from '../forms/CreateUserForm';
import ManageInvitesModal from '../invitations/ManageInvitesModal';
import UserDetailModal from '../user-detail/UserDetailModal';
import UserListColumns from './UserListColumns';
import UserListElement from './UserListElement';

const USER_LIST_ID = 'UserList';

const UserList = () => {
    const isSuperAdmin = useAuthorization(DefaultRole.SUPER_ADMIN);
    const authorised = useAuthorization(DefaultRole.ADMIN, true);
    const [isOpen, setIsOpen] = useState(false);
    const [inviteUserModal, changeInviteUserModal] = useState(false);
    const [selectedUser, changeSelectedUser] = useState<UserInfo>();
    const accountId = useAppSelector((state) => state.user.selectedMembership?.account.id);
    const users = useAppSelector((state) => state.user);
    const next = users.usersList?.next;
    const dispatch = useAppDispatch();
    const [textFilter, changeTextfilter] = useState<UserInfo[] | undefined>();
    const [textFilterValue, changeTextFilterValue] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(25);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [filteredUsers, changeFilteredUsers] = useState<UserInfo[] | undefined>(users.usersList?.results);
    const isInit = users.userListStatus == SliceStatus.INIT;

    useEffect(() => {
        if (authorised) dispatch(fetchUsers({ account: accountId?.toString(), page: pageIndex.toString(), page_size: pageSize.toString() }));
    }, [pageIndex, pageSize]);

    const onclickEvent = (user: any) => {
        changeSelectedUser(user);
    };

    useEffect(() => {
        if (users.usersList) {
            let validUsers: UserInfo[] = users.usersList.results;
            if (textFilter && textFilter.length !== 0 && textFilterValue && textFilterValue.length !== 0 && validUsers) {
                validUsers = validUsers.filter((u) => textFilter.find((tf) => tf.id === u.id));
            }
            if (isSuperAdmin) {
                changeFilteredUsers(validUsers);
            } else
                changeFilteredUsers(
                    validUsers.filter(
                        (user) =>
                            (users.user && user.email.includes(users.user.email)) ||
                            (!user.email.includes('telloport') && !user.account_members.some((member) => member.role === 'super_admin'))
                    )
                );
        }
    }, [users.usersList, textFilter]);

    const data = useMemo(() => {
        if (filteredUsers) {
            return filteredUsers;
        } else return [];
    }, [filteredUsers]);
    const columns: Column<UserInfo>[] = useMemo(() => UserListColumns, []);

    if (isInit || users.usersList?.results === undefined) {
        return (
            <Spinner
                show={true}
                type={ClassType.LIGHT}></Spinner>
        );
    }
    return (
        <>
            <div
                id={USER_LIST_ID}
                className='card'>
                <div className='card-header d-flex'>
                    <div className='d-flex flex-column align-items-start w-100'>
                        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                            <div className='d-flex flex-row jutsify-content-start align-items-cent'>
                                <HorizontalButtonGroup
                                    buttons={[
                                        {
                                            hide: false,
                                            onClick: () => setIsOpen(true),
                                            icon: FaPlus,
                                            type: ClassType.PRIMARY,
                                            id: 'UserList-CreateUserButton',
                                            text: (
                                                <FormattedMessage
                                                    id='UserList-Create-User'
                                                    description='Label for creating user'
                                                    defaultMessage='Create User'
                                                />
                                            )
                                        },
                                        {
                                            hide: false,
                                            onClick: () => changeInviteUserModal(true),
                                            icon: FiSend,
                                            type: ClassType.SECONDARY,
                                            text: (
                                                <FormattedMessage
                                                    id='UserList.manage.invites'
                                                    description='Label for managing user invites.'
                                                    defaultMessage='Manage invites'
                                                />
                                            )
                                        }
                                    ]}
                                    direction='left'
                                />
                            </div>
                            <div className='d-flex flex-row'>
                                <div className='px-1'>
                                    <Filter
                                        objects={data}
                                        filterCallback={changeTextfilter}
                                        filterValue={changeTextFilterValue}
                                        filterKeys={['email', 'first_name', 'last_name', 'username']}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={data}
                    pageCount={users.usersList ? Math.ceil(users.usersList.count / pageSize) : undefined}
                    next={next?.toString()}
                    pageSize={(value) => setPageSize(value)}
                    page={(value) => setPageIndex(value + 1)}
                    onSelectElement={onclickEvent}
                    RowElement={UserListElement}
                    id='UserList-Table'
                />
            </div>
            <CreateModal
                show={isOpen}
                handleClose={() => setIsOpen(false)}
                header={'User'}>
                <CreateUserForm handleClose={() => setIsOpen(false)}></CreateUserForm>
            </CreateModal>
            <UserDetailModal
                show={!!selectedUser}
                user={selectedUser}
                handleClose={() => changeSelectedUser(undefined)}
            />
            <Modal
                handleClose={() => changeInviteUserModal(false)}
                show={inviteUserModal}
                type={ModalType.PANE}>
                <ManageInvitesModal onClose={() => changeInviteUserModal(false)} />
            </Modal>
        </>
    );
};

export default UserList;
