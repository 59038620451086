import { FormattedMessage } from 'react-intl';

const CONTACTS = {
    CONTACT: (
        <FormattedMessage
            id='CONTACTS.CONTACT'
            description='Label for contact'
            defaultMessage='Contact'
        />
    ),
    CONTACT_GROUP: (
        <FormattedMessage
            id='CONTACT.CONTACT_GROUP'
            description='Label for contact group'
            defaultMessage='Contact Group'
        />
    )
};

export default CONTACTS;
