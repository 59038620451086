import { CreateAttributeModel } from '../../catalogue/attributes/forms/CreateAttributeForm';
import { AttributeTypeName } from '../../common/AttributeType';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from '../BaseApi';
import { ApiQueryParams } from '../BaseQueryParams';
import { ApiError, deleteApi, postApi, putApi } from '../utils';

export interface Attribute {
    id: number;
    url: string;
    name: string;
    type: AttributeTypeName;
    values: string[];
    account: number;
    category: number;
}

export enum AttributesQueryParams {
    CATEGORY = 'category',
    NAME = 'name',
    ACCOUNT = 'account',
    VALUES = 'values'
}

const attributeViewSet: ApiViewSet = {
    baseName: 'attributes'
};

export function fetchAttributesApi(queryParams?: ApiQueryParams<AttributesQueryParams> | null): Promise<Attribute[]> {
    return apiList<Attribute, AttributesQueryParams>(attributeViewSet, queryParams);
}

export function fetchAttributeApi(options: DetailOptions): Promise<Attribute> {
    return apiDetail<Attribute>(attributeViewSet, options);
}

export async function createAttributeApi(attribute: CreateAttributeModel): Promise<Attribute> {
    const attributeAddUrl = `/attributes/`;
    const response = await postApi(attributeAddUrl, attribute);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error adding attribute');
        }
        throw new ApiError(JSON.stringify(json));
    }
    return await response.json();
}

export async function updateAttributeApi(attribute: CreateAttributeModel, id: number): Promise<Attribute> {
    let URL = `/attributes/`;
    if (id) {
        URL += id + '/';
    }
    const body = attribute;

    const response = await putApi(URL, body);
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            throw new ApiError('Error updating attribute');
        }
        throw new ApiError('Error updating attribute');
    }
    return await response.json();
}

export async function deleteAttributeApi(attributeId: number): Promise<boolean> {
    const URL = `/attributes/${attributeId}/`;
    const response = await deleteApi(URL);
    return await response.ok;
}
