import React, { useEffect, useState } from 'react';
import { Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { getInputClass, getInputClassMultiSelect } from '../../../utils/FormUtils';

export interface MultiSelectProps extends Props {
    label: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: any[] | any;
    useConditionedStyling?: boolean;
    errorMessage?: React.ReactNode;
}

const MultiSelect = (props: MultiSelectProps) => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return (
        <div
            className='form-group'
            style={{ zIndex: 999 }}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
            </label>
            <CreatableSelect
                id={props.id}
                options={props.options}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        minWidth: '190px',
                        padding: '1.204px 0px 1.204px 10px',
                        borderRadius: '0.5rem'
                    }),
                    menu: (baseStyle, state) => ({
                        ...baseStyle,
                        zIndex: 9999
                    }),
                    valueContainer: (baseStyle, state) => ({
                        ...baseStyle,
                        padding: '0px'
                    })
                }}
                classNames={{
                    control: (state) =>
                        props.useConditionedStyling === false
                            ? 'form-control'
                            : props.isMulti
                            ? getInputClassMultiSelect(touched, valid, props.value)
                            : getInputClass(touched, valid, props.value)
                }}
                inputValue={props.inputValue}
                onChange={(newValue, actionMeta) => props.onChange && props.onChange(newValue, actionMeta)}
                onInputChange={(newValue, actionMeta) => props.onInputChange && props.onInputChange(newValue, actionMeta)}
                onKeyDown={(event) => props.onKeyDown && props.onKeyDown(event)}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                onFocus={() => {
                    changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                isClearable={props.isClearable}
                isDisabled={props.isDisabled}
                defaultValue={props.defaultValue}
                menuIsOpen={props.menuIsOpen}
                value={props.value}
            />
            {valid === false && touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};

export default MultiSelect;

export interface Option {
    readonly label: string;
    readonly value: string;
}
